import React from 'react';
import classNames from 'classnames';
import { ILoaderWrapperProps } from './LoaderWrapper.types';
import styles from './LoaderWrapper.module.css';

export const LoaderWrapper: React.FC<ILoaderWrapperProps> = ({
  show,
  classStyles,
  children,
  absoluteCenter = false
}: ILoaderWrapperProps) => {
  if (show) {
    return (
      <div
        className={classNames(
          styles.loader,
          styles.showLoader,
          {
            [styles.loaderAbsolute]: absoluteCenter
          },
          classStyles
        )}
      >
        <i className="icon-driving-school" />
      </div>
    );
  }
  if (children) {
    return children;
  }

  return null;
};

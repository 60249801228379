import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IRegisterEmailErrorProps } from './RegisterEmailError.types';
import styles from './RegisterEmailError.module.css';

export const RegisterEmailError: FC<IRegisterEmailErrorProps> = (props: IRegisterEmailErrorProps) => {
  const { show, onHide, onLogin } = props;
  const { t } = useTranslation();

  return (
    <Modal dialogClassName={styles.root} animation show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton className={styles.modalHeader} />
      <Modal.Body className={styles.modalBody}>{t('register.errors.emailExists')}</Modal.Body>
      <Modal.Footer className={styles.controlsContainer}>
        <Button variant="secondary" onClick={onLogin}>
          {t('app.btn.login')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { createAction } from 'redux-actions';

export const SET_SCORE_GAME_TYPE = createAction('SET_SCORE_GAME_TYPE');

export const SET_SCORE_LIVES_MODE = createAction('SET_SCORE_LIVES_MODE');

export const GET_SCORE_REQUEST = createAction('GET_SCORE_REQUEST');
export const GET_SCORE_SUCCESS = createAction('GET_SCORE_SUCCESS');
export const GET_SCORE_FAILURE = createAction('GET_SCORE_FAILURE');

export const CLEAR_GAME_SCORE_HISTORY_REQUEST = createAction('CLEAR_GAME_SCORE_HISTORY_REQUEST');
export const CLEAR_GAME_SCORE_HISTORY_SUCCESS = createAction('CLEAR_GAME_SCORE_HISTORY_SUCCESS');
export const CLEAR_GAME_SCORE_HISTORY_FAILURE = createAction('CLEAR_GAME_SCORE_HISTORY_FAILURE');

export const UPDATE_HISTORY_ANSWER_LIST = createAction('UPDATE_HISTORY_ANSWER_LIST');
export const SET_GAME_STATUS_HISTORY = createAction('SET_GAME_STATUS_HISTORY');

export const SHOW_SINGLE_GAME_HISTORY = createAction('SHOW_SINGLE_GAME_HISTORY');

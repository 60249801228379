import { all, takeLatest, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { getLivesGameScore, clearGameHistory } from './game.api';
import { firebaseApp } from '../config/firebase.config';
import {
  GET_SCORE_FAILURE,
  GET_SCORE_REQUEST,
  GET_SCORE_SUCCESS,
  SET_SCORE_LIVES_MODE,
  SHOW_SINGLE_GAME_HISTORY,
  SET_GAME_STATUS_HISTORY,
  CLEAR_GAME_SCORE_HISTORY_REQUEST,
  CLEAR_GAME_SCORE_HISTORY_SUCCESS,
  CLEAR_GAME_SCORE_HISTORY_FAILURE
} from '../actions/gameScore.action';
import { IScoreLivesModeAction, IHandleShowHistoryAction } from '../reducer/gameScore.types';
import history from '../router/history';
import { SHOW_GAME_FROM_HISTORY } from '../actions/game.action';
import { map } from '../utils/utils';
import i18n from '../i18n-local';

function* handleSetScoreLivesMode({ payload }: IScoreLivesModeAction) {
  yield put(GET_SCORE_REQUEST());
  try {
    const { currentUser } = firebaseApp.auth();
    const result = yield getLivesGameScore(currentUser?.uid, payload);
    yield put(GET_SCORE_SUCCESS(result));
  } catch (e) {
    yield put(GET_SCORE_FAILURE(e));
  }
}

function* handleShowHistory({ payload }: IHandleShowHistoryAction) {
  const filterAnswerList = x => ({ id: x.id, questionId: x.questionId || x.questionAnswerId });
  const sortedQuestionList = map(filterAnswerList, payload);
  yield put(SET_GAME_STATUS_HISTORY());
  yield put(SHOW_GAME_FROM_HISTORY({ answerList: payload, sortedQuestionList }));
  yield history.push('game');
}

function* handleClearGameHistory({ payload: difficulty }: IScoreLivesModeAction) {
  const toastId = toast.loading('Loading...', {
    position: 'top-right'
  });
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';
    if (userId) {
      yield clearGameHistory(userId, difficulty);
      toast.success(i18n.t('theoryPage.bookMark.successfullyRemoved'), {
        id: toastId,
        position: 'top-right'
      });
      yield put(CLEAR_GAME_SCORE_HISTORY_SUCCESS());
    }
    yield put(SET_SCORE_LIVES_MODE(difficulty));
  } catch (e) {
    toast.error('Error', {
      id: toastId,
      position: 'top-right'
    });
    yield put(CLEAR_GAME_SCORE_HISTORY_FAILURE(e));
  }
}

function* gameSaga() {
  yield all([takeLatest(SET_SCORE_LIVES_MODE, handleSetScoreLivesMode)]);
  yield all([takeLatest(SHOW_SINGLE_GAME_HISTORY, handleShowHistory)]);
  yield all([takeLatest(CLEAR_GAME_SCORE_HISTORY_REQUEST, handleClearGameHistory)]);
}

export default gameSaga;

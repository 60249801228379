/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';

import styles from './AutoTheorieLernenPage.module.css';

const AutoTheorieLernenPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1>Auto Theorie lernen – mit »theorie24«</h1>

        <img
          className={styles.mainImage}
          width="400"
          height="250"
          src="/content/tour/fragen.png"
          alt="Auto Theorie lernen – theorie24-App  (Basistheorie)"
        />
        <p>
          Du möchtest Auto Theorie lernen und dabei auf Bücher verzichten? Dann bist du hier richtig: »theorie24« ist
          eine der meistgekauften Theorie-Lernapps der Schweiz. Schon
          <b> mehr als 250‘000 Fahrschüler </b>
          haben sich mit Hilfe unserer App auf die Theorieprüfung vorbereitet. Starte jetzt durch und fange gleich mit
          dem Lernen an!
        </p>

        <h2>Auto Theorie lernen mit digitaler Power</h2>

        <p>
          Unsere Lern-App »theorie24« ermöglicht es dir, online für die theoretische Fahrprüfung zu lernen. Du kannst
          alle Vorteile der digitalen Technologien nutzen. Greife rund um die Uhr auf die Inhalte zu, schaue dir
          3D-Animationen an und lassen dir knifflige Verkehrssituationen anschaulich erklären. Mit »theorie24« hebst du
          deine Vorbereitung auf die theoretische Prüfung
          <b> auf eine neue Stufe!</b>
        </p>

        <h3>Alle Fragen in einer App</h3>

        <p>
          »theorie24« ist eine der wenigen Lernapps auf dem Markt, in denen die Originalfragen des Strassenverkehrsamtes
          enthalten sind. Dies bedeutet für dich einen enormen Vorteil, da du die Prüfungssituation perfekt nachstellen
          kannst. Du hast immer die passenden Antworten parat und brauchst
          <b> keine Angst </b>
          vor der theoretischen Fahrprüfung zu haben, die jeder Führerscheinanwärter hinter sich bringen muss.
        </p>

        <h3>Hohe Bedienfreundlichkeit</h3>

        <p>
          »theorie24« gilt als eines der nutzerfreundlichsten Lernprogramme im Bereich der Fahrschultheorie – und das
          mit Recht. Wenn du die Demo-Version nutzt, siehst du, dass wir dir nicht zu viel versprechen! Du findest dich
          auf Anhieb zurecht und kannst kinderleicht von einer Ebenen zur anderen navigieren. »theorie24« ist
          <b> auf allen Endgeräten problemlos bedienbar </b>– ob auf dem Smartphone oder auf dem Notebook.
        </p>

        <h3>Bequem unterwegs lernen</h3>

        <p>
          Mit unserer Lern-App kannst du <b>unterwegs Auto Theorie lernen</b>. Nutze jede freie Minute, um dich auf die
          Prüfung vorzubereiten – an der Bushaltestelle, in der Freistunde oder im Café. Wenn deine Freunde ebenfalls
          »theorie24« nutzen, könnt ihr euch gegenseitig abfragen. So macht die Vorbereitung auf die Theorieprüfung noch
          mehr Spass!
        </p>

        <iframe
          title="Iframe"
          className={styles.video}
          width="645"
          height="483"
          src="https://www.youtube.com/embed/4Wb9IUWtuRY"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <h3>Jetzt registrieren!</h3>

        <p>
          <b>
            <LinkWrapper to="/offers">Registriere dich noch heute!</LinkWrapper>
          </b>{' '}
          Du kannst gleich nach der Freischaltung damit beginnen, online Auto Theorie zu lernen. Du wirst schnell
          feststellen, dass das Lernen dir wesentlich leichter fällt als ohne App. Schon mehr als 250‘000 Fahrschüler
          haben sich mit Hilfe von »theorie24« erfolgreich auf die{' '}
          <LinkWrapper to="/theoriepruefung">Theorieprüfung</LinkWrapper> vorbereitet. Verliere keine Zeit und sichere
          dir jetzt eine der besten Lern-Apps auf dem Markt!
        </p>
      </Container>
    </div>
  );
};

export default AutoTheorieLernenPage;

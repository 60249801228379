import { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { delay, soundAbsolutePath } from '../../../../utils/utils';
import { PLAY_MUSIC } from '../../../../actions/sound.action';

const gameCountdown = soundAbsolutePath('race-countdown.mp3');

export interface ITrafficLightState {
  setRedLight: (state: boolean) => void;
  setYellowLight: (state: boolean) => void;
  setGreenLight: (state: boolean) => void;
  startCountDown: () => void;
  states: {
    redLightState: boolean;
    yellowLightState: boolean;
    greenLightState: boolean;
  };
}

export default function useTrafficLight({
  redLightInitState,
  yellowLightInitState,
  greenLightInitState
}): ITrafficLightState {
  const dispatch = useDispatch();

  const [redLightState, setRedLight] = useState(redLightInitState);
  const [yellowLightState, setYellowLight] = useState(yellowLightInitState);
  const [greenLightState, setGreenLight] = useState(greenLightInitState);

  const startCountDown = useCallback(async () => {
    await delay(1000);
    dispatch(PLAY_MUSIC({ url: gameCountdown }));
    setRedLight(true);
    await delay(1000);
    setYellowLight(true);
    await delay(1000);
    setGreenLight(true);
  }, [dispatch]);

  const handlers = useMemo(
    () => ({
      setRedLight,
      setYellowLight,
      setGreenLight,
      startCountDown
    }),
    [startCountDown]
  );

  return {
    states: {
      redLightState,
      yellowLightState,
      greenLightState
    },
    ...handlers
  };
}

import fetch, { IApiFailureResponse } from './fetch';
import { IUserPlanExtensionRequestPayload, IUserPlanExtensionSuccessResponse, IUserProfileApi } from './user.types';
import { db } from '../config/firebase.config';
import { EMPTY_MARKED_QUESTIONS } from '../constants';
import { CategoryType } from '../reducer/question.types';
import { checkIfThisQuestionExistInQuestionJSON } from '../utils/utils';

export const getProfile = (): IUserProfileApi => {
  return fetch('GET', '/customer');
};

export const getFirebaseProfile = async userId => {
  const snapshot = await db
    .collection('user')
    .doc(userId)
    .get();

  const userData = snapshot.data();

  const filteredQuestionList = {
    markedQuestions: {}
  };
  /** filter only question which exists * */
  if (userData?.questionsList) {
    // eslint-disable-next-line
    for (const key in userData?.questionsList?.markedQuestions) {
      filteredQuestionList.markedQuestions[key] = userData?.questionsList?.markedQuestions[key].filter(questionId =>
        checkIfThisQuestionExistInQuestionJSON(questionId)
      );
    }
  }

  return userData
    ? {
        address: userData.address,
        email: userData.email,
        firstName: userData.firstName,
        language: userData.language,
        lastName: userData.lastName,
        mobile: userData.mobile,
        phone: userData.phone,
        questionsList: userData?.questionsList ? filteredQuestionList : { markedQuestions: EMPTY_MARKED_QUESTIONS },
        drivingInstructor: userData.drivingInstructor,
        firstexam: userData.firstexam,
        theory: userData.theory || { bookedPage: null },
        game: userData.game
      }
    : null;
};

export const userPlanExtension = (
  payload: IUserPlanExtensionRequestPayload
): Promise<IUserPlanExtensionSuccessResponse | IApiFailureResponse> => fetch('POST', '/user/renew', payload);

export const setUserLanguageApi = (language: string) => {
  return fetch('PATCH', '/customer', { language });
};

export const setMarkedQuestion = async (userId, markedQuestionsArray) => {
  await db
    .collection('user')
    .doc(userId)
    .update({
      questionsList: { markedQuestions: markedQuestionsArray }
    });
  await getFirebaseProfile(userId);
};

export const setUserFirstExamPassedApi = (firstexam: string) => {
  return fetch('PATCH', '/customer', { firstexam });
};

export const setUserCategorySelected = (category: CategoryType) => {
  return fetch('PATCH', '/customer', { category });
};

export const setBookMarkedPage = async (userId, bookMarkedPage) => {
  await db
    .collection('user')
    .doc(userId)
    .update({
      theory: {
        bookedPage: bookMarkedPage
      }
    });
};

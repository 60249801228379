import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CategoryIcon from '../components/CategoryIcon/CategoryIcon';
import { RulesGroupItem } from '../ui-components/RulesGroupItem/RulesGroupItem';
import { PageTitle } from '../ui-components/PageTitle/PageTitle';
import styles from './TheoryContainer.module.css';
import { LinkWrapper } from '../ui-components/LinkWrapper/LinkWrapper';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import { P3 } from '../ui-components/P3/P3';

const TheoryContainer: React.FC = () => {
  const { t } = useTranslation();
  const { isLogged }: LoginState = useSelector(selectLogin);

  return (
    <RulesGroupItem>
      {isLogged ? (
        <div className="loggedPageText">
          <div className={styles.theoryPageText}>
            <PageTitle>{t('theoryPage.theory')}</PageTitle>
            <P3>{t('myCockpitCategories.subtitle.theory')}</P3>
          </div>
        </div>
      ) : (
        <div className="notLoggedPageText">
          <div className={styles.theoryPageText}>
            <PageTitle>{t('theoryPage.theory')}</PageTitle>
            <P3>{t('theoryPage.text')}</P3>
          </div>
        </div>
      )}
      <div className={styles.rulesList}>
        <CategoryIcon icon="icon-car" level="B" badge={false} />
        <CategoryIcon icon="icon-motorcycle" level="A, A1" badge={false} />
        <CategoryIcon icon="icon-tractor" level="F, G" badge={false} />
        <CategoryIcon icon="icon-e-bike" level="M" badge={false} />
      </div>

      {isLogged && <div className={styles.bookMarkText}>{t('theoryPage.bookMark.text')}</div>}

      {!isLogged && (
        <LinkWrapper className="btn btn-primary theoryBtn" to="/offers">
          {t('theoryPage.button')}
        </LinkWrapper>
      )}
    </RulesGroupItem>
  );
};

export default TheoryContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ProgressBar } from 'react-bootstrap';
import { IModalByTopicProps } from './ModalByTopic.type';
import styles from './ModalByTopic.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const ModalByTopic: React.FC<IModalByTopicProps> = ({
  onHide,
  show,
  topics,
  title,
  currentCategory,
  questionCountPassed,
  currentPercentage
}: IModalByTopicProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.modalTopic}
      animation
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.topicList}>
          {topics.map(
            link =>
              link && (
                <div className={styles.topicListItem} key={link.id}>
                  <LinkWrapper
                    className={styles.topicListLink}
                    title={`${link.title} (${link.questionsCount && link.questionsCount.toString()})`}
                    to={`/questions/${currentCategory}/${link.id}`}
                    onClick={onHide}
                  >
                    {link.title}
                  </LinkWrapper>

                  {questionCountPassed ? (
                    <div>
                      <div className={styles.progressBarItem}>
                        <div>
                          <ProgressBar now={currentPercentage || 0} />
                        </div>
                        <div>O</div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalByTopic;

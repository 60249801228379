import { createContext } from 'react';
import { RoutesTypes } from './router/routes.types';

export const RouteConfigContext = createContext<RoutesTypes>({
  component: () => null,
  path: '/',
  name: 'MainPage'
});

export const LanguageContext = createContext<string | undefined>(undefined);

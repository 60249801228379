import { put, takeLatest, select, call, all } from 'redux-saga/effects';
import { GET_QUESTION_FAILURE, GET_QUESTION_SUCCESS, GET_QUESTION_REQUEST } from '../actions/question.action';
import { getQuestions } from './question.api';
import { IRootState } from '../reducer';
import { CategoryType, IQuestion } from '../reducer/question.types';
import { prefetchQuestionsMediaSources } from '../utils/prefetchMediaSources';
import { getUsersMarkedQuestions } from '../selectors/user.selectors';
import { getMarkedCategory } from '../utils/utils';

const getCategory = ({ user }: IRootState) => user.profile.currentCategory;
const getUserAnswersCash = ({ answer: { answerListCash } }: IRootState) => answerListCash;

function* getQuestionList(action) {
  try {
    const currentCategory: CategoryType = yield select(getCategory);
    const markedCategory = getMarkedCategory(currentCategory);
    const markedQuestions = yield select(getUsersMarkedQuestions);
    const userAnswersCash = yield select(getUserAnswersCash);
    let questionsFromTopic: number[] = [];
    if (action.payload.topic === 'marked') {
      questionsFromTopic = [...markedQuestions[markedCategory]];
    }

    if (action.payload.topic === 'incorrect') {
      const topicIds = Object.keys(userAnswersCash);
      topicIds.forEach(topicId => {
        const answersKeyList = Object.keys(userAnswersCash[topicId]);
        answersKeyList.forEach(answerKey => {
          if (
            typeof userAnswersCash[topicId][answerKey].correct === 'boolean' &&
            !userAnswersCash[topicId][answerKey].correct
          ) {
            questionsFromTopic.push(Number(answerKey));
          }
        });
      });
    }

    const data = yield call(getQuestions, action.payload.topic, currentCategory, questionsFromTopic);
    yield put(GET_QUESTION_SUCCESS(data));
  } catch (e) {
    yield put(GET_QUESTION_FAILURE(e.message));
  }
}

function prefetchQuestionMedia({ payload }: { payload: IQuestion[] }) {
  prefetchQuestionsMediaSources(payload);
}

function* questionSaga() {
  yield all([
    takeLatest(GET_QUESTION_REQUEST, getQuestionList),
    takeLatest(GET_QUESTION_SUCCESS, prefetchQuestionMedia)
  ]);
}

export default questionSaga;

import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { selectLanguage } from '../../reducer/user.selectors';
import { ILocalesImagePath } from './LocalesImage.types';

const LocalesImage: React.FC<ILocalesImagePath> = forwardRef(
  ({ alt, localesPath, className }: ILocalesImagePath, ref?: React.Ref<HTMLImageElement>) => {
    const currentLanguage = useSelector(selectLanguage);

    const imgSrc = localesPath[currentLanguage]?.img;

    return <img ref={ref} className={className} src={imgSrc} alt={alt} />;
  }
);

export const MLocalesImage = motion(LocalesImage);

export default LocalesImage;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TopicSection.module.css';
import { PageTitle } from '../../../../ui-components/PageTitle/PageTitle';

const TopicSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.bannerContainer}>
        <PageTitle>{t('questionsByTopicContainer.topicsProgress')}</PageTitle>
      </div>
    </section>
  );
};

export default React.memo(TopicSection);

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getUniqId } from '../../utils/utils';
import { IBounceOnSelectProps } from './BounceOnSelect.types';

export const BounceOnSelect: React.FC<IBounceOnSelectProps> = ({
  isSelect,
  children,
  animateOnEveryChange = false
}: IBounceOnSelectProps) => {
  const [animationKey, setAnimationKey] = useState(getUniqId());

  useEffect(() => {
    if (animateOnEveryChange || isSelect) {
      setAnimationKey(getUniqId());
    }
  }, [isSelect, animateOnEveryChange]);

  return (
    <motion.div
      key={animationKey}
      animate={(animateOnEveryChange || isSelect) && { y: [0, -5, 0, -5, 0], transition: { duration: 0.3 } }}
    >
      {children}
    </motion.div>
  );
};

/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SaleItem } from '../../components';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import { imageAbsolutePath } from '../../../../utils/utils';
import { useGetOfferList } from '../../../../hooks/dataFetch/useGetOfferList';
import { IRootState } from '../../../../reducer';
import styles from './CallToActionSection.module.css';

const categoriesAB = imageAbsolutePath('B-A1-logo.png');
const categoriesMFG = imageAbsolutePath('MFG-logo.png');

const CallToActionSection: React.FC = () => {
  const { t } = useTranslation();

  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);

  const { offersList, isFetching } = useGetOfferList();

  const firstPlan = offersList.find(offer => offer.id === 11);
  const secondPlan = offersList.find(offer => offer.id === 12);

  return (
    <section>
      <TitleH3 center text={t('homePageNew.callToActionSection.title')} />

      <div className={styles.saleItemContainer}>
        <SaleItem
          categoryText={t('homePageNew.callToActionSection.autoCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${firstPlan?.id}`}
          isLoading={isFetching}
          icons={
            <img
              className={styles.abIcons}
              src={categoriesAB}
              alt={t('homePageNew.titleSection.images.categoriesAlt')}
            />
          }
          text={firstPlan?.days.toString()}
          price={firstPlan?.cost}
        />
        <SaleItem
          categoryText={t('homePageNew.callToActionSection.tractorCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${secondPlan?.id}`}
          isLoading={isFetching}
          icons={<img src={categoriesMFG} alt={t('homePageNew.titleSection.images.categoriesAlt')} />}
          text={secondPlan?.days.toString()}
          price={secondPlan?.cost}
        />
      </div>
    </section>
  );
};

export default CallToActionSection;

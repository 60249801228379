import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ICommonContainerProps } from './CommonContainer.types';

const CommonContainer: React.FC<ICommonContainerProps> = ({ className, children }: ICommonContainerProps) => {
  return (
    <div className={className && className}>
      <Container>
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default CommonContainer;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Container, Jumbotron, ProgressBar } from 'react-bootstrap';
import cx from 'classnames';
import { TOKEN } from '../../constants/storageKeys';
import { ICockpitBottomPanelProps } from './CockpitBottomPanel.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import SchoolLogo from '../SchoolLogo/SchoolLogo';
import { P3 } from '../../ui-components/P3/P3';
import { BestResultCard } from '../BestResultCard/BestResultCard';
import { selectIsPlanExpired } from '../../reducer/user.selectors';
import { API_DATE_FORMAT } from '../../constants/api';
import { getPlanDateCountLabel, isSSR } from '../../utils/utils';
import useDeviceSizes from '../../hooks/useDeviceSizes';
import styles from './CockpitBottomPanel.module.css';

const CockpitBottomPanel: React.FC<ICockpitBottomPanelProps> = ({
  activationPlaneDateTime,
  expirePlaneDateTime,
  drivingInstructor,
  lastExams,
  userStatistics
}: ICockpitBottomPanelProps) => {
  const { t } = useTranslation();
  const { width } = useDeviceSizes();
  const availablePlanDays = getPlanDateCountLabel(expirePlaneDateTime);
  const isAnyExamStarted = Boolean(lastExams && lastExams.length);
  const isExpired = useSelector(selectIsPlanExpired);
  const token = isSSR() ? false : localStorage.getItem(TOKEN)!;

  const isMobileMode = useMemo(() => width <= 991, [width]);

  const jumbotron = (
    <>
      <Jumbotron className={styles.jumbotron}>
        <div className={styles.jumbotronBox}>
          <h3 className="h3">{t('cockpitBottomPanel.thirdBlock.title')}</h3>
        </div>
        <div className={styles.jumbotronBox}>
          <div className={styles.daysCount}>
            <div className={styles.daysValue}>{availablePlanDays}</div>
            <div className={styles.daysText}>{t('cockpitBottomPanel.thirdBlock.days')}</div>
          </div>
        </div>
        <div className={styles.jumbotronBoxDate}>
          <div className={styles.jumbotronBox}>
            <div className={styles.jumbotronBoxItem}>
              <div className={styles.dateItem}>
                <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.activation')}</div>
                <div className={styles.dateItemText}>
                  {moment(activationPlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                </div>
              </div>
              <div className={styles.dateItem}>
                <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.expiry')}</div>
                <div className={styles.dateItemText}>
                  {moment(expirePlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.jumbotronBoxBtn}>
            <Button variant="primary" as={LinkWrapper} to="/extend-offers">
              {t('cockpitBottomPanel.thirdBlock.extendButton')}
            </Button>
          </div>
        </div>
      </Jumbotron>
    </>
  );

  if (isExpired) {
    return (
      <div className={styles.cockpitBottomPanel}>
        <Container>
          <Row>
            <Col>
              <div className={styles.bottomPanelList} id="cockpitBottomPanel">
                {jumbotron}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className={styles.cockpitBottomPanel}>
      <Container>
        <Row>
          <Col>
            <div className={styles.bottomPanelList} id="cockpitBottomPanel">
              <BestResultCard containerClassName={styles.bestResultCardContainer} isVisible={isMobileMode} />
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.jumbotronHeader}>
                  <div className={styles.jumbotronBox}>
                    <h3 className="h3">{t('cockpitBottomPanel.firstBlock.title')}</h3>
                  </div>
                  <div className={styles.jumbotronBox}>
                    <div className={styles.progressBarContent}>
                      <ProgressBar
                        title={`${(userStatistics
                          ? (userStatistics.progress * userStatistics.questionsCount) / 100
                          : 0
                        ).toFixed(0)} / ${userStatistics ? userStatistics.questionsCount : 0}`}
                        className={styles.progressBar}
                        now={userStatistics ? userStatistics.progress : 0}
                      />
                      <div
                        title={`${
                          userStatistics && userStatistics.status > -1 ? (userStatistics.status * 100).toFixed(0) : '0'
                        } %`}
                        className={cx(styles.status, {
                          [styles.empty]: userStatistics && userStatistics.status === -1,
                          [styles.bad]:
                            userStatistics &&
                            userStatistics.status >= 0.0 &&
                            userStatistics &&
                            userStatistics.status < 0.4,
                          [styles.middle]:
                            userStatistics &&
                            userStatistics.status >= 0.4 &&
                            userStatistics &&
                            userStatistics.status < 1.0,
                          [styles.good]: userStatistics && userStatistics.status === 1.0
                        })}
                      />
                    </div>
                  </div>
                </div>
                {isAnyExamStarted && (
                  <div className={styles.jumbotronBox}>
                    {lastExams.slice(0, 4).map(item => (
                      <div className={styles.jumbotronBoxItem} key={item.id}>
                        <div className={styles.jumbotronDate}>{moment(item.data.startTime).format('DD.MM.YYYY')}</div>
                        <div className={styles.jumbotronTime}>{moment(item.data.startTime).format('HH:mm')}</div>
                        <div className={styles.jumbotronCount}>{item.data.trueQuestionCount}</div>
                        <LinkWrapper to={`/exam/${item.id}`} className={styles.jumbotronIcon}>
                          <div className={styles.jumbotronText}>{t('cockpitBottomPanel.firstBlock.show')}</div>
                        </LinkWrapper>
                      </div>
                    ))}
                  </div>
                )}
                {!isAnyExamStarted && (
                  <div className={styles.jumbotronFooter}>
                    <div className={styles.jumbotronBox}>
                      <P3 className={styles.jumbotronPlaceholder}>{t('cockpitBottomPanel.firstBlock.placeholder')}</P3>
                    </div>
                    <div className={styles.jumbotronBoxBtn}>
                      <Button variant="secondary" as={LinkWrapper} to="/exam">
                        {t('cockpitBottomPanel.firstBlock.startExamBtn')}
                      </Button>
                    </div>
                  </div>
                )}
                {lastExams.length > 4 && (
                  <div className={styles.jumbotronFooter}>
                    <div className={styles.jumbotronBoxBtn}>
                      <Button variant="secondary" as={LinkWrapper} to="/exam-history">
                        {t('cockpitBottomPanel.firstBlock.showMore')}
                      </Button>
                    </div>
                  </div>
                )}
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.jumbotronBox}>
                  <h3 className="h3">{t('cockpitBottomPanel.secondBlock.title')}</h3>
                </div>
                {drivingInstructor && (
                  <>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.instructorContent}>
                        <SchoolLogo logoSrc={drivingInstructor.photo} alt={drivingInstructor.fullName} />
                        <div className={styles.instructorInfo}>
                          <div className={styles.instructorSc}>{drivingInstructor.school}</div>
                          <div className={styles.instructorName}>{drivingInstructor.fullName}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.address')}</p>
                      <p className={styles.jumbotronValue}>{drivingInstructor.address}</p>
                      <p className={styles.jumbotronValue}>{`${drivingInstructor.zip} ${drivingInstructor.city}`}</p>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.jumbotronBoxItem}>
                        {drivingInstructor.phone && (
                          <div className={styles.dateItem}>
                            <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.phone')}</p>
                            <p className={styles.jumbotronValue}>{drivingInstructor.phone}</p>
                          </div>
                        )}
                        {drivingInstructor.mobile && (
                          <div className={styles.dateItem}>
                            <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.mobile')}</p>
                            <p className={styles.jumbotronValue}>{drivingInstructor.mobile}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.email')}</p>
                      <p className={styles.jumbotronValue}>{drivingInstructor.email}</p>
                    </div>
                    {drivingInstructor.voucher && (
                      <a
                        className="btn btn-secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/api/v1/customer/voucher?bearer=${token}`}
                      >
                        {t('cockpitBottomPanel.secondBlock.downloadvoucher')}
                      </a>
                    )}
                  </>
                )}
                {!drivingInstructor && (
                  <div className={styles.jumbotronFooter}>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.jumbotronIconBox}>
                        <i className="icon-driving-school" />
                      </div>
                      <P3 className={styles.jumbotronPlaceholder}>{t('cockpitBottomPanel.secondBlock.placeholder')}</P3>
                    </div>
                    <div className={styles.jumbotronBoxBtn}>
                      <Button variant="secondary" as={LinkWrapper} to="/school">
                        {t('cockpitBottomPanel.secondBlock.selectInstructorBtn')}
                      </Button>
                    </div>
                  </div>
                )}
              </Jumbotron>
              {jumbotron}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitBottomPanel;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../utils/utils';
import { IVipCardProps } from './VipCard.types';
import { IS_WEBVIEW_IOS } from '../../constants';
import styles from './VipCard.module.css';

const logo = imageAbsolutePath('new-vip-card.png');

const VipCard: React.FC<IVipCardProps> = ({ withLink = true }: IVipCardProps) => {
  const { t } = useTranslation();

  const content = useCallback(() => {
    return (
      <>
        <img className={styles.cardLogo} src={logo} alt="logo" />
        {withLink ? (
          <div className="linkBtn">
            {t('homePage.btn.redeemCode')}
            <i className="icon-arrow" />
          </div>
        ) : (
          <LinkWrapper className="linkBtn" to="/vipcode">
            {t('homePage.btn.redeemCode')}
            <i className="icon-arrow" />
          </LinkWrapper>
        )}
      </>
    );
  }, [withLink, t]);

  if (IS_WEBVIEW_IOS) return null;

  if (withLink) {
    return (
      <LinkWrapper className={styles.vipCard} to="/vipcode">
        {content()}
      </LinkWrapper>
    );
  }
  return <div className={styles.vipCard}>{content()}</div>;
};

export default VipCard;

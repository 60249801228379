import React from 'react';
import classNames from 'classnames';
import { ICategoryConfig, ICategoryListProps } from './CategoryList.types';
import styles from './CategoryList.module.css';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

const categoryConfig: ICategoryConfig[] = [
  {
    name: 'B',
    icon: 'icon-car'
  },
  {
    name: 'A,A1',
    icon: 'icon-motorcycle'
  },
  {
    name: 'F,G',
    icon: 'icon-tractor'
  },
  {
    name: 'M',
    icon: 'icon-e-bike'
  }
];

const isDemoCategory: ICategoryConfig[] = [
  {
    name: 'B',
    icon: 'icon-car'
  },
  {
    name: 'A,A1',
    icon: 'icon-motorcycle'
  }
];

export const CategoryList: React.FC<ICategoryListProps> = ({
  className,
  selectedCategory,
  onClickDriveLicense,
  withFilteredCategories,
  userAvailableCategories,
  isDemo
}: ICategoryListProps) => {
  const categoryConfigList = isDemo ? isDemoCategory : categoryConfig;
  return (
    <div className={classNames(styles.rulesList, className)}>
      {categoryConfigList.map(category => {
        if (withFilteredCategories) {
          if (userAvailableCategories && userAvailableCategories.indexOf(category.name) !== -1) {
            return (
              <ButtonIcon
                key={category.name}
                level={category.name}
                selectedLevel={selectedCategory}
                content={<i className={category.icon} />}
                onClick={onClickDriveLicense}
              />
            );
          }
          return null;
        }
        return (
          <ButtonIcon
            key={category.name}
            level={category.name}
            selectedLevel={selectedCategory}
            content={<i className={category.icon} />}
            onClick={onClickDriveLicense}
          />
        );
      })}
    </div>
  );
};

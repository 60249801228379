import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import Counter from '../../ui-components/Counter/Counter';
import QuestionView from '../../components/QuestionView/QuestionView';
import QuestionBar from '../../components/QuestionBar/QuestionBar';
import { IRootState } from '../../reducer';
import { IQuestion } from '../../reducer/question.types';
import { RequestStatusType } from '../../reducer/fetch.types';
import { TopicSelectorContainer } from '../TopicSelectorContainer';
import { IQuestionCardContainerProps } from './QuestionCardContainer.types';
import {
  ADD_ANSWER_REQUEST,
  CHANGE_ANSWER,
  GET_ANSWER_FROM_FIREBASE_REQUEST,
  GET_ANSWER_LIST_CASH_REQUEST,
  CLEAR_CURRENT_ANSWER_REQUEST
} from '../../actions/answer.action';
import {
  SET_LANGUAGE_TOGGLE_STATE,
  SET_MARKED_QUESTION,
  SET_SELECTED_QUESTION_LANGUAGE,
  SET_USER_QUESTION_LANGUAGE_SUCCESS,
  SET_USER_SELECTED_CATEGORY,
  SET_USER_SELECTED_QUESTION
} from '../../actions/user.action';
import { GET_QUESTION_REQUEST, REMOVE_MARKED_QUESTION } from '../../actions/question.action';
import { GET_TOPICS_REQUEST } from '../../actions/topic.action';
import { IUserAnswer } from '../../reducer/user.types';
import styles from './QuestionCardContainer.module.css';
import { Loader } from '../../ui-components/Loader/Loader';
import { selectLanguage } from '../../reducer/user.selectors';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import { FAILURE, PENDING, UNCALLED } from '../../constants/store.constants';
import { usePrevious } from '../../hooks/usePrevious';
import Ads from '../../components/Ads/Ads';
import { P3 } from '../../ui-components/P3/P3';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { getUsersMarkedQuestions } from '../../selectors/user.selectors';
import { IUserAnswers } from '../../reducer/answer.types';
import { firebaseApp } from '../../config/firebase.config';
import { checkIsAnswerIsCorrect, getMarkedCategory } from '../../utils/utils';
import { IMarkedQuestion } from '../../saga/user.types';

export const QuestionCardContainer: React.FC<IQuestionCardContainerProps> = ({
  routeParams
}: IQuestionCardContainerProps) => {
  const { history } = useReactRouter();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const questionList: IQuestion[] = useSelector(({ question }: IRootState) => question.questionList);
  const requestStatus: RequestStatusType = useSelector(
    ({ question }: IRootState) => question.questionListRequest.status
  );
  const questionLanguage: string = useSelector(({ user: { profile } }: IRootState) => profile.questionLanguage);
  const { topic, id = 1, category } = routeParams;
  const isDemo = topic === 'demo';
  const userLanguage = useSelector(selectLanguage);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const markedQuestionCategory = getMarkedCategory(currentCategory);
  const isTopicMarked = useMemo(() => topic === 'marked', [topic]);

  const selectedQuestionId: number = useMemo(() => {
    if (id && +id > 0 && +id <= questionList.length) {
      return +id - 1;
    }
    if (isTopicMarked && +id === questionList.length + 1) {
      history.push(`/${userLanguage}/questions/${currentCategory}/${topic}/${+id - 1}`);
    }
    return 0;
  }, [userLanguage, currentCategory, questionList, topic, id, isTopicMarked, history]);

  const question: IQuestion = questionList[selectedQuestionId];
  const userAnswersCash: IUserAnswers = useSelector(({ answer: { answerListCash } }: IRootState) => answerListCash);
  const { currentUser } = firebaseApp.auth();

  const userAnswers: IUserAnswers = useSelector(({ answer: { answerList } }: IRootState) => answerList);

  const isReanswered = topic === 'incorrect' ? Object.keys(userAnswers).length > 0 : true;

  const isIncorrectTopic = topic === 'incorrect';

  const userAnswer: IUserAnswer | null =
    isReanswered && question && userAnswers && userAnswers[question.themeid]
      ? userAnswers[question.themeid][question.id]
      : null;

  const isCorrect: boolean | undefined = userAnswer ? userAnswer.correct : undefined;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const prevLanguage = usePrevious(userLanguage);
  const markedQuestions = useSelector(getUsersMarkedQuestions);
  const prevMarkedQuestion: undefined | IMarkedQuestion = usePrevious(markedQuestions);
  const isMarked = question && markedQuestions[markedQuestionCategory]?.includes(question.id);

  const selectedQuestionLang: string | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.selectedQuestionLang
  );

  const userCategories = useSelector(({ user }: IRootState) => user.profile.categories);

  const toggleLngState: boolean | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.toggleLngState
  );

  const isUserAnswered = userAnswer ? userAnswer.correct !== undefined : false;

  const changeLanguage = useCallback(
    (language: string): void => {
      dispatch(SET_USER_QUESTION_LANGUAGE_SUCCESS({ language }));
    },
    [dispatch]
  );

  const setToggleState = useCallback(toggleState => dispatch(SET_LANGUAGE_TOGGLE_STATE({ toggleState })), [dispatch]);

  const handleToggleChangeLng = useCallback(
    (newLng: string) => {
      setToggleState(false);
      dispatch(SET_SELECTED_QUESTION_LANGUAGE(newLng));
      changeLanguage(newLng);
    },
    [dispatch, changeLanguage, setToggleState]
  );

  const handleLngToggleClick = useCallback(() => {
    changeLanguage(!toggleLngState ? userLanguage : selectedQuestionLang);
    setToggleState(!toggleLngState);
  }, [toggleLngState, setToggleState, changeLanguage, selectedQuestionLang, userLanguage]);

  const handleQuestionCheck = useCallback(() => {
    if (userAnswer) {
      const correctStatus: boolean = checkIsAnswerIsCorrect(question, userAnswer.selected);
      dispatch(
        ADD_ANSWER_REQUEST({
          questionId: question.id,
          qTopic: question.themeid,
          answer: {
            correct: correctStatus,
            selected: [...userAnswer.selected]
          }
        })
      );
    }
  }, [userAnswer, dispatch, question]);

  const handleAnswerChange = useCallback(
    (checked: boolean, answerId: number) => {
      dispatch(CHANGE_ANSWER({ answerId, question, userAnswer, topic }));
    },
    [dispatch, userAnswer, question, topic]
  );

  const handleQuestionChange = useCallback(
    (link: string) => {
      if (topic === 'incorrect') {
        dispatch(GET_ANSWER_LIST_CASH_REQUEST());
        if (!isCorrect) {
          history.push(link);
        }
      } else {
        history.push(link);
      }
    },
    [dispatch, history, topic, isCorrect]
  );

  const handleToMarked = useCallback(
    (questionId: number) => {
      const newCategoryList = isMarked
        ? markedQuestions[markedQuestionCategory].filter(item => item !== questionId)
        : [...markedQuestions[markedQuestionCategory], questionId];
      const markedQuestionCopy = { ...markedQuestions, [markedQuestionCategory]: newCategoryList };
      dispatch(SET_MARKED_QUESTION(markedQuestionCopy));
      if (isTopicMarked && isMarked) {
        dispatch(REMOVE_MARKED_QUESTION(questionId));
      }
    },
    [dispatch, isMarked, markedQuestions, isTopicMarked, markedQuestionCategory]
  );

  const renderEmptyMarkedText = () => {
    return (
      <div className={styles.emptyMarkedQuestionsContainer}>
        <p className={styles.emptyMarkedQuestionsTitle}>{t('questionCard.markedListEmpty')}</p>
        <div className={styles.emptyMarkedQuestionsDescriptionWrapper}>
          <p>{t('questionCard.addingToList')}</p>
          <button type="button" className={styles.questionBtnListItem} onClick={() => {}}>
            <i className="icon-star-line" />
          </button>
        </div>
      </div>
    );
  };

  const handleCurrentQuestionDelete = useCallback(() => {
    dispatch(CLEAR_CURRENT_ANSWER_REQUEST({ question, isIncorrectTopic }));
  }, [question, dispatch, isIncorrectTopic]);

  useEffect(() => {
    if (!isLogged && !isDemo) {
      history.push(`/${userLanguage}/`);
    }
  }, [isLogged, isDemo, history, userLanguage]);

  useEffect(() => {
    if (isLogged && isDemo) {
      history.push(`/${userLanguage}/questions`);
    }
  }, [isLogged, isDemo, history, userLanguage]);

  useEffect(() => {
    dispatch(SET_USER_SELECTED_QUESTION(selectedQuestionId));
  }, [dispatch, selectedQuestionId]);

  useEffect(() => {
    if (category !== currentCategory) {
      dispatch(SET_USER_SELECTED_CATEGORY({ category }));
    }
  }, [dispatch, category, currentCategory]);

  useEffect(() => {
    if (isIncorrectTopic) {
      dispatch(GET_ANSWER_LIST_CASH_REQUEST());
    } else {
      dispatch(GET_ANSWER_FROM_FIREBASE_REQUEST({ topic }));
    }
  }, [dispatch, topic, isIncorrectTopic]);

  useEffect(() => {
    dispatch(GET_QUESTION_REQUEST({ topic }));
  }, [dispatch, topic, userAnswersCash]);

  useEffect(() => {
    if (isTopicMarked) {
      dispatch(GET_ANSWER_FROM_FIREBASE_REQUEST({ topic }));
    }
  }, [dispatch, topic, markedQuestions, isTopicMarked]);

  useEffect(() => {
    if (isTopicMarked) {
      dispatch(GET_QUESTION_REQUEST({ topic }));
    }
  }, [dispatch, topic, isTopicMarked]);

  useEffect(() => {
    if (typeof toggleLngState !== 'boolean' && prevLanguage && prevLanguage !== userLanguage) {
      setToggleState(!!toggleLngState);
    }
  }, [toggleLngState, dispatch, prevLanguage, userLanguage, setToggleState]);

  useEffect(() => {
    if (isLogged && userCategories && userCategories.indexOf(category) === -1) {
      dispatch(SET_USER_SELECTED_CATEGORY({ category: userCategories[0] }));
      history.push(`/`);
    }
  }, [isLogged, userCategories, category, history, dispatch]);

  useEffect(() => {
    if (isIncorrectTopic && userAnswersCash == null && currentUser) {
      dispatch(GET_ANSWER_LIST_CASH_REQUEST());
    }
  }, [dispatch, topic, isIncorrectTopic, userAnswersCash, currentUser]);

  useEffect(() => {
    if (
      isTopicMarked &&
      prevMarkedQuestion &&
      prevMarkedQuestion![currentCategory] &&
      prevMarkedQuestion![currentCategory].length === 0
    ) {
      dispatch(GET_QUESTION_REQUEST({ topic }));
    }
  }, [dispatch, topic, isTopicMarked, markedQuestions, prevMarkedQuestion, currentCategory]);

  useEffect(() => {
    if (prevLanguage !== userLanguage) {
      dispatch(GET_TOPICS_REQUEST({ category, language: userLanguage }));
    }
  }, [dispatch, category, prevLanguage, userLanguage]);

  const emptyQuestionContainerClass = !question ? styles.emptyQuestionContainer : '';

  return (
    <div className={styles.questionPage}>
      <Loader show={[PENDING, UNCALLED].includes(requestStatus)} />
      <PlaceholderBox show={[UNCALLED, PENDING, FAILURE].includes(requestStatus)} height="400px">
        <Container>
          <Row>
            <Col md={12} className={emptyQuestionContainerClass}>
              <div className={styles.mobileTopic}>{!isDemo && <TopicSelectorContainer selectedTopicId={topic} />}</div>
              <div className={styles.questionBar}>
                <div className={styles.headerQuestionBox}>
                  {question && (
                    <div className={styles.doubleCounter}>
                      <Counter current={selectedQuestionId + 1} maxSize={questionList.length} />
                    </div>
                  )}
                  {!isDemo && <TopicSelectorContainer selectedTopicId={topic} />}
                </div>
                <div>
                  {question && (
                    <QuestionBar
                      isLogged={isLogged}
                      languageToggleState={toggleLngState}
                      selectedLang={toggleLngState && selectedQuestionLang ? selectedQuestionLang : userLanguage}
                      onLngToggleClick={handleLngToggleClick}
                      onToggleChangeLng={handleToggleChangeLng}
                      questionId={selectedQuestionId}
                      onQuestionChange={handleQuestionChange}
                      topic={topic}
                      theoryId={question.theoryid}
                      userLanguage={userLanguage}
                      defaultRoute={`/${userLanguage}/questions/${currentCategory}/${topic}`}
                      questionList={questionList}
                      onLngChange={changeLanguage}
                      lng={questionLanguage}
                      questionLngList={Object.keys(question.langs)}
                      isMarked={isMarked}
                      onAddToMarked={handleToMarked}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {isTopicMarked && !question ? (
            <>{renderEmptyMarkedText()}</>
          ) : (
            question && (
              <QuestionView
                onQuestionChange={handleQuestionChange}
                onCheckedAnswerChange={handleAnswerChange}
                selectedAnswers={userAnswer ? userAnswer.selected : []}
                isCorrect={isCorrect}
                lng={questionLanguage}
                question={question}
                questionId={selectedQuestionId + 1}
                defaultRoute={`/${userLanguage}/questions/${currentCategory}/${topic}`}
                questionCount={questionList.length}
                onQuestionCheck={handleQuestionCheck}
              />
            )
          )}
          <div className={styles.questionLinkBottom}>
            <Button disabled={!isUserAnswered} variant="secondary" onClick={handleCurrentQuestionDelete}>
              {t('questionCard.resetAnswer')}
            </Button>
            <LinkWrapper className="btnLabel btn" to="/questions">
              {t('questionCard.exitLearningMode')}
            </LinkWrapper>
          </div>
          {!isLogged && (
            <Jumbotron className={styles.theoryMobile}>
              <PageTitle>{t('questionCard.mobileTitle')}</PageTitle>
              <P3>{t('questionCard.mobileText')}</P3>
              <div className={styles.theoryMobileFooter}>
                <LinkWrapper className="linkBtn" to="/theory">
                  {t('questionCard.mobileBtn')}
                  <i className="icon-arrow" />
                </LinkWrapper>
              </div>
            </Jumbotron>
          )}
        </Container>
      </PlaceholderBox>
      <Ads />
    </div>
  );
};

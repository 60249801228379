/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';
import styles from './TheoretischeFahrpruefungPage.module.css';

const TheoretischeFahrpruefungPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1>Theoretische Fahrprüfung: Effektives Lernen mit »theorie24«</h1>

        <img
          className={styles.mainImage}
          src="/content/tour/fragen.png"
          alt="Theoretische Fahrprüfung - theorie24-App"
          width="400"
          height="250"
        />
        <p>
          Die <b>theoretische Fahrprüfung</b> ist der erste grosse Schritt auf dem Weg zur unbefristeten Fahrerlaubnis.
          Theorie24 bietet dir die Chance, online für deine Prüfung zu lernen und deine Erfolgschancen zu maximieren.{' '}
          <b>Lege noch heute einen Zugang an</b> und beginne mit den Prüfungsvorbereitungen!
        </p>

        <h2>Mit 3D-Animationen für die theoretische Fahrprüfung lernen</h2>

        <p>
          Der Fortschritt macht auch vor der Fahrausbildung nicht halt: Wo früher Bücher gewälzt wurden, werden heute
          alle Vorteile der digitalen Technologien genutzt. Unsere Theorie-Lernapp enthält <b>3D-Animationen</b>, die
          dir knifflige Verkehrssituationen erläutern. Diese Art der Vorbereitung auf die theoretische Fahrprüfung ist
          äusserst effektiv – probiere es aus!
        </p>

        <h3>Jederzeit eine Probeprüfung absolvieren</h3>

        <p>
          Als Nutzer/in von »theorie24« hast du die Möglichkeit, jederzeit eine Probeprüfung abzulegen. So kannst du die
          realen Prüfungsbedingungen zu Hause nachstellen. Du lernst, dich an zeitliche Beschränkungen zu gewöhnen und
          die gestellten Fragen zügig zu beantworten. Wenn der Tag der theoretischen Fahrprüfung gekommen ist, bist du{' '}
          <b>perfekt vorbereitet</b> und kannst gelassen an die Aufgabe herangehen.
        </p>

        <h3>Lernen mit den Originalfragen des Strassenverkehrsamtes</h3>

        <p>
          Wenn du dich für »theorie24« entscheidest, lernst du mit den <b>Originalfragen des Strassenverkehrsamtes</b>.
          Als Partnerunternehmen der Organisation <a href="https://asa.ch/">asa</a> (Vereinigung der Schweizer
          Strassenverkehrsämter) haben wir Zugang zu allen Prüfungsfragen. Wir aktualisieren unsere Lern-App ständig,
          sodass du immer mit den aktuellen Fragen lernst.
        </p>

        <h3>Auto, Roller und weitere Fahrzeugklassen</h3>

        <p>
          Unsere bewährte Lern-App deckt mehrere Fahrzeugklassen ab. Egal, ob du für die
          <LinkWrapper to="/autopruefung-theorie"> Autoprüfung Theorie</LinkWrapper> lernst oder dich auf die
          <LinkWrapper to="/rollerpruefung"> Rollerprüfung</LinkWrapper> vorbereitest: Mit »theorie24« hast du eine App
          zur Verfügung, die dich fit für den obligatorischen Theorie-Test macht. Mit Hilfe von »theorie24« kannst du
          sogar für die <LinkWrapper to="/mofa-pruefung">Mofaprüfung</LinkWrapper> lernen. Welche Fahrzeugklassen in
          deiner Version inkludiert sind, erfährst du in den Produktbeschreibungen im Shop.
        </p>

        <h3>Erhältlich als Onlineversion oder USB-Stick</h3>

        <p>
          »theorie24« ist in verschiedenen Versionen erhältlich. Du kannst dich entweder online auf die theoretische
          Fahrprüfung vorbereiten oder die App auf deinem Endgerät installieren. Wir bieten Theorie24 als DVD, USB-Stick
          sowie als Download-Datei an. Online-Nutzer können sich <b>mit einem Login auf allen Geräten anmelden</b> und
          lernen.
        </p>

        <h3>Bewährt seit über 20 Jahren</h3>

        <p>
          Seit 1999 ist »theorie24« erste Wahl, wenn es darum geht, sich effizient auf die theoretische Fahrprüfung
          vorzubereiten. Unsere beliebte App hat bereits <b>mehr als 250‘000 Käufer</b> gefunden. Neben der{' '}
          <b>hohen Bedienfreundlichkeit</b> schätzen die Nutzer auch die Möglichkeit, in <b>verschiedenen Sprachen</b>{' '}
          (z.B. Englisch, Türkisch oder Portugiesisch) zu lernen.
        </p>

        <img
          className={styles.mainImage}
          src="/content/tour/theorie.png"
          alt="Theoretische Fahrprüfung – Theorie24 Basistheorie"
          width="400"
          height="250"
        />
        <h3>Jetzt mit dem Lernen beginnen!</h3>

        <p>
          <b>
            <LinkWrapper to="/offers">Registriere dich noch heute</LinkWrapper>
          </b>
          , um eine optimale Vorbereitung auf die theoretische Fahrprüfung zu erhalten! Falls du einen Code besitzt,
          klickst du einfach im Menü auf »VIP-Code« und gibst ihn anschliessend ein. Ganz gleich, für welche Version du
          dich entscheidest: Mit »theorie24« wird die Vorbereitung auf die <a href="/theoriepruefung">Theorieprüfung</a>{' '}
          zu einem Kinderspiel!
        </p>
      </Container>
    </div>
  );
};

export default TheoretischeFahrpruefungPage;

import { all, put, select, takeEvery } from 'redux-saga/effects';
import shuffle from 'lodash.shuffle';
import { toast } from 'react-hot-toast';
import {
  GET_QUESTION_FOR_EXAM_SUCCESS,
  GET_QUESTION_FOR_EXAM_FAILURE,
  ADD_EXAM_ANSWER_REQUEST,
  SAVE_NEW_EXAM_REQUEST,
  CREATE_NEW_EXAM_SUCCESS,
  CREATE_NEW_EXAM_FAILURE,
  SAVE_NEW_EXAM_SUCCESS,
  SAVE_NEW_EXAM_FAILURE,
  ADD_EXAM_ANSWER_FAILURE,
  ADD_EXAM_ANSWER_SUCCESS,
  RECOVERY_EXAM_FAILURE,
  RECOVERY_EXAM_SUCCESS,
  FINISH_EXAM_SUCCESS,
  FINISH_EXAM_FAILURE,
  GET_QUESTION_FOR_EXAM_REQUEST,
  UPDATE_EXAM_RESULT_FAILURE,
  UPDATE_EXAM_RESULT_SUCCESS,
  UPDATE_ANSWER_POINT_FAILURE,
  UPDATE_ANSWER_POINT_REQUEST,
  UPDATE_ANSWER_POINT_SUCCESS,
  GET_LAST_EXAM_FAILURE,
  GET_LAST_EXAM_SUCCESS,
  CLEAR_EXAM_HISTORY_REQUEST,
  CLEAR_EXAM_HISTORY_SUCCESS,
  CLEAR_EXAM_HISTORY_FAILURE
} from '../actions/exam.action';
import { firebaseApp } from '../config/firebase.config';
import {
  fbrecoveryExamAnswers,
  createExamData,
  createExamAnswer,
  updateAnswerPoint,
  addExamAnswer,
  finishExam,
  lastExam,
  getExamById,
  clearExamHistoryRequest
} from './exam.api.fb';
import { getAllQuestions as fbgetAllQuestions, getDemoQuestions } from './question.api';
import { checkIsAnswerIsCorrect, getFBCategoryName, getRandomListFromArr, addAnswerToAnswerList } from '../utils/utils';
import { IRootState } from '../reducer';
import { CategoryType, IQuestion, ILangsAnswersAttr } from '../reducer/question.types';
import { IExamAnswerPayload } from '../containers/ExamCardContainer/ExamCardContainer.types';
import { IUserExamAnswerByExamId, IUserExamAnswerList } from '../reducer/exam.types';
import { prefetchQuestionsMediaSources } from '../utils/prefetchMediaSources';
import i18n from '../i18n-local';

const getSelectedExamCategory = ({ user }: IRootState) => user.profile.currentCategory;
const getUserExamQuestionList = ({ exam }: IRootState) => exam.examQuestionList;
const getTrueQuestionCount = ({ exam }: IRootState) => exam.trueQuestionCount;
const getLogged = ({ login }: IRootState): boolean => login.isLogged;
const getCategory = ({ user }: IRootState) => user.profile.currentCategory;
const getAllQuestions = ({ exam }: IRootState) => exam.examQuestionList;
const getAllAnswers = ({ exam }: IRootState) => exam.examAnswerList;

const recoveryExamQuestion = async (answers, category) => {
  const allQuestions = await fbgetAllQuestions(category);
  return (
    allQuestions
      .filter(question => {
        if (question && question.id) {
          return answers[question.id];
        }
        return false;
      })
      // @ts-ignore
      .sort((a, b) => answers[a.id].sortnr - answers[b.id].sortnr)
  );
};

const shuffleExamQuestion = (examQuestionList: IQuestion[]) => {
  return examQuestionList.map(({ langs, ...question }) => {
    const newLangs = { ...langs };

    const positionOfAnswers: number[] = [];

    Object.keys(newLangs).forEach(key => {
      if (positionOfAnswers.length === 0) {
        newLangs[key].a = shuffle(newLangs[key].a);

        newLangs[key].a.forEach(({ id }) => positionOfAnswers.push(id));
      } else {
        const newAnswerList: ILangsAnswersAttr[] = [];

        positionOfAnswers.forEach(index => {
          const answer: ILangsAnswersAttr = newLangs[key].a.find(({ id }) => index === id) || newLangs[key].a[0];

          newAnswerList.push(answer);
        });

        newLangs[key].a = newAnswerList;
      }
    });

    return { ...question, langs: newLangs };
  });
};

const recoveryExamAnswers = async (examId: string) => {
  const { currentUser } = firebaseApp.auth();

  return fbrecoveryExamAnswers(currentUser ? currentUser.uid : '', examId);
};

function* getExamQuestions(category: CategoryType, questionCount: number) {
  const isLogged = yield select(getLogged);
  let questions;

  if (isLogged) {
    questions = yield fbgetAllQuestions(category);
  } else {
    questions = yield getDemoQuestions(category);
  }

  return getRandomListFromArr(questions, questionCount);
}

function* getQuestionListForExam(questionCount: number) {
  try {
    yield put(GET_QUESTION_FOR_EXAM_REQUEST());
    const category = yield select(getSelectedExamCategory);
    const data = yield getExamQuestions(category, questionCount);
    yield put(GET_QUESTION_FOR_EXAM_SUCCESS(data));
  } catch (e) {
    yield put(GET_QUESTION_FOR_EXAM_FAILURE(e.message));
  }
}

function* recalculateExamPoint(userAnswer, correctStatus, examId) {
  let trueQuestionCount = yield select(getTrueQuestionCount);
  const allQuestions = yield select(getAllQuestions);
  const allAnswers = yield select(getAllAnswers);
  const currentExamAnswers = allAnswers[examId];

  let truePointCount = 0;
  const currentExamAnswersKeys = Object.keys(currentExamAnswers);
  currentExamAnswersKeys.forEach(key => {
    if ('correct' in currentExamAnswers[key] && currentExamAnswers[key].selected.length) {
      let ques = allQuestions.find(question => {
        return question.id.toString() === key.toString();
      });
      ques = typeof ques !== 'undefined' ? ques : null;

      const currentIncorrectAnswer = [
        ...currentExamAnswers[key].selected.filter(item => {
          return ques ? ques.correct.indexOf(item + 1) < 0 : false;
        }),
        ...(ques ? ques.correct.filter(item => currentExamAnswers[key].selected.indexOf(item - 1) < 0) : false)
      ];
      const currentAnswerPoint: number = 3 - currentIncorrectAnswer.length;
      truePointCount += currentAnswerPoint;
    }
  });

  const oldCorrectStatus: boolean = !!userAnswer.correct;
  const questionDifference = +correctStatus - +oldCorrectStatus;
  trueQuestionCount += questionDifference;

  yield put(UPDATE_ANSWER_POINT_REQUEST({ trueQuestionCount, truePointCount, examId }));
}

function* onUpdateAnswerPoint(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    yield updateAnswerPoint(userId, action.payload);
    yield put(UPDATE_ANSWER_POINT_SUCCESS({ ...action.payload }));
  } catch (e) {
    yield put(UPDATE_ANSWER_POINT_FAILURE(e));
  }
}

function* onExamAnswerChange(action) {
  try {
    const { answerId, question, userAnswer, examId } = action.payload;
    const answerPayload: IExamAnswerPayload = {
      examId,
      questionId: question.id
    };

    const selectedAnswers: number[] = addAnswerToAnswerList(userAnswer, answerId);
    answerPayload.answer = {
      sortnr: userAnswer.sortnr,
      selected: selectedAnswers
    };

    const correctStatus: boolean = checkIsAnswerIsCorrect(question, selectedAnswers);
    answerPayload.answer = {
      sortnr: userAnswer.sortnr,
      selected: selectedAnswers,
      correct: correctStatus,
      favorite: !!userAnswer.favorite
    };

    yield put(ADD_EXAM_ANSWER_REQUEST(answerPayload));
    yield recalculateExamPoint(userAnswer, correctStatus, examId);
  } catch (e) {
    console.log(e.message);
  }
}

function generateExamAnswer(examId, questionList: IQuestion[]): IUserExamAnswerList {
  const answerList: IUserExamAnswerByExamId = {};
  questionList.forEach((question, index) => {
    answerList[question.id] = {
      sortnr: index,
      selected: []
    };
  });
  return {
    [examId]: answerList
  };
}

function* onCreateNewExam(action) {
  try {
    const {
      payload: { uuid, examStartTime, duration, questionCount, pointCount }
    } = action;
    yield getQuestionListForExam(questionCount);
    const examQuestionList: IQuestion[] = yield select(getUserExamQuestionList);
    const examAnswerList: IUserExamAnswerList = generateExamAnswer(uuid, examQuestionList);
    const examQuestionListShuffle = shuffleExamQuestion(examQuestionList);

    const examState = {
      uuid,
      examStartTime,
      duration,
      questionCount,
      trueQuestionCount: 0,
      truePointCount: 0,
      pointCount,
      examQuestionList: examQuestionListShuffle,
      examAnswerList
    };

    yield put(SAVE_NEW_EXAM_REQUEST(examState));
    yield put(CREATE_NEW_EXAM_SUCCESS());
  } catch (e) {
    yield put(CREATE_NEW_EXAM_FAILURE(e));
  }
}

function* onSaveNewExam(action) {
  try {
    const { examAnswerList, uuid, examStartTime, duration, questionCount, pointCount } = action.payload;
    const answerKeyList: string[] = Object.keys(examAnswerList[uuid]);
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);

    yield createExamData(userId, uuid, {
      duration,
      questionCount,
      trueQuestionCount: 0,
      truePointCount: 0,
      pointCount,
      category: categoryForQuery,
      startTime: examStartTime
    });

    const answerMap = {};
    answerKeyList.forEach(key => {
      answerMap[key] = examAnswerList[uuid][key];
    });

    yield createExamAnswer(userId, uuid, answerMap);

    yield put(SAVE_NEW_EXAM_SUCCESS());
  } catch (e) {
    yield put(SAVE_NEW_EXAM_FAILURE(e));
  }
}

function* onAddExamAnswer(action) {
  try {
    const { answer, questionId, examId } = action.payload;
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    yield addExamAnswer(userId, examId, questionId, answer);
    yield put(ADD_EXAM_ANSWER_SUCCESS());
  } catch (e) {
    yield put(ADD_EXAM_ANSWER_FAILURE(e));
  }
}

function* onRecoveryExam(action) {
  try {
    const { examId } = action.payload;
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    const examDataFromDb = yield getExamById(userId, examId);
    if (examDataFromDb) {
      const examStartTime = examDataFromDb ? examDataFromDb.startTime : null;
      const isFinished = examDataFromDb ? examDataFromDb.finish : null;
      const pointCount = examDataFromDb ? examDataFromDb.pointCount : null;
      const duration = examDataFromDb ? examDataFromDb.duration : null;
      const questionCount = examDataFromDb ? examDataFromDb.questionCount : null;
      const truePointCount = examDataFromDb ? examDataFromDb.truePointCount : null;
      const trueQuestionCount = examDataFromDb ? examDataFromDb.trueQuestionCount : null;
      const category = examDataFromDb ? examDataFromDb.category : null;

      const examAnswerList = yield recoveryExamAnswers(examId);
      const examQuestionList = yield recoveryExamQuestion(examAnswerList[examId], category);
      const examQuestionListShuffle = !isFinished ? shuffleExamQuestion(examQuestionList) : examQuestionList;

      yield put(
        RECOVERY_EXAM_SUCCESS({
          duration,
          pointCount,
          questionCount,
          truePointCount,
          trueQuestionCount,
          examStartTime,
          isFinished,
          currentExamId: examId,
          examQuestionList: examQuestionListShuffle,
          examAnswerList
        })
      );
    } else {
      yield put(RECOVERY_EXAM_FAILURE('Exam not found.'));
    }
  } catch (e) {
    yield put(RECOVERY_EXAM_FAILURE(e));
  }
}

function* onFinishExam(action) {
  try {
    const { examId } = action.payload;
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    yield finishExam(userId, examId);
    yield put(FINISH_EXAM_SUCCESS());
  } catch (e) {
    yield put(FINISH_EXAM_FAILURE(e));
  }
}

function* onUpdateExamResult(action) {
  try {
    const { examRoute } = action.payload;
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    const examInfo = yield getExamById(userId, examRoute);
    yield put(
      UPDATE_EXAM_RESULT_SUCCESS({
        questionCount: examInfo.questionCount,
        pointCount: examInfo.pointCount,
        trueQuestionCount: examInfo.trueQuestionCount,
        truePointCount: examInfo.truePointCount,
        examStartTime: examInfo.startTime,
        endTime: examInfo.endTime || +new Date(),
        duration: examInfo.duration,
        examId: examRoute
      })
    );
  } catch (e) {
    yield put(UPDATE_EXAM_RESULT_FAILURE(e));
  }
}

function* getLastExam() {
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';
    const examInfo = yield lastExam(userId);

    if (examInfo) {
      yield put(
        GET_LAST_EXAM_SUCCESS({
          uid: examInfo.examId,
          duration: examInfo.duration,
          examStartTime: examInfo.startTime,
          endTime: examInfo.endTime,
          finish: examInfo.finish
        })
      );
    } else {
      yield put(GET_LAST_EXAM_FAILURE('Empty exam history!'));
    }
  } catch (e) {
    yield put(GET_LAST_EXAM_FAILURE(e));
  }
}

function* clearExamHistory() {
  const toastId = toast.loading('Loading...', {
    position: 'top-right'
  });
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    if (userId) {
      yield clearExamHistoryRequest(userId, categoryForQuery);
      toast.success(i18n.t('theoryPage.bookMark.successfullyRemoved'), {
        id: toastId,
        position: 'top-right'
      });
      yield put(CLEAR_EXAM_HISTORY_SUCCESS());
    }
  } catch (e) {
    toast.error('Error', {
      id: toastId,
      position: 'top-right'
    });
    yield put(CLEAR_EXAM_HISTORY_FAILURE(e));
  }
}

function prefetchQuestionMedia({ payload }: { payload: { examQuestionList: IQuestion[] } }) {
  prefetchQuestionsMediaSources(payload.examQuestionList);
}

function* questionSaga() {
  yield all([
    takeEvery('CREATE_NEW_EXAM_REQUEST', onCreateNewExam),
    takeEvery('CHANGE_EXAM_ANSWER', onExamAnswerChange),
    takeEvery('ADD_EXAM_ANSWER_REQUEST', onAddExamAnswer),
    takeEvery('SAVE_NEW_EXAM_REQUEST', onSaveNewExam),
    takeEvery('FINISH_EXAM_REQUEST', onFinishExam),
    takeEvery('RECOVERY_EXAM_REQUEST', onRecoveryExam),
    takeEvery('UPDATE_ANSWER_POINT_REQUEST', onUpdateAnswerPoint),
    takeEvery('UPDATE_EXAM_RESULT_REQUEST', onUpdateExamResult),
    takeEvery('GET_LAST_EXAM_REQUEST', getLastExam),
    takeEvery(RECOVERY_EXAM_SUCCESS, prefetchQuestionMedia),
    takeEvery(SAVE_NEW_EXAM_REQUEST, prefetchQuestionMedia),
    takeEvery(CLEAR_EXAM_HISTORY_REQUEST, clearExamHistory)
  ]);
}

export default questionSaga;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { IOfferCardProps } from './OfferCard.types';
import styles from './OfferCard.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const OfferCard: React.FC<IOfferCardProps> = ({ offer, linkTo, currentLanguage }: IOfferCardProps) => {
  const { t } = useTranslation();

  let offerDays = 'offersPage.days';
  if (offer.days === 1) {
    offerDays = 'offersPage.day';
  }

  let activeClass = '';
  if (offer.id === 4) {
    activeClass = styles.suggested;
  }

  return (
    <Card className={activeClass}>
      <Card.Body>
        <Card.Title>
          <h3>{offer.title}</h3>
          <span className="header-text">{offer.cost}</span>
        </Card.Title>
        <Card.Text className="p3 pageText">{offer.langs[currentLanguage].description}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <span className="footerDays">{`${offer.days} ${t(offerDays)}`}</span>
        <LinkWrapper className="linkBtn" to={linkTo}>
          <span className="continueText">{t('offersPage.more')}</span>
          <i className="icon-arrow" />
        </LinkWrapper>
      </Card.Footer>
    </Card>
  );
};

export default OfferCard;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { IMoreInfoOfferLinkProps } from './MoreInfoOfferLink.types';
import styles from './MoreInfoOfferLink.module.css';

const MoreInfoOfferLink: React.FC<IMoreInfoOfferLinkProps> = ({
  className = '',
  to = '/offers'
}: IMoreInfoOfferLinkProps) => {
  const { t } = useTranslation();

  return (
    <LinkWrapper className={`${styles.moreButton} linkBtn ${className}`} to={to}>
      {t('homePageNew.callToActionSection.more')}
      <i className="icon-arrow" />
    </LinkWrapper>
  );
};

export default MoreInfoOfferLink;

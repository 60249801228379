import { handleActions } from 'redux-actions';
import {
  IGameState,
  IGameDifficultyAction,
  IGameTypeAction,
  IGameDurationAction,
  IGameLivesModeAction,
  IGameCategoryAction,
  IGameIncrementScoreAction,
  IGameStartTimeAction,
  IGameUpdateAnswerAction,
  gameLives,
  IShowSingleGameHistoryAction
} from './game.types';
import {
  INCREMENT_SCORE,
  SET_GAME_CATEGORY,
  SET_GAME_DIFFICULTY,
  SET_GAME_DURATION,
  SET_GAME_LIVES_MODE,
  SET_GAME_TYPE,
  DECREMENT_LIVE,
  RESTART_GAME,
  START_GAME,
  END_GAME,
  SET_START_TIME,
  UPDATE_GAME_ANSWER_LIST,
  CREAT_GAME_ANSWER_LIST_FROM_ALL_QUESTION,
  ADD_NEW_GAME_ANSWER_REQUEST,
  UPDATE_QUESTION_INDEX,
  UPDATE_EXTRA_SCORE,
  SHOW_GAME_FROM_HISTORY,
  SET_LIVES_COUNT
} from '../actions/game.action';
import { SET_GAME_STATUS_HISTORY } from '../actions/gameScore.action';
import {
  GAME_LIVES_OBJ,
  GAME_DURATION_NAME_SHORT,
  GAME_END_STATUS,
  GAME_PLAY_STATUS,
  BEGINNER_DIFFICULTY,
  GAME_CATEGORY_CAR,
  GAME_LIVE_NAME_LONG,
  GAME_TYPE_LIVES,
  EXTRA_SCORE_LIST,
  GAME_HISTORY_STATUS
} from '../constants';
import { getFBCategoryName, sliceQuestion } from '../utils/utils';

const clearGameState = {
  currentQuestionId: null,
  score: 0
};

export const setLivesCount = (name: gameLives) => {
  return GAME_LIVES_OBJ[name];
};

const defaultState: IGameState = {
  difficulty: BEGINNER_DIFFICULTY,
  type: GAME_TYPE_LIVES,
  currentQuestionId: null,
  score: 0,
  extraScore: 0,
  startTime: null,
  status: '',
  sortedQuestionList: sliceQuestion(GAME_DURATION_NAME_SHORT),
  duration: GAME_DURATION_NAME_SHORT,
  livesMode: GAME_LIVE_NAME_LONG,
  livesCount: setLivesCount(GAME_LIVE_NAME_LONG),
  questionCategory: GAME_CATEGORY_CAR,
  gameStartTime: 0,
  answerList: [],
  currentQuestionIndex: 0
};

const reduces = handleActions(
  {
    [SET_GAME_DIFFICULTY]: (state, action: IGameDifficultyAction) => ({
      ...state,
      difficulty: action.payload.difficulty
    }),
    [SET_GAME_TYPE]: (state, action: IGameTypeAction) => ({
      ...state,
      type: action.payload
    }),
    [SET_GAME_DURATION]: (state, action: IGameDurationAction) => ({
      ...state,
      duration: action.payload.duration
    }),
    [SET_GAME_LIVES_MODE]: (state, action: IGameLivesModeAction) => {
      return {
        ...state,
        livesMode: action.payload.livesMode,
        livesCount: setLivesCount(action.payload.livesMode)
      };
    },
    [SET_GAME_CATEGORY]: (state, action: IGameCategoryAction) => ({
      ...state,
      questionCategory: action.payload.questionCategory
    }),
    [INCREMENT_SCORE]: (state, action: IGameIncrementScoreAction) => {
      return {
        ...state,
        score: state.score + action.payload,
        extraScore: EXTRA_SCORE_LIST[0]
      };
    },
    [SET_LIVES_COUNT]: (state, action) => {
      return {
        ...state,
        livesCount: action.payload
      };
    },
    [DECREMENT_LIVE]: state => ({
      ...state,
      livesCount: state.livesCount - 1,
      extraScore: EXTRA_SCORE_LIST[0]
    }),
    [RESTART_GAME]: state => {
      const questionList = sliceQuestion(state.duration);
      return {
        ...state,
        ...clearGameState,
        status: GAME_PLAY_STATUS,
        sortedQuestionList: questionList,
        answerList: questionList,
        livesCount: setLivesCount(state.livesMode)
      };
    },
    [START_GAME]: (state, action) => {
      const questionList = sliceQuestion(state.duration, getFBCategoryName(action.payload));
      // @ts-ignore
      return {
        ...state,
        ...clearGameState,
        // type: action.payload.type,
        status: GAME_PLAY_STATUS,
        sortedQuestionList: questionList,
        startTime: Date.now(),
        answerList: questionList,
        livesCount: setLivesCount(state.livesMode),
        currentQuestionIndex: 0,
        extraScore: EXTRA_SCORE_LIST[0]
      };
    },
    [ADD_NEW_GAME_ANSWER_REQUEST]: state => {
      return {
        ...state,
        currentQuestionIndex: state.currentQuestionIndex + 1
      };
    },
    // [START_PRACTICE]: state => ({
    //   ...state,
    //   ...state,
    //   ...clearGameState,
    //   type: GAME_TYPE_SIMPLE,
    //   status: GAME_PLAY_STATUS,
    //   sortedQuestionList: getPracticeQuestion()
    // }),
    [END_GAME]: state => ({
      ...state,
      status: GAME_END_STATUS,
      currentQuestionIndex: state.currentQuestionIndex - 1
    }),
    [SET_START_TIME]: (state, action: IGameStartTimeAction) => ({
      ...state,
      gameStartTime: action.payload.time
    }),
    // ANSWER
    [UPDATE_GAME_ANSWER_LIST]: (state, action: IGameUpdateAnswerAction) => ({
      ...state,
      answerList: action.payload.answerList
    }),
    [CREAT_GAME_ANSWER_LIST_FROM_ALL_QUESTION]: (state, action: IGameUpdateAnswerAction) => ({
      ...state,
      answerList: action.payload.answerList
    }),
    [UPDATE_QUESTION_INDEX]: (state, action: IGameIncrementScoreAction) => {
      if (action.payload !== state.currentQuestionIndex) {
        return {
          ...state,
          currentQuestionIndex: +action.payload
        };
      }
      return { ...state };
    },
    [UPDATE_EXTRA_SCORE]: (state, action) => ({
      ...state,
      extraScore: action.payload
    }),
    [SET_GAME_STATUS_HISTORY]: state => ({
      ...state,
      status: GAME_HISTORY_STATUS
    }),
    [SHOW_GAME_FROM_HISTORY]: (state, action: IShowSingleGameHistoryAction) => ({
      ...state,
      answerList: action.payload.answerList,
      sortedQuestionList: action.payload.sortedQuestionList
    })
  },
  defaultState
);

export default reduces;

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import GameModeList from '../../components/Game/GameModeList/GameModeList';
import { IRootState } from '../../reducer';
import { GAME_DURATION_SHORT, GAME_LIVE_NAME_LONG, GAME_LIVE_NAME_MEDIUM, GAME_LIVE_NAME_SHORT } from '../../constants';
import { SET_GAME_LIVES_MODE, START_GAME } from '../../actions/game.action';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { PageText } from '../../ui-components/PageText/PageText';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import styles from './SelectGameModeContainer.module.css';

const GAME_DIFFICULTY_LIST = [GAME_LIVE_NAME_LONG, GAME_LIVE_NAME_MEDIUM, GAME_LIVE_NAME_SHORT];

const SelectGameModeContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { livesMode } = useSelector(({ game }: IRootState) => game);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);

  const onStartGame = useCallback(() => {
    dispatch(START_GAME(currentCategory));
  }, [dispatch, currentCategory]);

  const renderInfoCard = useCallback(
    () => (
      <div className={styles.gameCardInfo}>
        <div className={styles.gameCardItem}>
          <div className={styles.cardLabel}>{t('gameContainer.maximumPoints')}</div>
          <div className={styles.cardValue}>250</div>
        </div>
        <div className={styles.gameCardItem}>
          <div className={styles.cardLabel}>{t('gameContainer.questions')}</div>
          <div className={styles.cardValue}>{GAME_DURATION_SHORT}</div>
        </div>
      </div>
    ),
    [t]
  );

  const handleChangeLivesMode = useCallback(
    (id: string) => () => {
      const newGameType = {
        livesMode: id
      };
      dispatch(SET_GAME_LIVES_MODE(newGameType));
    },
    [dispatch]
  );

  const selectDifficultyByKeyPress = useCallback(
    (direction: 'left' | 'right', selectedLivesMode) => {
      const getCurrentIndex = GAME_DIFFICULTY_LIST.findIndex(item => item === selectedLivesMode);
      if (getCurrentIndex !== -1) {
        let newSelectedLivesIndex = direction === 'left' ? getCurrentIndex - 1 : getCurrentIndex + 1;
        newSelectedLivesIndex = newSelectedLivesIndex > GAME_DIFFICULTY_LIST.length - 1 ? 0 : newSelectedLivesIndex;
        newSelectedLivesIndex = newSelectedLivesIndex < 0 ? GAME_DIFFICULTY_LIST.length - 1 : newSelectedLivesIndex;
        handleChangeLivesMode(GAME_DIFFICULTY_LIST[newSelectedLivesIndex])();
      }
    },
    [handleChangeLivesMode]
  );

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        selectDifficultyByKeyPress('left', livesMode);
      }
      if (e.keyCode === 39) {
        selectDifficultyByKeyPress('right', livesMode);
      }

      if (e.keyCode === 13) {
        onStartGame();
      }
    },
    [onStartGame, livesMode, selectDifficultyByKeyPress]
  );

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [onStartGame, escapeListener]);

  const titleClass = `${styles.title} retroFont`;
  const descriptionClass = `${styles.description} retroFont`;
  const startGameButtonClass = `${styles.startButton} retroColorChangeClass`;

  return (
    <div className={styles.container}>
      <PageTitle className={titleClass}>{t('gameContainer.title')}</PageTitle>
      <PageText className={descriptionClass} variant="p3">
        {t('gameContainer.description')}
      </PageText>
      {renderInfoCard()}
      <PageText className={descriptionClass} variant="p3">
        {t('gameContainer.chooseLevel')}
      </PageText>
      <GameModeList livesMode={livesMode} />
      <div className={styles.startButtonContainer}>
        <Button className={startGameButtonClass} variant="secondary" onClick={onStartGame}>
          {t('gameContainer.startGame')}
        </Button>
        <LinkWrapper className="retroFont" to="/game-score">{`${t('gameContainer.resultTable')} >`}</LinkWrapper>
      </div>
    </div>
  );
};

export default SelectGameModeContainer;

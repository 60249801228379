import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { firebaseApp } from '../config/firebase.config';
import { usePrevious } from './usePrevious';

// call cb after user data fetched from firebase
export default function useAfterUserDataFetched(cb: () => void) {
  const dispatch = useDispatch();
  const { currentUser } = firebaseApp.auth();
  const previousUser = usePrevious(currentUser);
  const isDataFetched = !!currentUser;

  useEffect(() => {
    if (!previousUser && currentUser) {
      cb();
    }
  }, [dispatch, currentUser, previousUser, cb]);

  return isDataFetched;
}

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { IRootState } from '../reducer';
import { ThemeColors } from '../reducer/theme.types';
import { SET_THEME_COLOR } from '../actions/theme.action';

export function useGetSelectedColorTheme() {
  const dispatch = useDispatch();
  const { location } = useReactRouter();

  const selectedColorTheme = useSelector(({ theme }: IRootState) => theme.colorTheme);
  const isDarkTheme = selectedColorTheme === ThemeColors.dark;

  const isGamePath = location.pathname.includes('game');

  const setThemColor = useCallback(
    (themeColor: ThemeColors) => {
      dispatch(SET_THEME_COLOR(themeColor));
    },
    [dispatch]
  );

  return { selectedColorTheme, isDarkTheme: isGamePath ? true : isDarkTheme, setThemColor };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../../../utils/utils';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import styles from './ImageBreak.module.css';

const image = imageAbsolutePath('offers-break-image.png');

const ImageBreak: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <TitleH3 center text={t('offersPageNew.imageBreakSection.title')} />
      <img className={styles.mainImg} src={image} alt={t('offersPageNew.imageBreakSection.imageAlt')} />
    </section>
  );
};

export default ImageBreak;

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { IOffersState } from '../../reducer/offer.types';
import { selectOffer } from '../../reducer/offer.selectors';
import { UNCALLED } from '../../constants/store.constants';
import { GET_OFFERS_REQUEST } from '../../actions/offer.action';
import { useGetDataLoadingStatusStore } from '../useDataLoadingFromStore';

export const useGetOfferList = () => {
  const dispatch = useDispatch();

  const { offersList, offerListRequest }: IOffersState = useSelector(selectOffer);
  const { isFetching } = useGetDataLoadingStatusStore('offer.offerListRequest.status');

  useEffect(() => {
    if (offerListRequest.status === UNCALLED) {
      dispatch(GET_OFFERS_REQUEST());
    }
  }, [dispatch, offerListRequest]);

  return {
    offersList,
    isFetching
  };
};

// @ts-nocheck
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import TopController from '../../components/Game/TopController/TopController';
import CustomSwiper from '../../components/CustomSwiper/CustomSwiper';
import SingleCard from '../../components/Game/SingleCard/SingleCard';
import { ADD_NEW_GAME_ANSWER_REQUEST, END_GAME, UPDATE_QUESTION_INDEX } from '../../actions/game.action';
import { IRootState } from '../../reducer';
import { IGameContainerProps } from './GameContainer.types';
import { GAME_END_STATUS, GAME_HISTORY_STATUS } from '../../constants';
import { AnswerObj } from '../../reducer/game.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import VolumeSettings from '../../components/VolumeSettings/VolumeSettings';
import TrafficLightWrapper from '../../components/Game/TrafficLightWrapper/TrafficLightWrapper';
import styles from './GameContainer.module.css';
import useDeviceSizes from '../../hooks/useDeviceSizes';

const GameContainer: React.FC<IGameContainerProps> = ({ routeParams }: IGameContainerProps) => {
  const { t } = useTranslation();
  const { index = 1 } = routeParams;

  const [questionIndex, setQuestionIndex] = useState(null);

  const dispatch = useDispatch();

  const swiperRef = useRef(null);

  const sortedQuestion = useSelector(({ game }: IRootState) => game.sortedQuestionList);
  const gameStatus = useSelector(({ game }: IRootState) => game.status);
  const isHistoryState = useMemo(() => gameStatus === GAME_HISTORY_STATUS, [gameStatus]);
  const isGameFinished = gameStatus === GAME_END_STATUS || isHistoryState;
  const [showStartModalState, setShowModalState] = useState(!isGameFinished);
  const questionStoreIndex = useSelector(({ game }: IRootState) => game.currentQuestionIndex);
  const userAnswersList: AnswerObj[] = useSelector(({ game }: IRootState) => game.answerList);
  const isSoundAvailableForThisDevice = useSelector(({ sound }: IRootState) => sound.isSoundAvailableForThisDevice);
  const { history }: RouteComponenquestionLinkBottomtProps = useReactRouter();

  const { width } = useDeviceSizes();
  const isMobileMode = useMemo(() => width <= 991, [width]);

  const swiperContainerStyle = useMemo(() => (isMobileMode ? { minHeight: 445 } : { minHeight: 645 }), [isMobileMode]);

  const onAgreeClick = useCallback(() => {
    if (!isGameFinished && !showStartModalState) swiperRef.current.onRightMove();
    // history.push(`/${userLanguage}/game/${+index + 1}`);
  }, [swiperRef, isGameFinished, showStartModalState]);

  const onDeclineClick = useCallback(() => {
    if (!isGameFinished && !showStartModalState) swiperRef.current.onLeftMove();
    // history.push(`/${userLanguage}/game/${+index + 1}`);
  }, [swiperRef, isGameFinished, showStartModalState]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        // left
        onDeclineClick();
      }
      if (e.keyCode === 39) {
        // right
        onAgreeClick();
      }
    },
    [onAgreeClick, onDeclineClick]
  );

  const handleAnswer = useCallback(
    (id: number, answer: boolean | null, questionAnswerId: number) => {
      if (!isGameFinished) {
        dispatch(ADD_NEW_GAME_ANSWER_REQUEST({ id, answer, questionAnswerId }));
      }
    },
    [dispatch, isGameFinished]
  );

  const onExitClick = useCallback(() => {
    dispatch(END_GAME());
  }, [dispatch]);

  const onHideModal = useCallback(() => setShowModalState(false), []);

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [onAgreeClick, onDeclineClick, escapeListener]);

  useEffect(() => {
    if (isGameFinished) {
      setQuestionIndex(questionStoreIndex);
      // dispatch(UPDATE_QUESTION_INDEX(index - 1));
    }
  }, [index, isGameFinished, dispatch, questionStoreIndex]);

  useEffect(() => {
    if (gameStatus === '') {
      history.push('game-mode');
    }
  }, [gameStatus, history]);

  const disabledPrevBtn = useMemo(() => questionIndex === 0, [questionIndex]);
  const disabledNextBtn = useMemo(() => questionIndex === sortedQuestion.length - 1, [questionIndex, sortedQuestion]);
  const moveBack = useCallback(() => {
    if (!disabledPrevBtn) {
      dispatch(UPDATE_QUESTION_INDEX(questionStoreIndex - 1));
      setQuestionIndex(questionStoreIndex - 1);
    }
  }, [questionStoreIndex, setQuestionIndex, disabledPrevBtn, dispatch]);

  const moveForward = useCallback(() => {
    if (!disabledNextBtn) {
      dispatch(UPDATE_QUESTION_INDEX(questionStoreIndex + 1));
      setQuestionIndex(questionStoreIndex + 1);
    }
  }, [questionStoreIndex, setQuestionIndex, disabledNextBtn, dispatch]);

  // const swipeMethodAfterGameFinished = useMemo(() => {
  //   return isGameFinished
  //     ? swipeDirection => {
  //       if (swipeDirection === 'Right') {
  //         return moveBack();
  //       }
  //
  //       if (swipeDirection === 'Left') {
  //         return moveForward();
  //       }
  //       return null;
  //     }
  //     : undefined;
  // }, [isGameFinished, moveBack, moveForward]);

  const renderFooterControls = useMemo(() => {
    const secondButtonLink = isGameFinished ? '/game-score' : '/game-mode';
    const secondButtonText = isGameFinished ? t('gameContainer.resultTable') : t('gameContainer.exitGame');
    return (
      <div className={styles.questionLinkBottom}>
        {isSoundAvailableForThisDevice && !isGameFinished && <VolumeSettings />}

        <LinkWrapper onClick={onExitClick} className="btnLabel btn" to={secondButtonLink}>
          {secondButtonText}
        </LinkWrapper>
      </div>
    );
  }, [isSoundAvailableForThisDevice, isGameFinished, onExitClick, t]);
  if (gameStatus === '') {
    return null;
  }

  return (
    <div className={styles.gamePage}>
      <Container>
        <Row>
          <Col md={12}>
            <TopController showExtraScore={!showStartModalState} isGameFinished={isGameFinished} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {showStartModalState ? (
              <div className={styles.trafficLightContainer}>
                <TrafficLightWrapper show={showStartModalState} onHide={onHideModal} />
              </div>
            ) : (
              <CustomSwiper
                containerStyle={swiperContainerStyle}
                componentStyle={{ cursor: 'grab' }}
                withAnimation={!isGameFinished}
                useRef={swiperRef}
                itemList={sortedQuestion}
                renderCard={({ id, questionId }, questionCardIndex) => {
                  const currentAnswer = userAnswersList.find(answer => answer.id === id);
                  return (
                    <SingleCard
                      id={id}
                      questionOrderTextId={questionId}
                      oAgreeClick={onAgreeClick}
                      onDeclineClick={onDeclineClick}
                      isResultCard={isGameFinished}
                      disabledPrevBtn={disabledPrevBtn}
                      disabledNextBtn={disabledNextBtn}
                      setQuestionIndex={setQuestionIndex}
                      currentQuestionIndex={questionCardIndex}
                      currentAnswer={isGameFinished ? currentAnswer : null}
                    />
                  );
                }}
                index={questionIndex !== null ? questionIndex : 0}
                swipeConfig={{
                  dragPosition: isGameFinished ? false : 'x',
                  whileDrag: { cursor: 'grabbing' },
                  dragConstraints: { left: 0, right: 0 }
                }}
                offset={{
                  left: !isGameFinished ? -100 : -1,
                  right: !isGameFinished ? 100 : 1
                }}
                onSwipedLeft={({ id, questionId }) => {
                  if (isGameFinished) {
                    moveBack();
                  } else {
                    handleAnswer(id, false, questionId);
                    setQuestionIndex(prevQuestionIndex => prevQuestionIndex + 1);
                  }
                }}
                onSwipedRight={({ id, questionId }) => {
                  if (isGameFinished) {
                    moveForward();
                  } else {
                    handleAnswer(id, true, questionId);
                    setQuestionIndex(prevQuestionIndex => prevQuestionIndex + 1);
                  }
                }}
                withoutDragAndAnimations={isGameFinished}
                withoutDragAndAnimationsSwipeCallback={swipeDirection => {
                  if (swipeDirection === 'Right') {
                    moveBack();
                    return;
                  }
                  if (swipeDirection === 'Left') {
                    moveForward();
                  }
                }}
              />
            )}
            <div className={styles.questionLinkContainer}>
              <div className={styles.questionLinkBottom}>{renderFooterControls}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(GameContainer);

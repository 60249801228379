import React from 'react';
import { useTranslation } from 'react-i18next';
import SchoolLogo from '../SchoolLogo/SchoolLogo';
import { checkAndConvertEmptyString } from '../../utils/utils';
import { InstructorCardProps } from './InstructorCard.types';
import styles from './InstructorCard.module.css';

const InstructorCard: React.FC<InstructorCardProps> = ({ instructor, hidden }) => {
  const { t } = useTranslation();
  if (!instructor) {
    return null;
  }
  return (
    <div className={styles.instructorInfo} hidden={hidden}>
      <div className={styles.instructorContent}>
        <SchoolLogo logoSrc={instructor.photo} alt={instructor.fullName} />
        <div className={styles.instructorSchoolInfo}>
          <span className={styles.userInfo}>{instructor.school}</span>
          <span className={styles.userName}>{instructor.fullName}</span>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.address')}</div>
          <div className={styles.instructorValue}>{instructor.address}</div>
          <div className={styles.instructorValue}>{`${instructor.zip} ${instructor.city}`}</div>
        </div>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.email')}</div>
          <div className={styles.instructorValue}>{instructor.email}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.address')}</div>
          <div className={styles.instructorValue}>{instructor.address}</div>
          <div className={styles.instructorValue}>{`${instructor.zip} ${instructor.city}`}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.telephone')}</div>
          <div className={styles.instructorValue}>{checkAndConvertEmptyString(instructor?.phone)}</div>
        </div>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.mobile')}</div>
          <div className={styles.instructorValue}>{instructor.mobile}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>{t('instructor.email')}</div>
          <div className={styles.instructorValue}>{instructor.email}</div>
        </div>
      </div>
    </div>
  );
};

export default InstructorCard;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../utils/utils';
import { VipCardTCSTypes } from './VipCardTCS.types';
import styles from './VipCardTCS.module.css';

const logo = imageAbsolutePath('logoTCS.png');

const VipCardTCS: React.FC<VipCardTCSTypes> = ({ withLink = true }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.vipCard}>
      <img className={styles.cardLogo} src={logo} alt="logo" />
      <p className={styles.cardTitle}>{t('TCSPage.vipCard.vipCard')}</p>
      <div className={styles.ruleList}>
        <div className={styles.ruleListItem}>
          <i className="icon-car" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-motorcycle" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-tractor" />
        </div>
        <div className={styles.ruleListItem}>
          <i className="icon-e-bike" />
        </div>
      </div>
      {withLink && (
        <a className="linkBtn" rel="noopener noreferrer" target="_blank" href={t('TCSPage.btn.redeemLink')}>
          {t('TCSPage.btn.redeemCode')}
          <i className="icon-arrow" />
        </a>
      )}
    </div>
  );
};

export default VipCardTCS;

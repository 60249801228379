import { all, takeLatest, select, put, call } from 'redux-saga/effects';
import {
  ADD_NEW_GAME_ANSWER_REQUEST,
  DECREMENT_LIVE,
  END_GAME,
  INCREMENT_SCORE,
  SET_LIVES_COUNT,
  START_GAME,
  UPDATE_GAME_ANSWER_LIST
} from '../actions/game.action';
import history from '../router/history';
import {
  NOT_ANSWER,
  CORRECT_ANSWER,
  WRONG_ANSWER,
  GAME_TYPE_LIVES,
  DIFFICULTY_SCORE_OBJ,
  GAME_RESULT_STATUS_VICTORY,
  GAME_RESULT_STATUS_DEFEAT
} from '../constants';
import { IIAnswerRequestSagaType, AnswerObj } from '../reducer/game.types';
import { getQuestionById, soundAbsolutePath } from '../utils/utils';
import { IQuestion } from '../reducer/question.types';
import { IRootState } from '../reducer';
import { selectLanguage } from '../reducer/user.selectors';
import { PLAY_MUSIC } from '../actions/sound.action';
import { saveLivesGameResult } from './game.api';
import { firebaseApp } from '../config/firebase.config';

const wrongSound = soundAbsolutePath('wrong-answer.mp3');
const correctAnswerSound = soundAbsolutePath('correct-answer.mp3');
const badGameResultSound = soundAbsolutePath('bad-game-result.mp3');
const greatGameResult = soundAbsolutePath('great-game-result.mp3');

const sadResultSoundList = [badGameResultSound];

const getAnswerList = ({ game }: IRootState) => game.answerList;
const getDifficultySelector = ({ game }: IRootState) => game.difficulty;
const getGameTypeSelector = ({ game }: IRootState) => game.type;
const getGameLivesCount = ({ game }: IRootState) => game.livesCount;
const getGameStore = ({ game }: IRootState) => game;
const getExtraScore = ({ game }: IRootState) => game.extraScore;
// const getCurrentAnswerIndex = ({ game }: IRootState) => game.currentQuestionIndex;

export const getQuestionAnswerStatus = (question: IQuestion, answer: boolean | null, questionId: number) => {
  if (answer === null) {
    return NOT_ANSWER;
  }
  if (question.correct.includes(questionId)) {
    if (answer) {
      return CORRECT_ANSWER;
    }
  } else if (!answer) {
    return CORRECT_ANSWER;
  }
  return WRONG_ANSWER;
};

function* handleStartGame() {
  yield history.push('game');
  // selectedQuestionObj.clearQuestion();
  // yield put(setStartTime(Date.now()));
  // yield resetAll(GAME_SCREEN);
}

function* handleLivesModeResultSave() {
  const { currentUser } = firebaseApp.auth();
  const { livesMode, score, livesCount, answerList, startTime } = yield select(getGameStore);
  const answers = answerList.reduce((accum: number, current: AnswerObj) => {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    return current.isCorrect && current.isCorrect === CORRECT_ANSWER ? (accum += 1) : accum;
  }, 0);

  const scoreObj = {
    answers,
    livesLeft: livesCount,
    score,
    gameStatus: livesCount > 0 ? GAME_RESULT_STATUS_VICTORY : GAME_RESULT_STATUS_DEFEAT,
    startTime,
    endTime: Date.now()
  };
  yield saveLivesGameResult(currentUser?.uid, livesMode, scoreObj, answerList);
}

function* handleAnswerRequest({ payload: { id, answer, questionAnswerId } }: IIAnswerRequestSagaType) {
  // @ts-ignore
  const question: IQuestion = getQuestionById(id);
  const isCorrect = getQuestionAnswerStatus(question, answer, questionAnswerId);

  const answerList = yield select(getAnswerList);
  const userLanguage = yield select(selectLanguage);
  const answerCopy: AnswerObj[] = [...answerList];
  const type = yield select(getGameTypeSelector);
  const extraScore = yield select(getExtraScore);
  const answerIndex = answerCopy.findIndex(item => item.id === id && item.questionId === questionAnswerId);
  const isLastQuestion = answerIndex === answerList.length - 1;
  const randomElement = sadResultSoundList[Math.floor(Math.random() * sadResultSoundList.length)];

  answerCopy[answerIndex] = { id, answer, isCorrect, questionAnswerId };

  yield put(UPDATE_GAME_ANSWER_LIST({ answerList: answerCopy }));

  if (isCorrect === CORRECT_ANSWER) {
    const difficulty = yield select(getDifficultySelector);
    yield put(INCREMENT_SCORE(DIFFICULTY_SCORE_OBJ[difficulty] + extraScore));
    yield put(PLAY_MUSIC({ url: correctAnswerSound }));
  } else if (type === GAME_TYPE_LIVES) {
    const livesCount = yield select(getGameLivesCount);
    if (livesCount <= 1) {
      yield put(PLAY_MUSIC({ url: randomElement }));
      yield put(SET_LIVES_COUNT(0));
      yield put(END_GAME());
      yield history.push(`/${userLanguage}/game-result`);
      yield call(handleLivesModeResultSave);
      return;
    }
    if (livesCount > 1) {
      yield put(DECREMENT_LIVE());
      yield put(PLAY_MUSIC({ url: wrongSound }));
      return;
    }
  }

  if (isLastQuestion) {
    yield put(END_GAME());
    yield history.push(`/${userLanguage}/game-result`);
    yield call(handleLivesModeResultSave);
    yield put(PLAY_MUSIC({ url: greatGameResult }));
  }
}

function* gameSaga() {
  yield all([takeLatest(START_GAME, handleStartGame), takeLatest(ADD_NEW_GAME_ANSWER_REQUEST, handleAnswerRequest)]);
}

export default gameSaga;

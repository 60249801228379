import React, { Suspense } from 'react';
import { Detector } from 'react-detect-offline';
import { AppRouter } from './router/AppRouter';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import { isSSR } from './utils/utils';
import { GATE } from './constants';

interface IApp {
  SSRRout?: string;
}

const App: React.FC<IApp> = (props: IApp) => {
  const pollingUrl = `${GATE}/online-check`;
  const pollingOptions = {
    enabled: true,
    interval: 30000,
    timeout: 5000,
    url: pollingUrl
  };

  if (isSSR()) {
    return (
      <>
        <ScrollToTopButton />
        <Detector
          polling={pollingOptions}
          render={({ online }) => <AppRouter SSRRout={props.SSRRout} isOnline={online} />}
        />
      </>
    );
  }

  return (
    <Suspense fallback="Loading">
      <ScrollToTopButton />
      <Detector polling={pollingOptions} render={({ online }) => <AppRouter isOnline={online} />} />
    </Suspense>
  );
};

export default App;

import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const SeoLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <Col>
      <LinkWrapper withoutlanguageprefix="true" to="/autopruefung-theorie">
        {t('seolinks.link1')}
      </LinkWrapper>
      {' | '}
      <LinkWrapper withoutlanguageprefix="true" to="/mofa-pruefung">
        {t('seolinks.link2')}
      </LinkWrapper>
      {' | '}
      <LinkWrapper withoutlanguageprefix="true" to="/rollerpruefung">
        {t('seolinks.link3')}
      </LinkWrapper>
      {' | '}
      <LinkWrapper withoutlanguageprefix="true" to="/theoriepruefung-lernen">
        {t('seolinks.link4')}
      </LinkWrapper>
    </Col>
  );
};

export default SeoLinks;

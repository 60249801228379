import { useSelector } from 'react-redux';
import get from 'lodash.get';
import { PENDING, SUCCESS, UNCALLED } from '../constants/store.constants';

export const useGetDataLoadingStatusStore = storePathToStatus => {
  // @ts-ignore
  const selectedStatus = useSelector(state => get(state, storePathToStatus));
  return {
    isFetching: selectedStatus === PENDING || selectedStatus === UNCALLED,
    isLoaded: selectedStatus === SUCCESS
  };
};

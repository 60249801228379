import React, { FC } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import HomeCardsTCS from '../../components/HomeCardsTCS/HomeCardsTCS';
import Ads from '../../components/Ads/Ads';
import PromoContentContainerTCS from '../../containers/PromoContentContainerTCS';
import VipContentTCS from '../../components/VipContentTCS/VipContentTCS';
import SocialLinkList from '../../components/SocialLinkList/SocialLinkList';
import HomeCarouselMobileTCS from '../../components/HomeCarouselMobileTCS/HomeCarouselMobileTCS';
import styles from './TCSPage.module.css';

const TCSPage: FC = () => {
  return (
    <div>
      <div className={styles.sectionPromo}>
        <Container>
          <Row>
            <Col>
              <PromoContentContainerTCS />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileCarousel}>
        <Container>
          <Row>
            <Col>
              <HomeCarouselMobileTCS />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.sectionAdsMobile}>
        <Ads idx={1} />
      </div>
      <div className={styles.sectionVip}>
        <Container>
          <VipContentTCS />
        </Container>
      </div>
      <div className={styles.sectionCard}>
        <Container>
          <HomeCardsTCS />
        </Container>
      </div>
      {/* <div className={styles.sectionOnlineShop}> */}
      {/*  <Container> */}
      {/*    <HomePageShop /> */}
      {/*  </Container> */}
      {/* </div> */}
      <div className={styles.sectionSocialMobile}>
        <Container>
          <Row>
            <Col>
              <SocialLinkList />
            </Col>
          </Row>
        </Container>
      </div>
      <Ads />
    </div>
  );
};

export default TCSPage;

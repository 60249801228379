export const [PLAYING, STOPPED, PAUSED] = ['PLAYING', 'STOPPED', 'PAUSED'];
export type PlayStatusTypes = typeof PLAYING | typeof STOPPED | typeof PAUSED;

export interface ISoundState {
  url: string;
  playStatus: PlayStatusTypes;
  volume: number;
  uniqId: null | string;
  isSoundAvailableForThisDevice: boolean;
}

export interface IPlayMusicAction {
  payload: {
    url?: string;
  };
}

export interface IUpdateMusicAction {
  payload?: ISoundState;
}

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './CockpitCategories.module.css';
import { ICockpitCategoriesProps } from './CockpitCategories.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const CockpitCategories: React.FC<ICockpitCategoriesProps> = ({ isLogged }: ICockpitCategoriesProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.cardCategoriesContainer}>
      <Container>
        <Row>
          <Col>
            <div className={styles.cardCategoriesList}>
              <Card className={styles.cardCategoriesItem} border="light">
                <Card.Body>
                  <Card.Title className="h2">
                    {t(isLogged ? 'myCockpitCategories.title.ask' : 'CockpitNotLoggedTopContainer.demoquestions')}
                  </Card.Title>
                  <Card.Text>
                    {t(isLogged ? 'myCockpitCategories.subtitle.ask' : 'myCockpitCategories.subtitleDemo.ask')}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkWrapper className="linkBtn" to="/questions">
                    {t('myCockpitCategories.weiter')}
                    <i className="icon-arrow" />
                  </LinkWrapper>
                </Card.Footer>
              </Card>
              <Card className={styles.cardCategoriesItem} border="light">
                <Card.Body>
                  <Card.Title className="h2">
                    {t(isLogged ? 'myCockpitCategories.title.exam' : 'CockpitNotLoggedTopContainer.demoexam')}
                  </Card.Title>
                  <Card.Text>
                    {t(isLogged ? 'myCockpitCategories.subtitle.exam' : 'myCockpitCategories.subtitleDemo.exam')}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkWrapper className="linkBtn" to="/exam">
                    {t('myCockpitCategories.weiter')}
                    <i className="icon-arrow" />
                  </LinkWrapper>
                </Card.Footer>
              </Card>
              <Card className={styles.cardCategoriesItem} border="light">
                <Card.Body>
                  <Card.Title className="h2">{t('myCockpitCategories.title.theory')}</Card.Title>
                  <Card.Text>
                    {t(isLogged ? 'myCockpitCategories.subtitle.theory' : 'myCockpitCategories.subtitleDemo.theory')}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkWrapper className="linkBtn" to="/theory">
                    {t('myCockpitCategories.weiter')}
                    <i className="icon-arrow" />
                  </LinkWrapper>
                </Card.Footer>
              </Card>
              {isLogged && (
                <Card className={styles.cardCategoriesItem} border="light">
                  {/*<div className={styles.cardBadge}>*/}
                  {/*  <span className={styles.badgeText}>new</span>*/}
                  {/*</div>*/}
                  <Card.Body>
                    <Card.Title className="h2">{t('myCockpitCategories.title.gameMode')}</Card.Title>
                    <Card.Text>{t('myCockpitCategories.subtitle.gameMode')}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <LinkWrapper className="linkBtn" to="/game-mode">
                      {t('myCockpitCategories.weiter')}
                      <i className="icon-arrow" />
                    </LinkWrapper>
                  </Card.Footer>
                </Card>
              )}
              <Card className={styles.cardCategoriesItem} border="light">
                <Card.Body>
                  <Card.Title className="h2">{t('myCockpitCategories.title.3Danimations')}</Card.Title>
                  <Card.Text>
                    {t(
                      isLogged
                        ? 'myCockpitCategories.subtitle.3Danimations'
                        : 'myCockpitCategories.subtitleDemo.3Danimations'
                    )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkWrapper className="linkBtn" to="/3dteacher">
                    {t('myCockpitCategories.weiter')}
                    <i className="icon-arrow" />
                  </LinkWrapper>
                </Card.Footer>
              </Card>
              <Card className={styles.cardCategoriesItem} border="light">
                <Card.Body>
                  <Card.Title className="h2">{t('myCockpitCategories.title.ebike')}</Card.Title>
                  <Card.Text>
                    {t(isLogged ? 'myCockpitCategories.subtitle.ebike' : 'myCockpitCategories.subtitleDemo.ebike')}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkWrapper className="linkBtn" to="/ebike">
                    {t('myCockpitCategories.weiter')}
                    <i className="icon-arrow" />
                  </LinkWrapper>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitCategories;

import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Link, LinkProps } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import useReactRouter from 'use-react-router';
import { selectLanguage } from '../../reducer/user.selectors';
import { scrollToAnchor } from '../../utils/utils';
import { ILinkWrapperProps } from './LinkWrapper.types';

export const LinkWrapper: React.FC<LinkProps & ILinkWrapperProps> = React.memo(
  React.forwardRef((props, ref?: any) => {
    const { location } = useReactRouter();
    const language = useSelector(selectLanguage);

    // add string for resolving SSR warning (warning if set boolean)
    /* eslint-disable-next-line */
    const linkUrl = props.withoutlanguageprefix
      ? JSON.parse(props.withoutlanguageprefix)
        ? `${props.to}`
        : `/${language}${props.to}`
      : `/${language}${props.to}`;

    const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
      if (typeof props.onClick === 'function') {
        props.onClick(event);
      } else {
        event.preventDefault();
      }
      if (typeof props.to === 'string') {
        scrollToAnchor(props.to);
      }
    };

    if (props.toanchor) {
      return <HashLink ref={ref} {...props} to={`${linkUrl}#${props.toanchor}`} />;
    }

    if (typeof props.to === 'string' && props.to.startsWith('#')) {
      return <Link ref={ref} {...props} to={{ ...location, hash: props.to }} onClick={handleOnClick} />;
    }

    return <Link ref={ref} {...props} to={linkUrl} />;
  })
);

export const MLinkWrapper = motion(LinkWrapper);

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import Confetti from 'react-confetti';
import { ConfettiContainerTypes } from './ConfettiContainer.types';
import useDeviceSizes from '../../hooks/useDeviceSizes';

const ConfettiContainer: React.FC<ConfettiContainerTypes> = ({ showConfettiByDefault = false }) => {
  const { width, height } = useDeviceSizes();

  const [visibility, setVisibility] = useState(showConfettiByDefault);

  useEffect(() => {
    if (visibility) {
      const timer = setTimeout(() => {
        setVisibility(false);
      }, 18000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [setVisibility, visibility]);

  if (visibility) {
    return <Confetti width={width} height={height} />;
  }
  return null;
};

export default React.memo(ConfettiContainer);

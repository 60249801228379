import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../reducer';
import CockpitCategories from '../components/CockpitCategories/CockpitCategories';
import CockpitCategoriesAccordion from '../components/CockpitCategoriesAccordion/CockpitCategoriesAccordion';

const CockpitCategoriesContainer: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  return (
    <>
      <CockpitCategories isLogged={isLogged} />
      <CockpitCategoriesAccordion isLogged={isLogged} />
    </>
  );
};

export default CockpitCategoriesContainer;

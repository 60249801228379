import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import TickListItem from '../../../../ui-components/TickListItem/TickListItem';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import { getTranslationList, imageAbsolutePath } from '../../../../utils/utils';
import { selectLanguage } from '../../../../reducer/user.selectors';
import styles from './KeyBenefitsSection.module.css';

const examQuestionBannerDe: string = imageAbsolutePath('banners/years-logo_de.png');
const examQuestionBannerEn: string = imageAbsolutePath('banners/years-logo_en.png');
const examQuestionBannerFr: string = imageAbsolutePath('banners/years-logo_fr.png');
const examQuestionBannerIt: string = imageAbsolutePath('banners/years-logo_it.png');

const EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST = {
  de: { img: examQuestionBannerDe },
  fr: { img: examQuestionBannerFr },
  it: { img: examQuestionBannerIt },
  en: { img: examQuestionBannerEn }
};

const KeyBenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  const currentLanguage = useSelector(selectLanguage);

  /** create list from translation * */
  const callToActionList: { text: string; description?: string }[] = useMemo(() => {
    const translationObj = getTranslationList(currentLanguage, 'offersPageNew.keyBenefitsSection.list');
    const translationList: { text: string; description: string }[] = Object.values(translationObj);

    return Object.values(translationList).map(({ text, description }) => ({
      text,
      description
    }));
  }, [currentLanguage]);

  const renderItemList = useMemo(
    () =>
      callToActionList.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TickListItem key={index} text={item.text} description={item.description} />
      )),
    [callToActionList]
  );

  return (
    <section className={styles.container}>
      <TitleH3 center text={t('offersPageNew.keyBenefitsSection.title')} />

      <ul className={styles.listWrapper}>{renderItemList}</ul>

      <LocalesImage
        alt={t('homePageNew.titleSection.images.logoAlt')}
        localesPath={EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST}
      />
    </section>
  );
};

export default KeyBenefitsSection;

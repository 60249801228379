import { useRef, useEffect, useState } from 'react';

const useStateWithCallbackLazy = (initialValue, withUpdate = false) => {
  const callbackRef = useRef(null);

  const [value, setValue] = useState(initialValue);
  const [updateAllTime, setUpdateAllTime] = useState(0);

  useEffect(() => {
    if (callbackRef.current) {
      // @ts-ignore
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value, updateAllTime]);

  const setValueWithCallback = (newValue, callback) => {
    callbackRef.current = callback;
    if (withUpdate) {
      setUpdateAllTime(prevUpdateAllTime => prevUpdateAllTime + 1);
    }

    return setValue(newValue);
  };

  return [value, setValueWithCallback];
};

export { useStateWithCallbackLazy };

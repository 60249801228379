import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Image from '../../ui-components/Image/Image';
import { P3 } from '../../ui-components/P3/P3';
import { selectLanguage } from '../../reducer/user.selectors';
import styles from './EbikePage.module.css';

const getImgByLocale = locale => {
  const prefix = '/content/ebike/';

  switch (locale) {
    case 'de':
    case 'fr':
    case 'it':
    case 'en':
      return `${prefix}right_${locale}.jpg`;

    default:
      return `${prefix}right_de.jpg`;
  }
};

const EbikeRightPage: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);

  return (
    <div className={styles.ebikeTextList}>
      <h4 className={styles.ebikeTextTitle}>{t('ebikeRight.title')}</h4>
      <P3>{t('ebikeRight.subtitle')}</P3>
      <div className={styles.ebikeImgBox}>
        <Image src={getImgByLocale(currentLanguage)} />
      </div>
    </div>
  );
};

export default EbikeRightPage;

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { defaultTopicProgress } from '../../../../components/TopicProgress/TopicProgress';
import QuestionLinkList from '../../../QustionLinkList/QustionLinkList';
import { GET_QUESTION_REQUEST } from '../../../../actions/question.action';
import { TopicItemIconsObj } from './TopicItemIcons';
import { CLEAR_ANSWER_BY_TOPIC_REQUEST } from '../../../../actions/answer.action';
import { ITopicItemProps } from './TopicItem.types';
import styles from './TopicItem.module.css';

/** Icon component only for TopicItem * */
const Icon = ({ topicId }: { topicId: number | string }) =>
  TopicItemIconsObj[topicId]
    ? TopicItemIconsObj[topicId]({
        size: 30,
        color: 'rgba(0, 128, 201, 0.8)'
      })
    : 'icon';

const spring = {
  stiffness: 400,
  damping: 30,
  layout: { duration: 0.2 }
};

const text = {
  type: 'tween',
  layout: { duration: 0.1 }
};

const TopicItem: React.FC<ITopicItemProps> = ({
  topic,
  userAnswers,
  setSelectedTopicId,
  selectedTopicId,
  onQuestionChange,
  currentLanguage,
  currentCategory
}: ITopicItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const topicStatus = defaultTopicProgress(topic, userAnswers);

  const fetchQuestionList = () => {
    if (topic.id !== selectedTopicId) {
      // @ts-ignore
      setSelectedTopicId(topic.id);
      dispatch(GET_QUESTION_REQUEST({ topic: topic.id }));
    } else {
      // @ts-ignore
      setSelectedTopicId(null);
    }
  };

  useEffect(() => {
    /** update topics if user switch category* */
    if (currentCategory) {
      fetchQuestionList();
    }
    // eslint-disable-next-line
  }, [currentCategory]);

  const defaultRoute = `/${currentLanguage}/questions/${currentCategory}/${topic.id}`;

  const handleAnswerClear = useCallback(() => {
    dispatch(CLEAR_ANSWER_BY_TOPIC_REQUEST({ topic: topic.id }));
  }, [dispatch, topic]);

  const handleLearnTheory = useCallback(() => {
    onQuestionChange(defaultRoute);
  }, [defaultRoute, onQuestionChange]);

  const isSelected = topic.id === selectedTopicId;

  const containerClass = classNames(styles.container, { [styles.isSelected]: isSelected });
  const collapsedClass = classNames(styles.body, { [styles.isOpen]: isSelected });

  return (
    <motion.div transition={spring} layout className={containerClass}>
      <motion.div transition={spring} layout className={styles.toggleButton} onClick={fetchQuestionList}>
        <div className={styles.header}>
          <motion.div layout className={styles.headerIcon}>
            <Icon topicId={topic.id} />
          </motion.div>

          <motion.span transition={text} layout="position" className={styles.headerText}>
            {topic.title}
          </motion.span>

          <div className={styles.headerPercent}>
            <div
              className={classNames(styles.percentWrapper, {
                [styles.empty]: topicStatus.status === -1,
                [styles.bad]: topicStatus.status >= 0.0 && topicStatus.status < 0.4,
                [styles.middle]: topicStatus.status >= 0.4 && topicStatus.status < 1.0,
                [styles.good]: topicStatus.status === 1.0
              })}
            >
              <ProgressBar color="red" now={topicStatus.progress} />
              <motion.p layout>{`${topicStatus.progress.toFixed(0)} %`}</motion.p>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div className={collapsedClass}>
        <>
          <QuestionLinkList
            defaultRoute={defaultRoute}
            onQuestionChange={onQuestionChange}
            finishedExam={undefined}
            isExam={undefined}
            questionList={undefined}
          />
          <div className={styles.footer}>
            <Button variant="secondary" onClick={handleAnswerClear}>
              {t('questionModal.btn')}
            </Button>
            <Button variant="outline-secondary btnLabel" onClick={handleLearnTheory}>
              {t('questionsByTopicContainer.learnTheory')}
            </Button>
          </div>
        </>
      </motion.div>
    </motion.div>
  );
};

export default TopicItem;

import translationEn from './en/translation.json';
import translationDe from './de/translation.json';
import translationFr from './fr/translation.json';
import translationIt from './it/translation.json';
import { ILngList, ILocalesList } from './locale.types';

const de = '/static/images/lang/Germany.svg';
const fr = '/static/images/lang/France.svg';
const it = '/static/images/lang/Italy.svg';
const en = '/static/images/lang/UK.svg';

export const defaultLanguage = 'de';

export const lngList: ILngList = {
  de: {
    included: true,
    fullName: 'Deutsch',
    shortName: 'de',
    icon: de
  },
  fr: {
    included: true,
    fullName: 'Français',
    shortName: 'fr',
    icon: fr
  },
  it: {
    included: true,
    fullName: 'Italiano',
    shortName: 'it',
    icon: it
  },
  en: {
    included: true,
    fullName: 'English',
    shortName: 'en',
    icon: en
  }
};

const lngListTCS = { ...lngList };
delete lngListTCS.en;

const locale: ILocalesList = {
  en: {
    translation: translationEn
  },
  de: {
    translation: translationDe
  },
  fr: {
    translation: translationFr
  },
  it: {
    translation: translationIt
  }
};

Object.keys(locale).forEach((lng: string) => {
  if (lngList[lng] && !lngList[lng].included) {
    delete locale[lng];
  }
});

export { lngListTCS };

export default locale;

import React, { FC, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { IHomeCardProps } from './HomeCard.types';
import styles from './HomeCard.module.css';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';

export const HomeCard: FC<IHomeCardProps> = forwardRef(
  ({ title, text, link, linkUrl }: IHomeCardProps, ref?: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className={styles.cardItem}>
        <div className={styles.cardContent}>
          <h6 className={styles.cardContentTitle}>{title}</h6>
          <p className={styles.cardContentText}>{text}</p>
        </div>
        {linkUrl && (
          <div className={styles.cardFooter}>
            <LinkWrapper className="linkBtn" to={linkUrl}>
              {link}
              <i className="icon-arrow" />
            </LinkWrapper>
          </div>
        )}
      </div>
    );
  }
);

export const MHomeCard = motion(HomeCard);

export default HomeCard;

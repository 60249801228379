import { takeEvery, select, put, all } from 'redux-saga/effects';
import { firebaseApp } from '../config/firebase.config';
import { IRootState } from '../reducer';
import {
  ADD_ANSWER_FAILURE,
  ADD_ANSWER_REQUEST,
  ADD_ANSWER_SUCCESS,
  CLEAR_ANSWER_BY_TOPIC_FAILURE,
  CLEAR_ANSWER_BY_TOPIC_SUCCESS,
  CLEAR_CURRENT_ANSWER_REQUEST,
  GET_ANSWER_FROM_FIREBASE_FAILURE,
  GET_ANSWER_FROM_FIREBASE_SUCCESS,
  CLEAR_MARKED_ANSWER_LIST_REQUEST,
  GET_ANSWER_FROM_FIREBASE_REQUEST,
  CLEAR_MARKED_ANSWER_LIST_SUCCESS,
  CLEAR_MARKED_ANSWER_LIST_FAILURE,
  SET_ANSWER_LIST_CASH,
  GET_ANSWER_LIST_CASH_REQUEST,
  CLEAR_CURRENT_ANSWER_SUCCESS,
  CLEAR_CURRENT_ANSWER_FAILURE
} from '../actions/answer.action';
import { GET_QUESTION_REQUEST } from '../actions/question.action';
import {
  getAnswerList,
  saveAnswerByTopic,
  clearAnswerByTopic,
  clearAnswersFromMarkedQuestions,
  clearAnswerByAnswerId
} from './answer.api';
import { IAnswerPayload } from '../containers/QuestionCardContainer/QuestionCardContainer.types';
import {
  addAnswerToAnswerList,
  checkIfThisQuestionExistInQuestionJSON,
  getFBCategoryName,
  getMarkedCategory
} from '../utils/utils';

const getCategory = ({ user }: IRootState) => user.profile.currentCategory;

function* setAnswerToFirebase(action) {
  if (!action.payload?.isIncorrectTopic) {
    try {
      const currentCategory = yield select(getCategory);
      const categoryForQuery = getFBCategoryName(currentCategory);
      const { currentUser } = firebaseApp.auth();
      const userId = currentUser ? currentUser.uid : '';
      yield saveAnswerByTopic({
        userId,
        categoryForQuery,
        action
      });
      yield put(ADD_ANSWER_SUCCESS());
    } catch (e) {
      yield put(ADD_ANSWER_FAILURE(e.message));
    }
  }
}

function* clearUserAnswersByTopic(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    yield clearAnswerByTopic(currentUser ? currentUser.uid : '', categoryForQuery, action.payload.topic.toString());

    yield put(CLEAR_ANSWER_BY_TOPIC_SUCCESS());
  } catch (e) {
    yield put(CLEAR_ANSWER_BY_TOPIC_FAILURE(e.message));
  }
}

function* clearMarkedQuestionAnswers({ payload: { markedQuestions } }) {
  const { currentUser } = firebaseApp.auth();
  const currentCategory = yield select(getCategory);
  const categoryMarked = getMarkedCategory(currentCategory);
  const categoryForQuery = getFBCategoryName(currentCategory);
  try {
    if (markedQuestions && markedQuestions[categoryMarked].length !== 0) {
      yield clearAnswersFromMarkedQuestions(
        currentUser ? currentUser.uid : '',
        categoryForQuery,
        markedQuestions[categoryMarked]
      );
    }
    yield put(CLEAR_MARKED_ANSWER_LIST_SUCCESS());
    // for updating current store (clear previous answers)
    yield put(GET_QUESTION_REQUEST({ topic: 'marked' }));
    yield put(GET_ANSWER_FROM_FIREBASE_REQUEST({ topic: 'marked' }));
  } catch (e) {
    yield put(CLEAR_MARKED_ANSWER_LIST_FAILURE(e.message));
  }
}

function* getUserAnswersFromFirebase() {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    const answers = yield getAnswerList(currentUser ? currentUser.uid : '', categoryForQuery);

    /** remove answer for question which not exists* */
    const filterAnswers = {};
    // eslint-disable-next-line
    for (const key in answers) {
      const filterInside = {};
      Object.keys(answers[key]).forEach(answerId => {
        if (checkIfThisQuestionExistInQuestionJSON(+answerId)) {
          filterInside[answerId] = answers[key][answerId];
        }
      });
      filterAnswers[key] = filterInside;
    }
    yield put(GET_ANSWER_FROM_FIREBASE_SUCCESS({ answers: filterAnswers }));
  } catch (e) {
    yield put(GET_ANSWER_FROM_FIREBASE_FAILURE(e.message));
  }
}

function* getIncorrectAnswerFormFirebase() {
  try {
    const { currentUser } = firebaseApp.auth();
    if (currentUser) {
      const currentCategory = yield select(getCategory);
      const categoryForQuery = getFBCategoryName(currentCategory);
      const answers = yield getAnswerList(currentUser ? currentUser.uid : '', categoryForQuery);
      yield put(SET_ANSWER_LIST_CASH({ answers }));
    }
  } catch (e) {
    yield put(GET_ANSWER_FROM_FIREBASE_FAILURE(e.message));
  }
}

function* onAnswerChange(action) {
  try {
    const { answerId, question, userAnswer, topic } = action.payload;
    const answerPayload: IAnswerPayload = {
      questionId: question.id,
      qTopic: question.themeid
    };

    if (!userAnswer) {
      answerPayload.answer = {
        selected: [answerId]
      };
    } else {
      const selectedAnswers: number[] = addAnswerToAnswerList(userAnswer, answerId);
      answerPayload.answer = {
        selected: selectedAnswers
      };
    }

    answerPayload.isIncorrectTopic = topic === 'incorrect';
    yield put(ADD_ANSWER_REQUEST(answerPayload));
  } catch (e) {
    console.log(e.message);
  }
}

function* clearCurrentAnswer({ payload: { question, isIncorrectTopic } }) {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    if (!isIncorrectTopic) {
      yield clearAnswerByAnswerId(currentUser ? currentUser.uid : '', categoryForQuery, question.id);
    }
    yield put(CLEAR_CURRENT_ANSWER_SUCCESS({ themeId: question.themeid, questionId: question.id }));
  } catch (e) {
    yield put(CLEAR_CURRENT_ANSWER_FAILURE(e.message));
  }
}

function* answerSaga() {
  yield all([
    takeEvery('CHANGE_ANSWER', onAnswerChange),
    takeEvery('ADD_ANSWER_REQUEST', setAnswerToFirebase),
    takeEvery('CLEAR_ANSWER_BY_TOPIC_REQUEST', clearUserAnswersByTopic),
    takeEvery(CLEAR_CURRENT_ANSWER_REQUEST, clearCurrentAnswer),
    takeEvery('GET_ANSWER_FROM_FIREBASE_REQUEST', getUserAnswersFromFirebase),
    takeEvery(CLEAR_MARKED_ANSWER_LIST_REQUEST, clearMarkedQuestionAnswers),
    takeEvery(GET_ANSWER_LIST_CASH_REQUEST, getIncorrectAnswerFormFirebase)
  ]);
}

export default answerSaga;

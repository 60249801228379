import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { ITickListItemProps } from './TickListItem.types';

const TickListItem: React.FC<ITickListItemProps> = forwardRef(
  ({ text, description, withDote = false }: ITickListItemProps, ref?: React.Ref<HTMLLIElement>) => {
    const icon = withDote ? faCircle : faCheck;
    return (
      <li ref={ref}>
        <div>
          <FontAwesomeIcon icon={icon} size="lg" />
          {text}
        </div>
        {description && <span>{description}</span>}
      </li>
    );
  }
);

export const MTickListItem = motion(TickListItem);

export default TickListItem;

import React from 'react';
import { useSelector } from 'react-redux';
import { IEbikeContainerProps } from './EbikeContainer.types';
import { selectLanguage } from '../../reducer/user.selectors';
import EbikePage from '../../pages/EbikePage/EbikePage';

const EbikeContainer: React.FC<IEbikeContainerProps> = ({ match }: IEbikeContainerProps) => {
  const currentLanguage = useSelector(selectLanguage);

  return <EbikePage match={match} currentLanguage={currentLanguage} />;
};

export default EbikeContainer;

import React, { useEffect } from 'react';
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { imageAbsolutePath, switchColorTheme, TCSLinkGenerator } from '../../utils/utils';
import { lngListTCS } from '../../locales/locale';
import Translate from '../Translate/Translate';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import { IRootState } from '../../reducer';
import { GAME_PLAY_STATUS } from '../../constants';
import { IHeaderTCSProps } from './HeaderTCS.types';
import styles from './HeaderTCS.module.css';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import { ThemeColors } from '../../reducer/theme.types';
import { selectLanguage } from '../../reducer/user.selectors';

const logo = imageAbsolutePath('logo.png');

const headerVariants = {
  show: { opacity: 1, y: 0, height: 'auto' },
  hide: {
    y: -100,
    opacity: 0,
    height: 0,
    transitionEnd: {
      display: 'none'
    }
  }
};
// const logo = imageAbsolutePath('logo.png');

const HeaderTCS: React.FC<IHeaderTCSProps> = ({ routeProps }: IHeaderTCSProps) => {
  const { setThemColor, selectedColorTheme } = useGetSelectedColorTheme();

  const isGameStatusPlay = useSelector(({ game }: IRootState) => game.status) === GAME_PLAY_STATUS ? 'hide' : 'show';
  const currentLanguage = useSelector(selectLanguage);

  const logoSrc = logo;

  const navbarClass = 'light';

  useEffect(() => {
    setThemColor(ThemeColors.light);
  }, [setThemColor]);

  useEffect(() => {
    switchColorTheme(selectedColorTheme);
  }, [selectedColorTheme]);

  return (
    <motion.div
      animate={isGameStatusPlay}
      transition={{ duration: 1 }}
      variants={headerVariants}
      className={styles.header}
    >
      <div className={styles.headerTop}>
        <Container>
          <Row>
            <Col>
              <div className={styles.headerTopContent}>
                <ul className={styles.headerTopLinkList}>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/tcs.schweiz/"
                    >
                      <i className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/touringclubsuisse/"
                    >
                      <i className="icon-instagram" />
                    </a>
                  </li>
                </ul>
                <div className={styles.langBtnGroup}>
                  {Object.keys(lngListTCS).map((lng: string) => {
                    return (
                      <Translate key={lng} lng={lngListTCS[lng].shortName} routeProps={routeProps}>
                        {lngListTCS[lng].fullName}
                      </Translate>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar className={navbarClass} expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand>
            <Nav>
              <a rel="noopener noreferrer" href={TCSLinkGenerator('mycockpit', currentLanguage)}>
                <img src={logoSrc} width={100} alt="logo" />
              </a>
            </Nav>
          </Navbar.Brand>
          <div className={styles.mobileButtons}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>
          <>
            <Navbar.Collapse id="responsive-navbar-nav">
              <HeaderMenuContainer />
            </Navbar.Collapse>
          </>
        </Container>
      </Navbar>
    </motion.div>
  );
};

export default HeaderTCS;

import React, { useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { IImageZoomContainerProps } from './ImageZoomContainer.types';
import './ImageZoomContainer.module.css';

export const ImageZoomContainer: React.FC<IImageZoomContainerProps> = ({ children }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  const childrenWithProps: any = () => {
    if (React.isValidElement(children)) {
      const ref = { ref: imgRef };
      return React.cloneElement(children, { ...ref });
    }
    return children;
  };

  return <QuickPinchZoom onUpdate={onUpdate}>{childrenWithProps()}</QuickPinchZoom>;
};

import React from 'react';
import CircleLight from './components/CircleLight/CircleLight';
import { ICommonTrafficLightComponentProps, ICommonTrafficLightProps } from './TrafficLight.types';
import styles from './TrafficLight.module.css';

const TrafficLight: React.FC<ICommonTrafficLightProps> & ICommonTrafficLightComponentProps = ({
  containerStyle = styles.trafficLightContainer,
  children
}: ICommonTrafficLightProps) => {
  return <div className={containerStyle}>{children}</div>;
};

TrafficLight.CircleLight = CircleLight;

export { TrafficLight };

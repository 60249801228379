export const TOKEN = 'token';
export const FB_TOKEN = 'firebaseToken';
export const DEVICE_UID = 'deviceUid';
export const TEACHER_ID = 'app/registration/teacherId';
export const SS_THEORY_GLOSSARY_SCROLL_POSITION_KEY = 'TheoryGlossaryContainer/scrollPosition';
export const SELECTED_OFFER_KEY = 'selectedOffer';
export const REGISTRATION_FORM_KEY = 'registrationForm';
export const LANGUAGE_KEY = 'language';
export const USER_EMAIL = 'userEmail';
export const USER_PASSWORD = 'userPassword';
export const LAST_OPEN_EXAM_ID = 'lastOpenExamUrl';
export const DEMO_CATEGORY = 'demoCategory';
export const THEME_COLOR = 'themeColor';
export const QUESTION_PAGE_VIEW_TYPE = 'questionPageViewType';

import React from 'react';
import { ImageIconProps } from './ImageIcon.types';

const ImageIcon: React.FC<ImageIconProps> = ({ src, alt, width, height }: ImageIconProps) => {
  return (
    <div>
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
};

export default ImageIcon;

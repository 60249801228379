import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { LoaderWrapper } from '../../../ui-components/LoaderWrapper/LoaderWrapper';
import { ReactComponent as BackButton } from '../../../img/game/back-arrow.svg';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';
import { IGameScoreTableProps } from './GameScoreTable.types';
import { GAME_RESULT_STATUS_VICTORY } from '../../../constants';
import {
  CLEAR_GAME_SCORE_HISTORY_REQUEST,
  SHOW_SINGLE_GAME_HISTORY,
  UPDATE_HISTORY_ANSWER_LIST
} from '../../../actions/gameScore.action';
import QuestionChooseModalGameContainer from '../QuestionChooseModalGameContainer/QuestionChooseModalGameContainer';
import { selectLanguage } from '../../../reducer/user.selectors';
import { IRootState } from '../../../reducer';
import { UPDATE_QUESTION_INDEX } from '../../../actions/game.action';
import styles from './GameScoreTable.module.css';

const GameScoreTable: React.FC<IGameScoreTableProps> = ({ isLoading, tableData }: IGameScoreTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showQuestionWin, setShowQuestionWin] = useState(false);

  const currentLanguage = useSelector(selectLanguage);
  const selectedScoreType = useSelector(({ gameScore }: IRootState) => gameScore.livesMode);
  const { historyAnswerList } = useSelector(({ gameScore }: IRootState) => gameScore);

  const onShowQuestionWin = useCallback(() => {
    setShowQuestionWin(true);
  }, [setShowQuestionWin]);

  const onHideQuestionWin = useCallback(() => {
    setShowQuestionWin(false);
  }, [setShowQuestionWin]);

  const onRowClick = useCallback(
    answerList => () => {
      dispatch(UPDATE_HISTORY_ANSWER_LIST(answerList));
      onShowQuestionWin();
    },
    [dispatch, onShowQuestionWin]
  );

  const oClearGameHistoryClick = useCallback(() => {
    dispatch(CLEAR_GAME_SCORE_HISTORY_REQUEST(selectedScoreType));
  }, [dispatch, selectedScoreType]);

  const handleQuestionChange = useCallback(
    (index: number) => () => {
      dispatch(UPDATE_QUESTION_INDEX(index));
      dispatch(SHOW_SINGLE_GAME_HISTORY(historyAnswerList));
    },
    [historyAnswerList, dispatch]
  );

  const renderBackButton = useMemo(
    () => (
      <LinkWrapper className={`btn btn-secondary ${styles.backButton}`} to="/game-mode">
        <BackButton />
        {t('gameScorePage.back')}
      </LinkWrapper>
    ),
    [t]
  );

  const renderResetProgressButton = useMemo(
    () => (
      <div className={styles.backButtonWrapper}>
        <Button variant="secondary" onClick={oClearGameHistoryClick}>
          {t('gameScorePage.clearAll')}
        </Button>
      </div>
    ),
    [t, oClearGameHistoryClick]
  );

  const renderTableMap = useMemo(() => {
    if (tableData) {
      return (
        <>
          {(tableData as any[])!.map(({ answers, gameStatus, score, livesLeft, endTime, answerList }, index) => {
            const gameStatusText =
              gameStatus === GAME_RESULT_STATUS_VICTORY ? t('gameScorePage.victory') : t('gameScorePage.defeat');
            return (
              <tr onClick={onRowClick(answerList)} key={endTime}>
                <td>{index + 1}</td>
                <td>{score}</td>
                <td>{livesLeft}</td>
                <td>
                  {answers}
                  /25
                </td>
                <td>{gameStatusText}</td>
                <td>{moment(endTime).format('DD.MM.YY HH:mm')}</td>
              </tr>
            );
          })}
        </>
      );
    }
    return null;
  }, [tableData, t, onRowClick]);

  const renderEmptyTable = useMemo(() => {
    if (tableData?.length === 0) {
      return (
        <div className={styles.noResultContainer}>
          <p>{t('gameScorePage.doNotHaveSavedResult')}</p>
          <p>{t('gameScorePage.shouldPlay')}</p>
          {renderBackButton}
        </div>
      );
    }
    return null;
  }, [tableData, renderBackButton, t]);

  return (
    <LoaderWrapper show={isLoading}>
      {tableData?.length !== 0 ? (
        <>
          <Table className={styles.tableContainer} responsive striped borderless hover>
            <thead>
              <tr>
                <th>#</th>
                <th>{t('gameScorePage.table.score')}</th>
                <th>{t('gameScorePage.table.livesLeft')}</th>
                <th>{t('gameScorePage.table.answers')}</th>
                <th>{t('gameScorePage.table.gameStatus')}</th>
                <th>{t('gameScorePage.table.date')}</th>
              </tr>
            </thead>
            <tbody>{renderTableMap}</tbody>
          </Table>
          <div className={styles.noResultContainer}>
            {renderResetProgressButton}
            {renderBackButton}
          </div>
        </>
      ) : (
        renderEmptyTable
      )}

      <QuestionChooseModalGameContainer
        defaultRoute={`/${currentLanguage}/game`}
        onQuestionChange={handleQuestionChange}
        answerList={historyAnswerList}
        show={showQuestionWin}
        onHide={onHideQuestionWin}
      />
    </LoaderWrapper>
  );
};

export default React.memo(GameScoreTable);

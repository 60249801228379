import React, { useRef, useState, useCallback } from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { isSSR } from '../../utils/utils';
import { ISuccessSignUpPopupProps } from './SuccessSignUpPopup.types';
import styles from './SuccessSignUpPopup.module.css';

const SuccessSignUpPopup: React.FC<ISuccessSignUpPopupProps> = ({
  show,
  onHide,
  password
}: ISuccessSignUpPopupProps) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const isCopySupported: boolean = isSSR() ? false : document.queryCommandSupported('copy');
  const { t } = useTranslation();

  const handleCopyPasswordInBufferClick = useCallback(() => {
    if (passwordInputRef.current && isCopySupported) {
      passwordInputRef.current.select();
      if (!isSSR()) {
        document.execCommand('copy');
      }
      setIsCopied(true);
    }
  }, [passwordInputRef, isCopySupported, setIsCopied]);

  return (
    <Modal animation show={show} onHide={onHide} size="sm" className={styles.root}>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>{t('signUpFinish.successModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        {password && (
          <div className={styles.passwordContainer}>
            <p className={styles.text}>{t('signUpFinish.successModal.passwordText')}</p>
            <input className={styles.passwordInput} ref={passwordInputRef} type="text" value={password} readOnly />
            <span className={cx(styles.copyHint, { [styles.copyHintShow]: isCopied })}>
              {t('signUpFinish.successModal.copyHint')}
            </span>
          </div>
        )}
        <p className={styles.text}>{t('signUpFinish.successModal.emailText')}</p>
        <p className={styles.text}>
          <Trans i18nKey="signUpFinish.successModal.supportText">
            {' '}
            <a className={styles.linkText} href={t('info.emailhref')} rel="noopener noreferrer" target="_blank">
              {' '}
            </a>
          </Trans>
        </p>
        {password && (
          <Button
            className={styles.copyButton}
            variant="link"
            onClick={handleCopyPasswordInBufferClick}
            disabled={!isCopySupported}
          >
            {t('signUpFinish.successModal.copyInBufferBtn')}
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button variant="secondary" onClick={onHide}>
          {t('signUpFinish.successModal.moreBtn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessSignUpPopup;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GAME_LIVE_NAME_LONG, GAME_LIVE_NAME_MEDIUM, GAME_LIVE_NAME_SHORT, GAME_LIVES_OBJ } from '../../../constants';
import { SET_GAME_LIVES_MODE } from '../../../actions/game.action';
import { IGameModeListProps } from './GameModeList.types';
import SelectGameModeButton from '../SelectGameModeButton/SelectGameModeButton';
import { ReactComponent as LiveLongSVG } from '../../../img/game/slime.svg';
import { ReactComponent as LiveAdvanceSVG } from '../../../img/game/character-3.svg';
import { ReactComponent as LiveJesusSVG } from '../../../img/game/skeleton-armored.svg';
import styles from './GameModeList.module.css';

const GameModeList: React.FC<IGameModeListProps> = ({ livesMode }: IGameModeListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeLivesMode = useCallback(
    (id: string) => () => {
      const newGameType = {
        livesMode: id
      };
      dispatch(SET_GAME_LIVES_MODE(newGameType));
    },
    [dispatch]
  );

  const deathModeArray = useMemo(() => {
    return [
      {
        id: GAME_LIVE_NAME_LONG,
        name: t('gameContainer.start'),
        icon: <LiveLongSVG />
      },
      {
        id: GAME_LIVE_NAME_MEDIUM,
        name: t('gameContainer.advanced'),
        icon: <LiveAdvanceSVG />
      },
      {
        id: GAME_LIVE_NAME_SHORT,
        name: t('gameContainer.god'),
        icon: <LiveJesusSVG />
      }
    ];
  }, [t]);

  const renderGameButton = useCallback(
    (id: string, name: string, icon: any) => {
      const classes = `btn ${styles.gameButton} ${livesMode === id ? styles.selected : ''}`;
      return (
        <SelectGameModeButton
          isSelected={livesMode === id}
          text={name}
          countOfLives={GAME_LIVES_OBJ[id]}
          key={id}
          icon={icon}
          onClick={handleChangeLivesMode(id)}
          className={classes}
          type="button"
        />
      );
    },
    [livesMode, handleChangeLivesMode]
  );

  return (
    <div className="gameListContainer">
      {deathModeArray.map(({ id, name, icon }) => {
        return renderGameButton(id, name, icon);
      })}
    </div>
  );
};

export default GameModeList;

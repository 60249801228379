import React from 'react';
import { useTranslation } from 'react-i18next';
import { P3 } from '../../ui-components/P3/P3';
import styles from './EbikePage.module.css';

const EbikeLegalAspectsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.ebikeTextList}>
      <h4 className={styles.ebikeTextTitle}>{t('ebikeLegalAspects.title')}</h4>
      <p className="h8">{t('ebikeLegalAspects.content1.title')}</p>
      <P3>{t('ebikeLegalAspects.content1.text1')}</P3>
      <P3>{t('ebikeLegalAspects.content1.text2')}</P3>
      <P3>{t('ebikeLegalAspects.content1.text3')}</P3>
      <p className="h8">{t('ebikeLegalAspects.content2.title')}</p>
      <P3>{t('ebikeLegalAspects.content2.text')}</P3>
      <p className="h8">{t('ebikeLegalAspects.content3.title')}</p>
      <P3>{t('ebikeLegalAspects.content3.text1')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text2')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text3')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text4')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text5')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text6')}</P3>
      <P3>{t('ebikeLegalAspects.content3.text7')}</P3>
      <p className="h8">{t('ebikeLegalAspects.content4.title')}</p>
      <P3>{t('ebikeLegalAspects.content4.text1')}</P3>
      <P3>{t('ebikeLegalAspects.content4.text2')}</P3>
      <P3>{t('ebikeLegalAspects.content4.text3')}</P3>
      <p className="h8">{t('ebikeLegalAspects.content5.title')}</p>
      <P3>{t('ebikeLegalAspects.content5.text')}</P3>
    </div>
  );
};

export default EbikeLegalAspectsPage;

import React, { useCallback, useEffect, useState } from 'react';
import uuidv4 from 'uuid';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { IRootState } from '../../reducer';
import { IExamResultContainerProps } from './ExamResultContainer.types';
import { CREATE_NEW_EXAM_REQUEST, RECOVERY_EXAM_REQUEST } from '../../actions/exam.action';
import ExamResultInfo from '../../components/ExamResultInfo/ExamResultInfo';
import QuestionLinkList from '../QustionLinkList/QustionLinkList';
import { IExamResult, ILastExamInfo } from '../../reducer/exam.types';
import Congratulation from '../../components/Congratulation/Congratulation';
import { FAILURE, PENDING, SUCCESS } from '../../constants/store.constants';
import { Request } from '../../reducer/fetch.types';
import { Loader } from '../../ui-components/Loader/Loader';
import { selectLanguage, selectUserFirstExamPassed } from '../../reducer/user.selectors';
import { HAS_RATE, IS_WEBVIEW_IOS } from '../../constants';
import { IQuestion } from '../../reducer/question.types';
import { SET_USER_FIRST_EXAM_PASSED_REQUEST } from '../../actions/user.action';
import styles from './ExamResultContainer.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

export const ExamResultContainer: React.FC<IExamResultContainerProps> = ({
  routeParams
}: IExamResultContainerProps) => {
  const { examRoute } = routeParams;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const examResult: IExamResult = useSelector(({ exam }: IRootState) => exam.examResult);
  const { history } = useReactRouter();
  const createExamRequest: Request = useSelector(({ exam }: IRootState) => exam.createExamRequest);
  const updateResultRequest: Request = useSelector(({ exam }: IRootState) => exam.updateResultRequest);
  const lastExamRequest: Request = useSelector(({ exam }: IRootState) => exam.lastExamRequest);
  const lastExam: ILastExamInfo = useSelector(({ exam }: IRootState) => exam.lastExam);
  const isLogged: boolean = useSelector(({ login }: IRootState) => login.isLogged);
  const [selectedExamUuid, setSelectedExamUuid] = useState(0);
  const currentLanguage = useSelector(selectLanguage);
  const examPassed = useSelector(selectUserFirstExamPassed);
  const questionList: IQuestion[] = useSelector(({ exam }: IRootState) => exam.examQuestionList);

  const handleExamStart = useCallback(() => {
    const uuid = uuidv4();
    const { duration, questionCount, pointCount } = examResult;
    const examStartTime = Date.now();

    dispatch(CREATE_NEW_EXAM_REQUEST({ examStartTime, uuid, duration, questionCount, pointCount }));
    setSelectedExamUuid(uuid);
  }, [examResult, dispatch]);

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  useEffect(() => {
    if (createExamRequest.status === SUCCESS && selectedExamUuid) {
      history.push(`/${currentLanguage}/exam/${selectedExamUuid}`);
    }
  }, [createExamRequest, history, selectedExamUuid, currentLanguage]);

  useEffect(() => {
    if ((updateResultRequest.status === SUCCESS && !examResult) || updateResultRequest.status === FAILURE) {
      history.push(`/${currentLanguage}/`);
    }
  }, [updateResultRequest, history, examResult, currentLanguage]);

  useEffect(() => {
    if (lastExam && lastExam.uid !== examRoute && lastExamRequest.status !== PENDING) {
      dispatch(RECOVERY_EXAM_REQUEST({ examId: examRoute }));
    }
  }, [dispatch, examRoute, lastExamRequest, lastExam]);

  const handleRateClick = useCallback(() => {
    dispatch(SET_USER_FIRST_EXAM_PASSED_REQUEST({ firstexam: moment().format('YYYY-MM-DD hh:mm:ss') }));
  }, [dispatch]);

  useEffect(() => {
    if (examResult) {
      const isSuccess = examResult && examResult.truePointCount / examResult.pointCount >= 0.9;

      if (HAS_RATE && isLogged && isSuccess) {
        handleRateClick();

        if (IS_WEBVIEW_IOS) {
          window.location.assign('theorie24app://rate');

          /** show rate only once for Android  * */
        } else if (examPassed) {
          window.open('theorie24app://rate', '_self');
        }
      }
    }
  }, [isLogged, examResult, handleRateClick, examPassed]);

  return (
    <div className={styles.resultPage}>
      <Loader show={createExamRequest.status === PENDING || !examResult} />
      {examResult && (
        <Container>
          <div className={styles.resultPageContent}>
            <Congratulation isSuccess={examResult.truePointCount / examResult.pointCount >= 0.9} isLogged={isLogged} />
            <div className={styles.resultPageContentItem}>
              <ExamResultInfo examResult={examResult} currentLanguage={currentLanguage} />
              <h3 className="h4 text-center">{t('examResult.btn.mark')}</h3>
              <QuestionLinkList
                containerClass={styles.questionLinkLinkContainer}
                defaultRoute={`/${currentLanguage}/exam/${examRoute}`}
                onQuestionChange={handleQuestionChange}
                finishedExam
                isExam
                questionList={questionList}
              />
              <>
                {isLogged ? (
                  <>
                    <Button variant="secondary" className={styles.resultButton} onClick={handleExamStart}>
                      {t('examResult.btn.newExam')}
                    </Button>
                    <LinkWrapper className={styles.resultOverviewLink} to="/exam-history">
                      {`${t('examResult.btn.resultOverview')} >`}
                    </LinkWrapper>
                  </>
                ) : (
                  <Button as={Link} to={`/${currentLanguage}/offers`} className={styles.resultButton} variant="primary">
                    {t('examResult.btn.deals')}
                  </Button>
                )}
              </>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import styles from './HomeCardsTCS.module.css';

const HomeCardsTCS: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <div className={styles.promoCardList}>
          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('TCSPage.cards.routinier.title')}</h6>
              <p className={styles.cardContentText}>{t('TCSPage.cards.routinier.text')}</p>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('TCSPage.cards.vouchers.title')}</h6>
              <p className={styles.cardContentText}>{t('TCSPage.cards.vouchers.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a className="linkBtn" rel="noopener noreferrer" target="_blank" href={t('TCSPage.cards.cardLink2')}>
                {t('TCSPage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('TCSPage.cards.schoolCoupon.title')}</h6>
              <p className={styles.cardContentText}>{t('TCSPage.cards.schoolCoupon.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a className="linkBtn" rel="noopener noreferrer" target="_blank" href={t('TCSPage.cards.cardLink3')}>
                {t('TCSPage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>
          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('TCSPage.cards.scooter.title')}</h6>
              <p className={styles.cardContentText}>{t('TCSPage.cards.scooter.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <a className="linkBtn" rel="noopener noreferrer" target="_blank" href={t('TCSPage.cards.cardLink4')}>
                {t('TCSPage.cards.scooter.link')}
                <i className="icon-arrow" />
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HomeCardsTCS;

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { ITopicProgressProps } from './TopicProgress.types';
import { IUserAnswer } from '../../reducer/user.types';
import { TopicsAttributes } from '../../reducer/topic.types';
import styles from './TopicProgress.module.css';
import QuestionChooseModalContainer from '../../containers/QuestionChooseModalContainer/QuestionChooseModalContainer';
import { getUsersMarkedQuestions } from '../../selectors/user.selectors';
import { CategoryType } from '../../reducer/question.types';
import { getMarkedCategory } from '../../utils/utils';

export const marketProgress = (
  topic: TopicsAttributes,
  userAnswers: IUserAnswer,
  markedQuestions: number[],
  currentCategory: CategoryType
) => {
  const markedCategory = getMarkedCategory(currentCategory);

  let checkedCount: number = 0;
  let correctCount: number = 0;
  const topicIds = Object.keys(userAnswers);
  topicIds.forEach(topicId => {
    const answersKeyList: string[] = Object.keys(userAnswers[topicId]);
    answersKeyList.forEach(answerKey => {
      if (
        typeof userAnswers[topicId][answerKey].correct === 'boolean' &&
        markedQuestions[markedCategory] &&
        markedQuestions[markedCategory].includes(Number(answerKey))
      ) {
        checkedCount += 1;

        if (userAnswers[topicId][answerKey].correct && topic.id === 'marked') {
          correctCount += 1;
        }
      }
    });
  });

  return {
    status: checkedCount ? correctCount / checkedCount : -1,
    progress: checkedCount ? (checkedCount / topic.questionsCount) * 100 : 0,
    correctCount,
    questionsCount: markedQuestions[markedCategory] ? markedQuestions[markedCategory].length : 0
  };
};

export const incorrectProgress = (topic: TopicsAttributes, userAnswers: IUserAnswer) => {
  let checkedCount: number = 0;
  let correctCount: number = 0;
  const questionList: string[] = [];
  if (userAnswers) {
    const topicIds = Object.keys(userAnswers);
    topicIds.forEach(topicId => {
      const answersKeyList: string[] = Object.keys(userAnswers[topicId]);
      answersKeyList.forEach(answerKey => {
        if (typeof userAnswers[topicId][answerKey].correct === 'boolean') {
          checkedCount += 1;

          if (!userAnswers[topicId][answerKey].correct && topic.id === 'incorrect') {
            correctCount += 1;
            questionList.push(answerKey);
          }
        }
      });
    });
  }
  return {
    status: checkedCount ? correctCount / checkedCount : -1,
    progress: checkedCount ? (checkedCount / topic.questionsCount) * 100 : 0,
    correctCount,
    questionList
  };
};

export const allProgress = (topic: TopicsAttributes, userAnswers: IUserAnswer) => {
  let checkedCount: number = 0;
  let correctCount: number = 0;

  const topicIds = Object.keys(userAnswers);
  topicIds.forEach(topicId => {
    const answersKeyList: string[] = Object.keys(userAnswers[topicId]);
    answersKeyList.forEach(answerKey => {
      if (typeof userAnswers[topicId][answerKey].correct === 'boolean') {
        checkedCount += 1;

        if (userAnswers[topicId][answerKey].correct) {
          correctCount += 1;
        }
      }
    });
  });

  const devider = topic?.questionsCount ? topic?.questionsCount : 1;

  return {
    status: checkedCount ? correctCount / checkedCount : -1,
    progress: checkedCount ? (checkedCount / devider) * 100 : 0,
    correctCount
  };
};

export const defaultTopicProgress = (topic: TopicsAttributes, userAnswers: IUserAnswer) => {
  // is topic
  let checkedCount: number = 0;
  let correctCount: number = 0;

  if (topic.id > 0) {
    // has no answers, yet
    if (!userAnswers[topic.id]) {
      return {
        status: -1,
        progress: 0
      };
    }

    const answersKeyList: string[] = Object.keys(userAnswers[topic.id]);

    answersKeyList.forEach(answerKey => {
      if (typeof userAnswers[topic.id][answerKey].correct === 'boolean') {
        checkedCount += 1;
        if (userAnswers[topic.id][answerKey].correct) {
          correctCount += 1;
        }
      }
    });
  }

  return {
    status: checkedCount ? correctCount / checkedCount : -1,
    progress: checkedCount ? (checkedCount / topic.questionsCount) * 100 : 0,
    correctCount
  };
};

const topicProgressMap = {
  all: allProgress,
  marked: marketProgress,
  incorrect: incorrectProgress
};

const TopicProgress: React.FC<ITopicProgressProps> = ({
  topic,
  userAnswers,
  currentCategory,
  currentLanguage,
  onQuestionChange
}: ITopicProgressProps) => {
  const [showWin, setShowWin] = useState(false);
  const markedQuestions = useSelector(getUsersMarkedQuestions);
  const markedCategory = getMarkedCategory(currentCategory);
  const changeModalView = useCallback(() => {
    setShowWin(!showWin);
  }, [showWin, setShowWin]);
  const isMarkedTopic = topic.id === 'marked';
  const isIncorrectTopic = topic.id === 'incorrect';
  // eslint-disable-next-line no-nested-ternary
  const questionsCount = isMarkedTopic
    ? markedQuestions
      ? markedQuestions[markedCategory].length
      : topic.questionsCount
    : topic.questionsCount;
  const getTopicProgress = (
    currentTopic: TopicsAttributes,
    userAnswersList: IUserAnswer,
    markedQuestionsList: number[]
  ) =>
    (topicProgressMap[topic.id] || defaultTopicProgress)(
      currentTopic,
      userAnswersList,
      markedQuestionsList,
      currentCategory
    );
  const topicstatus = getTopicProgress({ ...topic, questionsCount }, userAnswers, markedQuestions);
  const { questionList } = topicstatus;
  // const questionProcess = isIncorrectTopic
  //   ? topicstatus.correctCount
  //   : ((topicstatus.progress * questionsCount) / 100).toFixed(0);

  if (isIncorrectTopic) {
    return <p className={styles.incorrectCountText}>{topicstatus.correctCount}</p>;
  }

  // title={`${questionProcess} / ${questionsCount}`
  return (
    <div className={styles.progressContent}>
      <ProgressBar now={topicstatus.progress} onClick={changeModalView} />
      {!isIncorrectTopic && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={props => (
            <Tooltip id="button-tooltip" {...props}>
              {`${topicstatus.status > -1 ? (topicstatus.status * 100).toFixed(0) : '0'} %`}
            </Tooltip>
          )}
        >
          <div
            className={classNames(styles.status, {
              [styles.empty]: topicstatus.status === -1,
              [styles.bad]: topicstatus.status >= 0.0 && topicstatus.status < 0.4,
              [styles.middle]: topicstatus.status >= 0.4 && topicstatus.status < 1.0,
              [styles.good]: topicstatus.status === 1.0
            })}
          />
        </OverlayTrigger>
      )}
      <QuestionChooseModalContainer
        onQuestionChange={onQuestionChange}
        defaultRoute={`/${currentLanguage}/questions/${currentCategory}/${topic.id}`}
        show={showWin}
        onHide={changeModalView}
        topicId={topic.id}
        questionList={(isMarkedTopic || isIncorrectTopic) && questionList}
      />
    </div>
  );
};

export default TopicProgress;

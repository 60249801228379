import { IRootState } from '../reducer';
import { IMarkedQuestion } from '../saga/user.types';

export const getUsersMarkedQuestions = ({
  user: {
    profile: {
      questionsList: { markedQuestions }
    }
  }
}: IRootState): IMarkedQuestion => markedQuestions;

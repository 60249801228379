import React from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS, isAndroid } from 'react-device-detect';
// import { imageAbsolutePath } from '../../utils/utils';
import VipCardTCS from '../VipCardTCS/VipCardTCS';
import styles from './VipContentTCS.module.css';

// const google = imageAbsolutePath('googleBtn.svg');
// const apple = imageAbsolutePath('appleBtn.svg');

const VipContentTCS: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sectionVipContent}>
      <div className={styles.sectionVipItem}>
        <div className="h3">{t('TCSPage.vipCard.title')}</div>
        <VipCardTCS />
      </div>
      {!isIOS && !isAndroid && (
        <div className={styles.sectionVipItem}>
          <div className="h3">{t('TCSPage.readyToLearn.title')}</div>
          <p className={styles.vipText}>{t('TCSPage.readyToLearn.text1')}</p>
          <p className={styles.vipText}>{t('TCSPage.readyToLearn.text2')}</p>
          <p className={styles.vipText}>{t('TCSPage.readyToLearn.text3')}</p>
          <p className={styles.vipText}>{t('TCSPage.readyToLearn.text4')}</p>
        </div>
      )}
    </div>
  );
};

export default VipContentTCS;

import React, { useEffect } from 'react';
import { ThemToggleProps } from './ThemToggle.types';
import styles from './ThenToggle.module.css';

import sun from '../../img/themeSwithcer/sun.png';
import moon from '../../img/themeSwithcer/moon.png';
// import heel from '../../img/themeSwithcer/heel.png';
import { switchColorTheme } from '../../utils/utils';
import { ThemeColors } from '../../reducer/theme.types';

const ThemToggle: React.FC<ThemToggleProps> = ({
  containerClassName,
  setThemColor,
  currentThemeName
}: ThemToggleProps) => {
  // const labelClasses = classNames(styles.reactSwitchLabel, { [styles.isActive]: isOn });

  const setTheme = (name: ThemeColors) => () => {
    setThemColor(name);
  };

  useEffect(() => {
    switchColorTheme(currentThemeName);
  }, [currentThemeName]);

  // TODO remove after lady mode will be added
  const toggleTheme = () => {
    if (currentThemeName === ThemeColors.light) {
      return setTheme(ThemeColors.dark)();
    }
    if (currentThemeName === ThemeColors.dark) {
      return setTheme(ThemeColors.light)();
    }
    return null;
  };

  return (
    <div className={containerClassName}>
      <div onClick={toggleTheme} className={styles.reactSwitchLabel}>
        <span className={styles.reactSwitchButton} />
        {/* TODO return this for lady-mode */}
        {/* <button type="button" onClick={setTheme(ThemeColors.lady)}> */}
        {/*  <img src={heel} alt="heel" /> */}
        {/* </button> */}
        {/* <button type="button" onClick={setTheme(ThemeColors.light)}> */}
        <button type="button">
          <img src={sun} alt="light" />
        </button>
        {/* <button type="button" onClick={setTheme(ThemeColors.dark)}> */}
        <button type="button">
          <img src={moon} alt="dark" />
        </button>
      </div>
    </div>
  );
};

export default ThemToggle;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { GET_OFFERS_REQUEST } from '../actions/offer.action';
import OfferCard from '../components/OfferCard/OfferCard';
import OfferAccordion from '../components/OfferAccordion/OfferAccordion';
import { selectLanguage } from '../reducer/user.selectors';
import { selectOffer } from '../reducer/offer.selectors';
import { IOffersState } from '../reducer/offer.types';
import { FAILURE, PENDING, SUCCESS, UNCALLED } from '../constants/store.constants';
import PlaceholderBox from '../components/PlaceholderBox/PlaceholderBox';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import IOfferCardContainer from './OfferCardContainer.types';
import styles from './OfferCardContainer.module.css';
import useDeviceSizes from '../hooks/useDeviceSizes';

const OfferCardContainer: React.FC<IOfferCardContainer> = ({ showTabledView = false }) => {
  const dispatch = useDispatch();
  const { width } = useDeviceSizes();
  const isDesktopMode = !showTabledView ? width > 991 : false;
  const { isLogged }: LoginState = useSelector(selectLogin);
  const { offersList, offerListRequest }: IOffersState = useSelector(selectOffer);
  const currentLanguage = useSelector(selectLanguage);

  useEffect(() => {
    if (offerListRequest.status === UNCALLED) {
      dispatch(GET_OFFERS_REQUEST());
    }
  }, [dispatch, offerListRequest]);

  const CardComponent = isDesktopMode ? OfferCard : OfferAccordion;
  const containerClassName = isDesktopMode ? styles.offerCardList : styles.offerAccordionList;

  return (
    <Container>
      <div className={containerClassName}>
        {[PENDING, FAILURE].includes(offerListRequest.status) &&
          offersList.length === 0 &&
          Array.from(Array(6).keys()).map(index => (
            <PlaceholderBox show key={index} className={styles.placeholderBox} />
          ))}
        {offerListRequest.status === SUCCESS &&
          offersList.map(
            offer =>
              (typeof offer.selectable === 'undefined' || offer.selectable) && (
                <CardComponent
                  key={offer.id}
                  offer={offer}
                  linkTo={`/${isLogged ? 'plan-extension' : 'register'}?offer=${offer.id}`}
                  currentLanguage={currentLanguage}
                />
              )
          )}
      </div>
    </Container>
  );
};

export default OfferCardContainer;

import { handleActions } from 'redux-actions';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { defaultRequestStatus } from './fetch.types';
import { IAuthState } from './auth.types';
import { FIREBASE_LOGIN_TRY_COUNT } from '../constants';
import {
  FIREBASE_AUTH_FAILURE,
  FIREBASE_AUTH_REQUEST,
  FIREBASE_AUTH_SUCCESS,
  GET_TOKEN_FAILURE,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  SET_DEFAULT_AUTH,
  SET_DEVICE_CHECKED,
  SET_DEVICE_UID,
  SET_OFFLINE_MODE_STATE,
  UPDATE_DEVICE_UID_FAILURE,
  UPDATE_DEVICE_UID_REQUEST,
  UPDATE_DEVICE_UID_SUCCESS,
  UPDATE_TRY_LOGIN_COUNT
} from '../actions/auth.action';
import { CLEAR_USER } from '../actions/user.action';

const defaultState: IAuthState = {
  offlineMode: false,
  token: null,
  firebaseToken: null,
  firebaseAuth: false,
  deviceChecked: false,
  deviceUid: null,
  getTokenRequest: defaultRequestStatus,
  firebaseAuthRequest: defaultRequestStatus,
  updateDeviceUid: defaultRequestStatus,
  tryLoginCount: FIREBASE_LOGIN_TRY_COUNT
};

const reduces = handleActions(
  {
    [SET_DEFAULT_AUTH]: () => ({ ...defaultState }),
    [SET_OFFLINE_MODE_STATE]: (state, action) => ({
      ...state,
      offlineMode: action.payload
    }),
    [UPDATE_DEVICE_UID_REQUEST]: state => ({
      ...state,
      updateDeviceUid: { status: PENDING, error: null }
    }),
    [UPDATE_DEVICE_UID_SUCCESS]: state => ({
      ...state,
      updateDeviceUid: { status: SUCCESS, error: null }
    }),
    [UPDATE_DEVICE_UID_FAILURE]: (state, action) => ({
      ...state,
      updateDeviceUid: { status: FAILURE, error: action.payload }
    }),
    [GET_TOKEN_REQUEST]: state => ({
      ...state,
      getTokenRequest: { status: PENDING, error: null }
    }),
    [GET_TOKEN_FAILURE]: (state, action) => ({
      ...defaultState,
      getTokenRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      token: action.payload.token,
      firebaseToken: action.payload.firebaseToken,
      getTokenRequest: { status: SUCCESS, error: null }
    }),
    [CLEAR_USER]: state => ({
      ...state,
      token: null,
      getTokenRequest: defaultState.getTokenRequest
    }),
    [FIREBASE_AUTH_REQUEST]: state => ({
      ...state,
      firebaseAuth: false,
      firebaseAuthRequest: { status: PENDING, error: null }
    }),
    [FIREBASE_AUTH_FAILURE]: (state, action) => ({
      ...defaultState,
      firebaseAuthRequest: { status: FAILURE, error: action.payload }
    }),
    [FIREBASE_AUTH_SUCCESS]: state => ({
      ...state,
      firebaseAuth: true,
      firebaseAuthRequest: { status: SUCCESS, error: null }
    }),
    [SET_DEVICE_CHECKED]: (state, action) => ({
      ...state,
      deviceChecked: action.payload
    }),
    [SET_DEVICE_UID]: (state, action) => ({
      ...state,
      deviceUid: action.payload
    }),
    [UPDATE_TRY_LOGIN_COUNT]: (state, action) => ({
      ...state,
      tryLoginCount: action.payload
    })
  },
  defaultState
);

export default reduces;

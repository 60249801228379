import React, { useEffect, useMemo } from 'react';
import { ProgressBarLine } from 'react-progressbar-line';
import classNames from 'classnames';
import { ICounterProgressBar } from './CountProgressBar.types';
import styles from './CountProgressBar.module.css';

const CountProgressBar: React.FC<ICounterProgressBar> = ({ value, text, tick }: ICounterProgressBar) => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      tick();
    }, 1000);
    return () => {
      window.clearTimeout(timer);
    };
  }, [value, tick]);

  const tailColor = useMemo(() => {
    // if (value > 50) {
    //   return '#0080c9';
    // }
    // if (value <= 50 && value > 20) {
    //   return '#ffc107';
    // }
    // if (value <= 20) {
    //   return '#e20000';
    // }
    // return '#0080c9';
    return '#fff';
  }, []);

  const containerClass = classNames(styles.container, { [styles.hidden]: value <= 0 });

  return (
    <>
      <div className={containerClass}>
        <ProgressBarLine
          value={value}
          min={0}
          text={text}
          max={100}
          strokeWidth={3}
          trailWidth={3}
          styles={{
            path: {
              stroke: tailColor
            },
            trail: {
              stroke: 'transparent'
            },
            text: {
              fill: '#404040',
              textAlign: 'center',
              fontSize: '32px'
            }
          }}
        />
      </div>
    </>
  );
};

export default CountProgressBar;

import React, { useCallback } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Nav, Form, Button } from 'react-bootstrap';
import NavLinkWrapper from '../../ui-components/NavLinkWrapper/NavLinkWrapper';
import DemoFlag from '../DemoFlag/DemoFlag';
import { lngListTCS } from '../../locales/locale';
import Translate from '../Translate/Translate';
import { TCSLinkGenerator, triggerMenuCollapse } from '../../utils/utils';
import { IHeaderMenuTCSProps } from './HeaderMenuTCS.types';
import styles from './HeaderMenuTCS.module.css';
import { selectLanguage } from '../../reducer/user.selectors';

const HeaderMenuTCS: React.FC<IHeaderMenuTCSProps> = ({
  isLogged,
  routeProps,
  onLogoutClick,
  isDemo
}: IHeaderMenuTCSProps) => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);

  const handleLogoutBtnClick = useCallback(() => {
    onLogoutClick();
    triggerMenuCollapse();
  }, [onLogoutClick]);

  return (
    <>
      <Nav className={`${styles.headerMenu} mr-auto`}>
        <Nav.Link as={NavLinkWrapper} to="/questions" href="#questions" hidden={!isLogged} active={false}>
          {t('headerTCS.ask')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/exam" href="#exam" hidden={!isLogged} active={false}>
          {t('headerTCS.exam')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/theory" href="#theory" hidden={!isLogged} active={false}>
          {t('headerTCS.theory')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/game-mode" href="#game-mode" hidden={!isLogged} active={false}>
          {t('headerTCS.gameMode')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/3dteacher" href="#3dteacher" hidden={!isLogged} active={false}>
          {t('headerTCS.3d')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/ebike" href="#ebike" hidden={!isLogged} active={false}>
          {t('headerTCS.ebike')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/contacts" href="#contacts" hidden={!isLogged} active={false}>
          {t('headerTCS.contact')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/faq" href="#faq" hidden={!isLogged} active={false}>
          {t('headerTCS.faq')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/anleitung" href="#" hidden={!isLogged} active={false}>
          {t('headerTCS.manual')}
        </Nav.Link>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('howitworks', currentLanguage)}>
          {t('headerTCS.howItWorks')}
        </a>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('vipcode', currentLanguage)}>
          {t('headerTCS.vipcode')}
        </a>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('about', currentLanguage)}>
          {t('headerTCS.about')}
        </a>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('faq', currentLanguage)}>
          {t('headerTCS.faq')}
        </a>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('tcsoffers', currentLanguage)}>
          {t('headerTCS.deals')}
        </a>
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('contacts', currentLanguage)}>
          {t('headerTCS.contact')}
        </a>
        {/* <Nav.Link as={NavLinkWrapper} to="/instructor" href="#fahrlehrer" hidden={isLogged} active={false}> */}
        {/*  {t('header.drivingInstructor')} */}
        {/* </Nav.Link> */}
        <a className="nav-link" rel="noopener noreferrer" href={TCSLinkGenerator('mycockpitdemo', currentLanguage)}>
          {t('headerTCS.demo')}
        </a>
        <DemoFlag isHidden={!isDemo} />
        {isLogged && (
          <Nav.Link
            className={cx(styles.loginBtnItem, styles.loginBtnMobile)}
            as={NavLinkWrapper}
            href="#mycockpit"
            to="/mycockpit"
            active={false}
          >
            {t('headerTCS.cockpit')}
          </Nav.Link>
        )}
      </Nav>
      <Nav className={styles.loginBtnGroup} hidden={!isLogged}>
        <Nav.Link
          className={cx(styles.loginBtnItem, styles.desktop)}
          as={NavLinkWrapper}
          href="#mycockpit"
          to="/mycockpit"
          active={false}
        >
          {t('headerTCS.cockpit')}
        </Nav.Link>
        <Form>
          <Button className={styles.logoutBtn} variant="secondary" onClick={handleLogoutBtnClick}>
            {t('app.btn.logout')}
          </Button>
        </Form>
      </Nav>
      <Nav className={styles.langIconGroup}>
        {Object.keys(lngListTCS).map((lng: string) => {
          return (
            <Nav.Link key={lng} href="#">
              <Translate lng={lngListTCS[lng].shortName} icon={lngListTCS[lng].icon} routeProps={routeProps} />
            </Nav.Link>
          );
        })}
      </Nav>
    </>
  );
};

export default HeaderMenuTCS;

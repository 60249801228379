import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducer';
import { IQuestion } from '../../reducer/question.types';
import { IUserAnswer, IUserAnswerByTopic } from '../../reducer/answer.types';
import { IUserExamAnswer, IUserExamAnswerByExamId } from '../../reducer/exam.types';
import { QuestionLink } from '../../ui-components/QuestionLink/QuestionLink';
import { getUsersMarkedQuestions } from '../../selectors/user.selectors';
import { getMarkedCategory } from '../../utils/utils';
import { IQuestionLinkListProps } from './QuestionLinkList.types';
import styles from './QuestionLinkList.module.css';

const getStatusCls = (answerList: IUserAnswerByTopic, question: IQuestion, isExam?: boolean): string => {
  if (isExam) {
    // in exam mode don't use question answers
    return '';
  }
  const answer: IUserAnswer | null =
    answerList && answerList[question.themeid] ? answerList[question.themeid][question.id] : null;
  switch (answer && answer.correct) {
    case true:
      return 'examListItemCorrect';
    case false:
      return 'examListItemIncorrect';
    default:
      return '';
  }
};
const getCheckedExamQuestionStatus = (
  answerList: IUserExamAnswerByExamId,
  question: IQuestion,
  examId: number
): boolean => {
  const answer: IUserExamAnswer | null = answerList[examId] ? answerList[examId][question.id] : null;
  return !!(answer && answer.selected && answer.selected.length);
};

const getExamCorrectStatus = (answerList: IUserExamAnswerByExamId, question: IQuestion, examId: number): boolean => {
  const answer: IUserExamAnswer | null = answerList[examId] ? answerList[examId][question.id] : null;
  return !!(answer && answer.correct);
};

const QuestionLinkList: React.FC<IQuestionLinkListProps> = ({
  onQuestionChange,
  isExam,
  finishedExam,
  defaultRoute,
  questionList,
  containerClass = ''
}: IQuestionLinkListProps) => {
  const questionsListFromStore: IQuestion[] = useSelector(({ question }: IRootState) => question.questionList);
  const questions: IQuestion[] = questionList || questionsListFromStore;
  const examId = useSelector(({ exam }: IRootState) => exam.currentExamId);
  const questionAnswers: IUserAnswerByTopic = useSelector(({ answer }: IRootState) => answer.answerList);
  const examAnswers: IUserAnswerByTopic = useSelector(({ exam }: IRootState) => exam.examAnswerList);
  const markedQuestions = useSelector(getUsersMarkedQuestions);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const categoryMarked = getMarkedCategory(currentCategory);
  const containerClasses = classNames(styles.examList, containerClass, 'questionLinkList');

  return (
    <div className={containerClasses}>
      {questions.map((question, id) => (
        <QuestionLink
          key={question.id}
          onQuestionChange={onQuestionChange}
          link={`${defaultRoute}/${id + 1}`}
          className={classNames(
            styles.examListItem,
            'singleQuestionLink',
            getStatusCls(questionAnswers, question, isExam),
            {
              [styles.marked]: !isExam && markedQuestions?.[categoryMarked]?.includes(+question.id),
              [styles.favorite]:
                isExam &&
                examAnswers[examId] &&
                examAnswers[examId][question.id] &&
                examAnswers[examId][question.id].favorite,
              [styles.checked]: isExam && getCheckedExamQuestionStatus(examAnswers, question, examId),
              examListItemCorrect: isExam && finishedExam && getExamCorrectStatus(examAnswers, question, examId),
              examListItemIncorrect: isExam && finishedExam && !getExamCorrectStatus(examAnswers, question, examId)
            }
          )}
        >
          <span className={styles.questionsNumbers}>{id + 1}</span>
        </QuestionLink>
      ))}
    </div>
  );
};

export default QuestionLinkList;

import { put, takeEvery, takeLatest, call, all, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  GET_PROFILE_FROM_FIREBASE_REQUEST,
  GET_PROFILE_FROM_FIREBASE_FAILURE,
  GET_USER_EXAM_RESULTS_REQUEST,
  GET_USER_EXAM_RESULTS_SUCCESS,
  GET_USER_EXAM_RESULTS_FAILURE,
  GET_PROFILE_FROM_FIREBASE_SUCCESS,
  PLAN_EXTENSION_REQUEST,
  PLAN_EXTENSION_FAILURE,
  PLAN_EXTENSION_SUCCESS,
  SET_USER_LANGUAGE_REQUEST,
  SET_USER_LANGUAGE_FAILURE,
  SET_USER_LANGUAGE_SUCCESS,
  SET_MARKED_QUESTION,
  SET_USER_SELECTED_CATEGORY,
  UPDATE_CURRENT_CATEGORY,
  SAVE_SELECTED_CATEGORY_REQUEST,
  SAVE_SELECTED_CATEGORY_SUCCESS,
  SAVE_SELECTED_CATEGORY_FAILURE,
  SET_USER_FIRST_EXAM_PASSED_REQUEST,
  SET_USER_FIRST_EXAM_PASSED_SUCCESS,
  SET_USER_FIRST_EXAM_PASSED_FAILURE,
  SET_BOOK_MARKED_PAGE_REQUEST,
  SET_BOOK_MARKED_PAGE_SUCCESS,
  SET_BOOK_MARKED_PAGE_FAILURE
} from '../actions/user.action';
import { getLastExams, getAllExams } from './exam.api.fb';
import {
  getFirebaseProfile,
  getProfile,
  userPlanExtension,
  setUserLanguageApi,
  setUserFirstExamPassedApi,
  setMarkedQuestion,
  setUserCategorySelected,
  setBookMarkedPage
} from './user.api';
import { firebaseApp } from '../config/firebase.config';
import { SET_IS_LOGGED } from '../actions/login.action';
import { getFBCategoryName } from '../utils/utils';
import { COOKIES_EXPIRES, EMPTY_MARKED_QUESTIONS } from '../constants';
import { LANGUAGE_KEY, DEMO_CATEGORY } from '../constants/storageKeys';
import { selectLanguage } from '../reducer/user.selectors';
import { selectIsOfflineMode } from '../reducer/auth.selectors';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import i18n from '../i18n-local';
import { EMPTY_EXAM_HISTORY } from '../reducer/user';

function* preselectCorrectCategory(selectedCategory: string, availableCategories: string[], response) {
  // if user has preselected
  if (selectedCategory) {
    if (availableCategories.indexOf(selectedCategory) !== -1) {
      response.payload.customer.currentCategory = response.payload.customer.category;
    } else if (availableCategories) {
      // eslint-disable-next-line prefer-destructuring
      response.payload.customer.currentCategory = availableCategories[0];
    }
  } else if (availableCategories) {
    // user don't have preselect
    yield put(UPDATE_CURRENT_CATEGORY(availableCategories[0]));
  } else {
    yield put(UPDATE_CURRENT_CATEGORY('B'));
  }
  return response;
}

function* getUserExamInfo({ payload }) {
  try {
    const { currentCategory } = payload;
    const categoryForQuery = getFBCategoryName(currentCategory);
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;

    const lastExamsInfo = yield getLastExams(userId, categoryForQuery);
    const sortedbyCorrectAnswers = yield getAllExams(userId, categoryForQuery);
    yield put(
      GET_USER_EXAM_RESULTS_SUCCESS(
        sortedbyCorrectAnswers
          ? {
              lastExams: lastExamsInfo,
              bestResult: sortedbyCorrectAnswers
            }
          : EMPTY_EXAM_HISTORY
      )
    );
  } catch (e) {
    yield put(GET_USER_EXAM_RESULTS_FAILURE(e.message));
  }
}

function* getUserProfile() {
  try {
    let response = yield getProfile();
    if (response.status !== 200) {
      yield put(GET_USER_PROFILE_FAILURE(response.payload.message));
      yield put(
        SET_IS_LOGGED({
          isLogged: false
        })
      );
    } else {
      // add preselected category
      response = yield preselectCorrectCategory(
        response.payload.customer.category,
        response.payload.customer.categories,
        response
      );

      yield put(GET_USER_PROFILE_SUCCESS(response.payload.customer));
      yield put(
        SET_IS_LOGGED({
          isLogged: true
        })
      );
    }
  } catch (e) {
    yield put(GET_USER_PROFILE_FAILURE(e.message));
    yield put(
      SET_IS_LOGGED({
        isLogged: false
      })
    );
  }
}

function* getFirebaseUserProfile(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;
    if (userId) {
      const profile = yield getFirebaseProfile(userId);

      if (profile) {
        yield put(GET_PROFILE_FROM_FIREBASE_SUCCESS(profile));
        yield put(
          SET_IS_LOGGED({
            isLogged: true
          })
        );

        if (action.payload.isOnline) {
          yield put(GET_USER_PROFILE_REQUEST());
        }
      } else {
        yield put(GET_PROFILE_FROM_FIREBASE_FAILURE('Empty profile!'));
      }
    } else {
      const language = Cookies.get(LANGUAGE_KEY);

      yield put(GET_PROFILE_FROM_FIREBASE_FAILURE('Empty user!'));

      if (language) {
        yield put(SET_USER_LANGUAGE_SUCCESS({ language }));
      }
    }
  } catch (e) {
    yield put(GET_PROFILE_FROM_FIREBASE_FAILURE(e.message));
    yield put(
      SET_IS_LOGGED({
        isLogged: false
      })
    );
  }
}

function* setUserLanguage({ payload }) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : null;
  const currentLanguage = yield select(selectLanguage);
  Cookies.set(LANGUAGE_KEY, payload.language, { expires: COOKIES_EXPIRES });

  // currentLanguage !== payload.language - minimize requests to api server
  // userId - if not found, save language localy
  if (userId && currentLanguage !== payload.language) {
    try {
      const isOffline = yield select(selectIsOfflineMode);
      if (!isOffline) {
        const response = yield call(setUserLanguageApi, payload.language);
        if (response.status !== 200) {
          yield put(SET_USER_LANGUAGE_FAILURE(response.payload.message));

          // we need to change language even if we get an error in saving language in the back-end
          yield put(SET_USER_LANGUAGE_SUCCESS({ language: payload.language }));
        } else {
          yield put(SET_USER_LANGUAGE_SUCCESS(payload));
        }
      }
    } catch (e) {
      yield put(SET_USER_LANGUAGE_FAILURE(e));
    }
  } else {
    yield put(SET_USER_LANGUAGE_SUCCESS(payload));
  }
}

function* setUserFirstExamPassed({ payload }) {
  try {
    const response = yield call(setUserFirstExamPassedApi, payload.firstexam);
    if (response.status !== 200) {
      yield put(SET_USER_FIRST_EXAM_PASSED_FAILURE(response.payload.message));
    } else {
      yield put(SET_USER_FIRST_EXAM_PASSED_SUCCESS(payload));
    }
  } catch (e) {
    yield put(SET_USER_FIRST_EXAM_PASSED_FAILURE(e));
  }
}

function* makePlanExtension({ payload }) {
  try {
    const response = yield call(userPlanExtension, payload);
    if (response.status !== 200) {
      yield put(PLAN_EXTENSION_FAILURE(response.payload.message));
    } else {
      yield put(PLAN_EXTENSION_SUCCESS());
      window.location.href = response.payload.payment.html || '/';
    }
  } catch (e) {
    yield put(PLAN_EXTENSION_FAILURE(e));
  }
}

function* setUserMarkedQuestion({ payload }) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : null;
  if (userId) {
    yield setMarkedQuestion(userId, payload || EMPTY_MARKED_QUESTIONS);
  } else {
    yield put(SET_MARKED_QUESTION(EMPTY_MARKED_QUESTIONS));
  }
}

function* saveSelectedCategory({ payload }) {
  // saved selected category if user not logged in
  try {
    const { isLogged }: LoginState = yield select(selectLogin);
    if (isLogged) {
      yield put(SAVE_SELECTED_CATEGORY_REQUEST());
      const response = yield call(setUserCategorySelected, payload.category);
      if (response.status !== 200) {
        yield put(SAVE_SELECTED_CATEGORY_FAILURE(response.message));
      } else {
        yield put(SAVE_SELECTED_CATEGORY_SUCCESS());
      }
    } else {
      Cookies.set(DEMO_CATEGORY, payload.category);
    }
  } catch (e) {
    yield put(SAVE_SELECTED_CATEGORY_FAILURE(e));
  }
}

function* setBookMarked(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;
    if (userId) {
      const toastId = toast.loading('Loading...', {
        position: 'top-right'
      });
      const toastMessage = action.payload
        ? i18n.t('theoryPage.bookMark.successfullySaved')
        : i18n.t('theoryPage.bookMark.successfullyRemoved');
      yield call(setBookMarkedPage, userId, action.payload);
      toast.success(toastMessage, {
        id: toastId,
        position: 'top-right'
      });
      yield put(SET_BOOK_MARKED_PAGE_SUCCESS(action.payload));
    }
  } catch (e) {
    yield put(SET_BOOK_MARKED_PAGE_FAILURE(e.message));
  }
}

function* userSaga() {
  yield all([
    takeEvery(GET_USER_PROFILE_REQUEST, getUserProfile),
    takeEvery(GET_PROFILE_FROM_FIREBASE_REQUEST, getFirebaseUserProfile),
    takeEvery(GET_USER_EXAM_RESULTS_REQUEST, getUserExamInfo),
    takeEvery(SET_USER_LANGUAGE_REQUEST, setUserLanguage),
    takeLatest(PLAN_EXTENSION_REQUEST, makePlanExtension),
    takeLatest(SET_USER_SELECTED_CATEGORY, saveSelectedCategory),
    takeEvery(SET_USER_FIRST_EXAM_PASSED_REQUEST, setUserFirstExamPassed),
    takeLatest(SET_MARKED_QUESTION, setUserMarkedQuestion),
    takeEvery(SET_BOOK_MARKED_PAGE_REQUEST, setBookMarked)
  ]);
}

export default userSaga;

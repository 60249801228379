import { handleActions } from 'redux-actions';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { GET_OFFERS_REQUEST, GET_OFFERS_FAILURE, GET_OFFERS_SUCCESS } from '../actions/offer.action';
import { IOffersState } from './offer.types';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IOffersState = {
  offersList: [],
  offerListRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_OFFERS_REQUEST]: () => ({
      ...defaultState,
      offerListRequest: { status: PENDING, error: null }
    }),
    [GET_OFFERS_FAILURE]: action => ({
      ...defaultState,
      offerListRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_OFFERS_SUCCESS]: (state, action) => ({
      ...state,
      offerListRequest: { status: SUCCESS, error: null },
      offersList: action.payload
    })
  },
  defaultState
);

export default reduces;

import moment from 'moment';
import { defaultLanguage } from '../locales/locale';
import { LanguageType } from '../types';

const LOCALE_MAP: Record<LanguageType, string> = {
  en: 'en-gb',
  it: 'it',
  fr: 'fr',
  de: 'de'
};

export const setMomentLocale = (locale?: LanguageType) => {
  if (!locale || !LOCALE_MAP[locale]) {
    moment.locale(defaultLanguage);
  } else {
    moment.locale(LOCALE_MAP[locale]);
  }
};

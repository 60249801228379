import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { ImageZoomContainer } from '../ImageZoomContainer/ImageZoomContainer';
import { IMultimediaModalProps } from './MultimediaModal.types';
import styles from './MultimediaModal.module.css';

const MultimediaModal: React.FC<IMultimediaModalProps> = ({
  onHide,
  show,
  src,
  alt,
  video,
  className
}: IMultimediaModalProps) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.height = 'auto';
    }
  }, [show]);

  return (
    <Modal
      className={classNames(styles.multiModal, className)}
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {video ? (
          <video className={styles.videoContent} src={src} muted playsInline controls crossOrigin="anonymous">
            <source src={src} type="video" />
            <track kind="captions" />
          </video>
        ) : (
          <ImageZoomContainer>
            <img className={styles.zoomImg} src={src} alt={alt} />
          </ImageZoomContainer>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MultimediaModal;

// @ts-nocheck
import React, { useCallback, useEffect, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { ICustomSwiperProps } from './CustomSwiper.types';
import { useStateWithCallbackLazy } from '../../hooks/useStateWithCallbackLazy';
import { delay } from '../../utils/utils';
import { customSwiperDefaultValue } from './CustomSwiper.defaultValues';

const swipeableConfig = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
  trackTouch: true, // track touch input
  trackMouse: true, // track mouse input
  rotationAngle: 0 // set a rotation angle
};

const CustomSwiper: React.FC<ICustomSwiperProps> = ({
  index,
  itemList,
  useRef,
  renderCard,
  componentStyle,
  animations = { ...customSwiperDefaultValue.animation },
  swipeConfig: {
    dragPosition,
    whileDrag,
    dragConstraints = customSwiperDefaultValue.dragConstraints,
    dragElastic = customSwiperDefaultValue.dragElastic,
    dragTransition = customSwiperDefaultValue.dragTransition,
    ...otherConfig
  },
  withAnimation = true,
  containerStyle,
  onSwipedLeft,
  onSwipedRight,
  offset: { left, right },
  withoutDragAndAnimations = false,
  withoutDragAndAnimationsSwipeCallback = () => {}
}: ICustomSwiperProps) => {
  const { leftMoveAnimation, initialAnimation, rightMoveAnimation, appearAnimation } = animations;
  const [dragTransitionDirection, setDratTransitionDirection] = useStateWithCallbackLazy(dragPosition);
  // eslint-disable-next-line
  const [_, setSelectedIndex] = useStateWithCallbackLazy(null);

  const controls = useAnimation();

  const onRightMove = useCallback(async () => {
    setSelectedIndex(index, () => {
      if (withAnimation) {
        return setDratTransitionDirection(false, async () => {
          controls.start(rightMoveAnimation);

          await delay(800);
          return setDratTransitionDirection(dragPosition, () => {
            onSwipedRight(itemList[index]);
          });
        });
      }
      return onSwipedRight(itemList[index]);
    });
  }, [
    setSelectedIndex,
    index,
    setDratTransitionDirection,
    dragPosition,
    itemList,
    onSwipedRight,
    rightMoveAnimation,
    withAnimation,
    controls
  ]);

  const onLeftMove = useCallback(async () => {
    setSelectedIndex(index, () => {
      if (withAnimation) {
        return setDratTransitionDirection(false, async () => {
          controls.start(leftMoveAnimation);

          await delay(800);
          return setDratTransitionDirection(dragPosition, () => {
            onSwipedLeft(itemList[index]);
          });
        });
      }
      return onSwipedLeft(itemList[index]);
    });
  }, [
    setSelectedIndex,
    index,
    setDratTransitionDirection,
    dragPosition,
    itemList,
    onSwipedLeft,
    leftMoveAnimation,
    withAnimation,
    controls
  ]);

  useEffect(() => {
    if (useRef) {
      // eslint-disable-next-line no-param-reassign
      useRef.current = {
        onLeftMove,
        onRightMove
      };
    }
  }, [useRef, index, onRightMove, onLeftMove]);

  useEffect(() => {
    if (withAnimation) {
      controls.start(appearAnimation);
    }
  }, [index, withAnimation, controls, appearAnimation]);

  const initial = useMemo(() => (withAnimation ? initialAnimation : {}), [withAnimation, initialAnimation]);

  const handlers = useSwipeable({
    onSwiped: eventData => withoutDragAndAnimationsSwipeCallback(eventData.dir),
    ...swipeableConfig
  });

  if (withoutDragAndAnimations) {
    return (
      <div {...handlers} style={containerStyle}>
        {itemList[index] && renderCard(itemList[index], index)}
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      {itemList[index] && (
        <motion.div
          style={componentStyle}
          whileDrag={whileDrag}
          key={itemList[index].id}
          dragTransition={dragTransition}
          dragElastic={dragElastic}
          drag={dragTransitionDirection}
          dragDirectionLock
          dragPropagation
          initial={initial}
          {...otherConfig}
          animate={controls}
          dragConstraints={dragConstraints}
          onDrag={(event, info) => {
            if (info.offset.x > right) {
              onRightMove();
            }
            if (info.offset.x < left) onLeftMove();
          }}
        >
          {renderCard(itemList[index], index)}
        </motion.div>
      )}
    </div>
  );
};

export default React.memo(CustomSwiper);

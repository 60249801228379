const dragTransition = { bounceStiffness: 600, bounceDamping: 100 };
const dragElastic = 0.5;
const initialAnimation = { opacity: 0, x: 0, y: 250 };
const dragConstraints = { top: 0, right: 0, bottom: 0, left: 0 };
const rightMoveAnimation = {
  opacity: 0,
  x: 400,
  y: 60,
  rotate: 25,
  transition: { duration: 0.8 }
};
const leftMoveAnimation = {
  opacity: 0,
  x: -400,
  y: 60,
  rotate: -25,
  transition: { duration: 0.8 }
};

const appearAnimation = {
  opacity: 1,
  x: 0,
  y: 0,
  transition: { duration: 0.3 }
};

export const customSwiperDefaultValue = {
  dragTransition,
  dragElastic,
  animation: {
    initialAnimation,
    rightMoveAnimation,
    leftMoveAnimation,
    appearAnimation
  },
  dragConstraints
};

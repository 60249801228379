import React from 'react';
import cx from 'classnames';
import { IPlaceholderBoxProps } from './PlaceholderBox.types';
import { ReactComponent as Svg } from '../../img/img-placeholder.svg';
import styles from './PlaceholderBox.module.css';
import { isSSR } from '../../utils/utils';

const PlaceholderBox: React.FC<IPlaceholderBoxProps> = ({
  show,
  children,
  className,
  width,
  height,
  backgroundColor,
  icon = isSSR() ? null : <Svg />
}) => (
  <>
    {show ? (
      <div className={cx(styles.root, className)} style={{ height, width, backgroundColor }}>
        {icon}
      </div>
    ) : (
      children
    )}
  </>
);

export default PlaceholderBox;

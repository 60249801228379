import React, { useMemo } from 'react';
import { ICircleLightProps } from './CircleLight.types';
import { ReactComponent as TrafficLightSVG } from '../../../../../img/game/traffic-light-square.svg';
import { getUniqId } from '../../../../../utils/utils';
import styles from './CircleLight.module.css';

const CircleLight: React.FC<ICircleLightProps> = ({
  className = styles.circleLight,
  isOn,
  color,
  borderColor = '#000'
}) => {
  const uniqId = useMemo(() => getUniqId(), []);

  const styleCode = useMemo(
    () => `#${uniqId} rect { fill: ${borderColor} } #${uniqId} circle { fill: ${isOn ? color : 'transparent'} } `,
    [uniqId, isOn, color, borderColor]
  );

  return (
    <>
      <style>{styleCode}</style>
      <TrafficLightSVG id={uniqId} className={className} />
    </>
  );
};

export default React.memo(CircleLight);

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import uuidv4 from 'uuid';
import Cookies from 'js-cookie';
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  SET_IS_LOGGED,
  SET_PASSWORD,
  LOGIN_WITH_TOKEN_FAILURE,
  LOGIN_WITH_TOKEN_REQUEST,
  RECOVERY_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_FAILURE,
  RECOVERY_PASSWORD_SUCCESS
} from '../actions/login.action';
import { login, logout, loginWithToken, recoveryPasswordByEmail } from './login.api';
import { firebaseApp } from '../config/firebase.config';
import { CLEAR_USER, SET_DEFAULT_USER_PROFILE, UPDATE_CURRENT_CATEGORY } from '../actions/user.action';
import { GET_TOKEN_REQUEST, SET_DEFAULT_AUTH, SET_DEVICE_UID } from '../actions/auth.action';
import { CLEAR_EXAM } from '../actions/exam.action';
import { CLEAR_ANSWER } from '../actions/answer.action';
import {
  DEMO_CATEGORY,
  DEVICE_UID,
  FB_TOKEN,
  LAST_OPEN_EXAM_ID,
  TEACHER_ID,
  TOKEN,
  USER_EMAIL,
  USER_PASSWORD
} from '../constants/storageKeys';
import { ILoginWithTokenSuccessResponse } from './login.types';
import { COOKIES_EXPIRES } from '../constants';
import { ecommerceRegistered } from '../utils/ecommerce/registered';

function clearUserCookies() {
  Cookies.remove(USER_EMAIL);
  Cookies.remove(USER_PASSWORD);
}

function* logoutSaga() {
  try {
    yield call(logout);
    yield put(LOGOUT_SUCCESS());
    yield put(SET_DEFAULT_USER_PROFILE());
    yield put(GET_TOKEN_REQUEST());
    // set preselect category to default
    yield put(UPDATE_CURRENT_CATEGORY('B'));
    Cookies.remove(DEMO_CATEGORY);
  } catch (error) {
    yield put(LOGOUT_FAILURE(error));
  }
}

function* resetPrevLoginState() {
  yield put(SET_DEFAULT_AUTH());
  yield firebaseApp.auth().signOut();
}

function* handleSuccessLoginResponse(response) {
  const {
    payload: { token, firebaseToken, password }
  }: ILoginWithTokenSuccessResponse = response;
  yield call(resetPrevLoginState);

  yield put(LOGIN_SUCCESS(token));

  if (password) {
    yield put(SET_PASSWORD(password));
  }

  localStorage.setItem(TOKEN, token);
  localStorage.setItem(FB_TOKEN, firebaseToken);
  window.localStorage.removeItem(TEACHER_ID);

  yield put(SET_DEVICE_UID(uuidv4()));
  yield put(GET_TOKEN_REQUEST());
}

function* authorize({ payload }) {
  try {
    const { email, password, saveCookies } = payload;
    const response = yield call(login, email, password);

    if (response.status !== 200) {
      yield put(LOGIN_FAILURE(response.payload.message));
    } else {
      // clear preselect category
      yield put(UPDATE_CURRENT_CATEGORY(''));
      Cookies.remove(DEMO_CATEGORY);

      if (saveCookies) {
        Cookies.set(USER_EMAIL, email, { expires: COOKIES_EXPIRES });
        Cookies.set(USER_PASSWORD, password, { expires: COOKIES_EXPIRES });
        Cookies.remove(LAST_OPEN_EXAM_ID);
      } else {
        clearUserCookies();
      }
      yield call(handleSuccessLoginResponse, response);
    }
  } catch (error) {
    yield put(LOGIN_FAILURE(error));
  }
}

function* signInWithToken({ payload }) {
  try {
    const response = yield call(loginWithToken, payload.registrationToken);

    if (response.status !== 200) {
      yield put(LOGIN_WITH_TOKEN_FAILURE(response.payload.message));
    } else {
      ecommerceRegistered(payload.registrationToken);
      yield call(handleSuccessLoginResponse, response);
    }
  } catch (e) {
    yield put(LOGIN_WITH_TOKEN_FAILURE(e));
  }
}

function* clearLSToken(action) {
  if (!action.payload || !action.payload.isLogged) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(FB_TOKEN);
    localStorage.removeItem(DEVICE_UID);
    yield put(CLEAR_EXAM());
    yield put(CLEAR_ANSWER());
    yield put(CLEAR_USER());
    yield firebaseApp.auth().signOut();
  }
}

function* onLogin() {
  yield put(CLEAR_EXAM());
  yield put(CLEAR_ANSWER());
}

function* recoveryPassword({ payload }) {
  try {
    const response = yield call(recoveryPasswordByEmail, payload);

    if (response.status !== 200) {
      yield put(RECOVERY_PASSWORD_FAILURE(response.payload.message));
    } else {
      yield put(RECOVERY_PASSWORD_SUCCESS());
    }
  } catch (e) {
    yield put(RECOVERY_PASSWORD_FAILURE(e));
  }
}

function* loginFlow() {
  yield takeEvery(LOGIN_REQUEST, authorize);
  yield takeEvery(RECOVERY_PASSWORD_REQUEST, recoveryPassword);
  yield takeEvery(LOGIN_SUCCESS, onLogin);
  yield takeEvery(LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(SET_IS_LOGGED, clearLSToken);
  yield takeEvery(LOGOUT_SUCCESS, clearLSToken);
  yield takeLatest(LOGIN_WITH_TOKEN_REQUEST, signInWithToken);
}

export default loginFlow;

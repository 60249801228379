import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import styles from './ShopLinkSection.module.css';

const ShopLinkSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <Card className={styles.offerCard}>
        <Card.Body>
          <Card.Title>{t('offersPageNew.shopLinkSection.giveAccess.title')}</Card.Title>
          <Card.Text>{t('offersPageNew.shopLinkSection.giveAccess.subtitle')}</Card.Text>
        </Card.Body>
        <Card.Footer className={styles.cardFooterSingle}>
          <Card.Link
            className={styles.offerFooterLink}
            rel="noopener noreferrer"
            target="_blank"
            href={t('offersPageNew.shopLinkSection.offersLink')}
          >
            {t('offersPageNew.shopLinkSection.giveAccess.button')}
          </Card.Link>
        </Card.Footer>
      </Card>
    </section>
  );
};

export default ShopLinkSection;

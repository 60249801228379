import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { P3 } from '../../ui-components/P3/P3';
import Image from '../../ui-components/Image/Image';
import { selectLanguage } from '../../reducer/user.selectors';
import styles from './EbikePage.module.css';

const getImgByLocale = locale => {
  const prefix = '/content/ebike/';

  switch (locale) {
    case 'de':
    case 'fr':
    case 'it':
    case 'en':
      return `${prefix}anhalteweg_${locale}.jpg`;

    default:
      return `${prefix}anhalteweg_de.jpg`;
  }
};

const EbikeSafeTravelPage: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);

  return (
    <div className={styles.ebikeTextList}>
      <h4 className={styles.ebikeTextTitle}>{t('ebikeSafeTravel.title')}</h4>
      <P3>{t('ebikeSafeTravel.text1')}</P3>
      <p className="h8">{t('ebikeSafeTravel.subtitle')}</p>
      <P3>{t('ebikeSafeTravel.text2')}</P3>
      <P3>{t('ebikeSafeTravel.text3')}</P3>
      <div className={styles.ebikeImgBox}>
        <Image src={getImgByLocale(currentLanguage)} alt="theory24 e-bike" />
      </div>
    </div>
  );
};

export default EbikeSafeTravelPage;

import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { RiGridFill } from 'react-icons/ri';
import { BsListUl } from 'react-icons/bs';
import { IViewTypeButtonProps, ViewTypeButtonType } from './ViewTypeButton.types';
import styles from './ViewTypeButton.module.css';
// <BsListUl />
export const ViewTypeButton: React.FC<IViewTypeButtonProps> = ({
  type = ViewTypeButtonType.List,
  setNewTypeCb,
  containerClass = ''
}) => {
  const [viewType, setViewType] = useState(type);

  const buttonClass = cx(styles.buttonContainer, containerClass);

  const toggleViewType = useCallback(() => {
    const newViewType = viewType === ViewTypeButtonType.List ? ViewTypeButtonType.Grid : ViewTypeButtonType.List;
    setViewType(newViewType);
    setNewTypeCb(newViewType);
  }, [viewType, setNewTypeCb]);

  return (
    <button type="button" onClick={toggleViewType} className={buttonClass}>
      {viewType === ViewTypeButtonType.List ? (
        <RiGridFill size={30} color="rgba(0, 128, 201, 0.8)" />
      ) : (
        <BsListUl size={30} color="rgba(0, 128, 201, 0.8)" />
      )}
    </button>
  );
};

export default ViewTypeButton;

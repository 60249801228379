import ReactGA from 'react-ga';
import { SELECTED_OFFER_KEY } from '../../constants/storageKeys';
import { Offer } from '../../reducer/offer.types';
import { isSSR } from '../utils';

const DEFAULT_OFFER: Partial<Offer> = {
  id: -1,
  cost: 'voucher',
  title: 'voucher',
  days: -1,
  description: 'voucher',
  total: 0
};

export function ecommerceRegistered(registrationToken: string) {
  if (process.env.REACT_APP_GOOGLE_GA_KEY) {
    let offer: Partial<Offer> = DEFAULT_OFFER;

    try {
      const offerData = isSSR() ? false : localStorage.getItem(SELECTED_OFFER_KEY);

      if (offerData) {
        offer = JSON.parse(offerData);
      }
    } catch (e) {
      // May problem if user change offer manually
    }

    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: registrationToken,
      name: offer.cost, // '365'
      sku: offer.id, // '1
      price: offer.total, // '29.00',
      category: 'plan',
      quantity: '1'
    });
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: registrationToken,
      revenue: offer.total // '29.00'
    });
    ReactGA.plugin.execute('ecommerce', 'send', '');
    ReactGA.plugin.execute('ecommerce', 'clear', '');
  }
}

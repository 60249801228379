import ExamCardPage from '../pages/ExamCardPage/ExamCardPage';
import ExamResultPage from '../pages/ExamResultPage/ExamResultPage';
import ExamPage from '../pages/ExamPage/ExamPage';
import ExamHistoryPage from '../pages/ExamHistoryPage/ExamHistoryPage';
import TheoryPage from '../pages/TheoryPage/TheoryPage';
import ImprintPage from '../pages/ImprintPage/ImprintPage';
import TermPage from '../pages/TermPage/TermPage';
import AsaPage from '../pages/AsaPage/AsaPage';
import QuestionCardPage from '../pages/QuestionCardPage/QuestionCardPage';
import QuestionPage from '../pages/QuestionPage/QuestionPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import DrivingSchoolPage from '../pages/DrivingSchoolPage/DrivingSchoolPage';
import VipCodePage from '../pages/VipCodePage/VipCodePage';
import CockpitLoggedPage from '../pages/CockpitLoggedPage/CockpitLoggedPage';
import DrivingInstructor from '../pages/DrivingInstructorPage/DrivingInstructor';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import PaymentRedirectPage from '../pages/PaymentRedirectPage/PaymentRedirectPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AnleitungPage from '../pages/AnleitungPage/AnleitungPage';
import { RoutesTypes } from './routes.types';
import NotFoundContainer from '../containers/NotFoundСontainer/NotFoundContainer';
import AboutPageContainer from '../containers/AboutPageContainer/AboutPageContainer';
import EbikeContainer from '../containers/EbikeContainer/EbikeContainer';
import TheoryBookByTopicsPage from '../pages/TheoryBookByTopicsPage/TheoryBookByTopicsPage';
import TheoryGlossaryPage from '../pages/TheoryGlossaryPage/TheoryGlossaryPage';
// import OffersPage from '../pages/OffersPage/OffersPage';
import InvoicePage from '../pages/InvoicePage/InvoicePage';
import DrivingSchoolInfoPage from '../pages/DrivingSchoolInfoPage/DrivingSchoolInfoPage';
import CockpitNotLoggedPage from '../pages/CockpitNotLoggedPage/CockpitNotLoggedPage';
import MainPage from '../pages/MainPage/MainPage';
import Teacher3dPageContainer from '../containers/Teacher3dPageContainer/Teacher3dPageContainer';
import ExtendOffersPage from '../pages/ExtendOffersPage/ExtendOffersPage';
import ConfirmedPlanExtensionPage from '../pages/ConfirmedPlanExtensionPage/ConfirmedPlanExtensionPage';
import TheoryTestPage from '../pages/TheoryTestPage/TheoryTestPage';
import AutoTestTheoryPage from '../pages/AutoTestTheoryPage/AutoTestTheoryPage';
import LearnTheoryExam from '../pages/LearnTheoryExam/LearnTheoryExam';
import MopedTestPage from '../pages/MopedTestPage/MopedTestPage';
import RollerExamPage from '../pages/RollerExamPage/RollerExamPage';
import AutoTheorieLernenPage from '../pages/SEO/AuthTheorieLernenPage/AutoTheorieLernenPage';
import AutoTheoriepruefungLernenPage from '../pages/SEO/AutoTheoriepruefungLernenPage/AutoTheoriepruefungLernenPage';
import TheoretischeAutopruefungPage from '../pages/SEO/TheoretischeAutopruefungPage/TheoretischeAutopruefungPage';
import TheoretischeFahrpruefungPage from '../pages/SEO/TheoretischeFahrpruefungPage/TheoretischeFahrpruefungPage';
import AutoTheoriepruefungPage from '../pages/SEO/AutoTheoriepruefungPage/AutoTheoriepruefungPage';
import TheoriepruefungFuehrerscheinPage from '../pages/SEO/TheoriepruefungFuehrerscheinPage/TheoriepruefungFuehrerscheinPage';
import GameModePage from '../pages/GameModePage/GameModePage';
import GamePage from '../pages/GamePage/GamePage';
import GameResultPage from '../pages/GameResultPage/GameResultPage';
import GameScorePage from '../pages/GameScorePage/GameScorePage';
import NewOffersPage from '../pages/NewOffersPage/NewOffersPage';
import TCSPage from '../pages/TCSPage/TCSPage';

export const routes: RoutesTypes[] = [
  {
    path: '/',
    exact: true,
    component: MainPage,
    name: 'MainPage',
    ads: {
      size: {
        mobile: [300, 250],
        desktop: [1440, 250]
      },
      account: 175925,
      mobile: { en: [405986, 405987], de: [405976, 405977], it: [405982, 405983], fr: [405979, 405980] },
      desktop: { en: [405985], de: [405975], it: [405981], fr: [405978] }
    }
  },
  {
    path: '/tcs',
    exact: true,
    component: TCSPage,
    name: 'TCSPage'
  },
  {
    path: '/exam/:examRoute/:id?',
    renderAfterLoad: true,
    component: ExamCardPage,
    name: 'ExamCardPage'
  },
  {
    path: '/about',
    component: AboutPageContainer,
    name: 'AboutPage'
  },
  {
    path: '/result/:examRoute',
    renderAfterLoad: true,
    component: ExamResultPage,
    name: 'ExamResultPage'
  },
  {
    path: '/exam',
    renderAfterLoad: true,
    hideExpired: true,
    component: ExamPage,
    name: 'ExamPage'
  },
  {
    path: '/exam-history',
    renderAfterLoad: true,
    hideExpired: true,
    component: ExamHistoryPage,
    name: 'ExamHistoryPage'
  },
  {
    path: '/ebike',
    component: EbikeContainer,
    name: 'EbikePage'
  },
  {
    path: '/theory/topics/:page',
    demoPathName: '/',
    renderAfterLoad: true,
    component: TheoryBookByTopicsPage,
    name: 'TheoryBookByTopicsPage'
  },
  {
    path: '/theory/glossary/:page',
    renderAfterLoad: true,
    demoPathName: '/',
    component: TheoryGlossaryPage,
    name: 'TheoryGlossaryPage'
  },
  {
    path: '/theory',
    hideExpired: true,
    component: TheoryPage,
    name: 'TheoryPage'
  },
  {
    path: '/imprint',
    component: ImprintPage,
    name: 'ImprintPage'
  },
  {
    path: '/term',
    component: TermPage,
    name: 'TermPage'
  },
  {
    path: '/asa',
    component: AsaPage,
    name: 'AsaPage'
  },
  { path: '/game-mode', component: GameModePage, name: 'GameMode', onlyForLogin: true, hideExpired: true },
  { path: '/game/:index?', component: GamePage, name: 'Game', onlyForLogin: true, hideExpired: true },
  { path: '/game-result', component: GameResultPage, name: 'GameResult', onlyForLogin: true, hideExpired: true },
  { path: '/game-score', component: GameScorePage, name: 'GameScore', onlyForLogin: true, hideExpired: true },
  {
    path: '/questions/:category/:topic/:id?',
    renderAfterLoad: true,
    hideExpired: true,
    component: QuestionCardPage,
    name: 'QuestionCardPage'
  },
  {
    path: '/questions',
    renderAfterLoad: true,
    hideExpired: true,
    component: QuestionPage,
    name: 'QuestionPage'
  },
  {
    path: '/contacts',
    component: ContactPage,
    name: 'ContactPage'
  },
  {
    path: '/3dteacher',
    renderAfterLoad: true,
    component: Teacher3dPageContainer,
    name: 'Teacher3dPage'
  },
  {
    path: '/vipcode',
    component: VipCodePage,
    name: 'VipCodePage'
  },
  {
    path: '/vip',
    component: VipCodePage,
    name: 'VipCodePage'
  },
  {
    path: '/school',
    exact: true,
    component: DrivingSchoolPage,
    name: 'DrivingSchoolPage'
  },
  {
    path: '/school/:id',
    exact: true,
    component: DrivingSchoolInfoPage,
    name: 'DrivingSchoolInfoPage'
  },
  {
    path: '/mycockpitdemo',
    renderAfterLoad: true,
    component: CockpitNotLoggedPage,
    name: 'CockpitNotLoggedPage'
  },
  {
    path: '/mycockpit',
    renderAfterLoad: true,
    demoPathName: '/mycockpitdemo',
    component: CockpitLoggedPage,
    name: 'CockpitLoggedPage'
  },
  {
    path: '/instructor',
    component: DrivingInstructor,
    name: 'DrivingInstructor'
  },
  // {
  //   path: '/offers',
  //   component: OffersPage,
  //   name: 'OffersPage'
  // },
  {
    path: '/offers',
    component: NewOffersPage,
    name: 'OffersPage'
  },
  {
    path: '/invoice/:studentid/:invoiceid',
    component: InvoicePage,
    name: 'InvoicePage'
  },
  {
    path: '/extend-offers',
    demoPathName: '/offers',
    component: ExtendOffersPage,
    name: 'ExtendOffersPage'
  },
  {
    path: '/register',
    component: RegisterPage,
    name: 'RegisterPage'
  },
  {
    path: '/plan-extension',
    demoPathName: '/register',
    component: RegisterPage,
    name: 'PlanExtensionPage'
  },
  {
    path: '/payment',
    component: PaymentRedirectPage,
    name: 'PaymentRedirectPage'
  },
  {
    path: '/confirmed-plan-extension',
    demoPathName: '/register',
    component: ConfirmedPlanExtensionPage,
    name: 'ConfirmedPlanExtensionPage'
  },
  {
    path: '/faq',
    component: FaqPage,
    name: 'FaqPage'
  },
  {
    path: '/anleitung',
    component: AnleitungPage,
    name: 'AnleitungPage'
  },
  {
    path: '/theoriepruefung',
    component: TheoryTestPage,
    name: 'theoryTestPage'
  },
  {
    path: '/autopruefung-theorie',
    component: AutoTestTheoryPage,
    name: 'autoTestTheoryPage'
  },
  {
    path: '/theoriepruefung-lernen',
    component: LearnTheoryExam,
    name: 'learnTheoryExam'
  },
  {
    path: '/mofa-pruefung',
    component: MopedTestPage,
    name: 'mopedTestPage'
  },
  {
    path: '/rollerpruefung',
    component: RollerExamPage,
    name: 'rollerExamPage'
  },
  {
    path: '/auto-theorie-lernen',
    component: AutoTheorieLernenPage,
    name: 'autoTheorieLernenPage'
  },
  {
    path: '/auto-theoriepruefung-lernen',
    component: AutoTheoriepruefungLernenPage,
    name: 'autoTheoriepruefungLernenPage'
  },
  {
    path: '/theoretische-autopruefung',
    component: TheoretischeAutopruefungPage,
    name: 'theoretischeAutopruefungPage'
  },
  {
    path: '/theoretische-fahrpruefung',
    component: TheoretischeFahrpruefungPage,
    name: 'theoretischeFahrpruefungPage'
  },
  {
    path: '/auto-theoriepruefung',
    component: AutoTheoriepruefungPage,
    name: 'autoTheoriepruefungPage'
  },
  {
    path: '/theoriepruefung-fuehrerschein',
    component: TheoriepruefungFuehrerscheinPage,
    name: 'theoriepruefungFuehrerscheinPage'
  },
  {
    path: '*',
    component: NotFoundContainer,
    name: 'NotFoundPage'
  }
];

import alasql from 'alasql';
import { db } from '../config/firebase.config';

export const getAnswerList = async (userId: string, category: string) => {
  let answerList = {};

  if (userId) {
    const answers = await db
      .collection('user')
      .doc(userId)
      .collection('question')
      .doc(category.toString())
      .collection('answer')
      .get();
    answers.docs.forEach(answer => {
      const a = answer.data();

      answerList = {
        ...answerList,
        [a.qTopic]: answerList[a.qTopic]
          ? {
              ...answerList[a.qTopic],
              [answer.id]: a
            }
          : {
              [answer.id]: a
            }
      };
    });
  } else {
    const res = await alasql('SELECT qNr, answer, qTopic FROM answer WHERE qCat = ? AND uid = ?', [category, userId]);

    res.forEach(a => {
      answerList = {
        ...answerList,
        [a.qTopic]: answerList[a.qTopic]
          ? {
              ...answerList[a.qTopic],
              [a.qNr]: a.answer
            }
          : {
              [a.qNr]: a.answer
            }
      };
    });
  }

  return answerList;
};

export const clearAnswerByTopic = async (userId: string, category: string, topic: string) => {
  if (userId) {
    if (topic === 'all' || topic === 'demo') {
      const allAnswer = await db
        .collection('user')
        .doc(userId)
        .collection('question')
        .doc(category.toString())
        .collection('answer')
        .get();
      allAnswer.docs.forEach(answerByTopic => {
        answerByTopic.ref.delete();
      });
    } else {
      const allAnswer = await db
        .collection('user')
        .doc(userId)
        .collection('question')
        .doc(category.toString())
        .collection('answer')
        .where('qTopic', '==', parseInt(topic, 10))
        .get();
      allAnswer.docs.forEach(answerByTopic => {
        answerByTopic.ref.delete();
      });
    }
  } else if (topic === 'all' || topic === 'demo') {
    await alasql('DELETE FROM answer WHERE qCat = ? AND uid = ?', [category, userId]);
  } else {
    await alasql('DELETE FROM answer WHERE qTopic = ? AND qCat = ? AND uid = ?', [
      parseInt(topic, 10),
      category,
      userId
    ]);
  }
};

export const clearAnswersFromMarkedQuestions = async (userId: string, category: string, markedQuestions: number[]) => {
  if (userId) {
    const allAnswers = await db
      .collection('user')
      .doc(userId)
      .collection('question')
      .doc(category.toString())
      .collection('answer')
      .get();
    allAnswers.docs.forEach(answer => {
      if (markedQuestions.includes(+answer.id)) {
        answer.ref.delete();
      }
    });
  }
};

export const clearAnswerByAnswerId = async (userId: string, category: string, questionId: string) => {
  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('question')
      .doc(category.toString())
      .collection('answer')
      .doc(questionId.toString())
      .delete();
  } else {
    await alasql('DELETE FROM answer WHERE qNr = ? AND uid = ?', [questionId, userId]);
  }
};

export const saveAnswerByTopic = async fbRequestParam => {
  const { userId, categoryForQuery, action } = fbRequestParam;

  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('question')
      .doc(categoryForQuery.toString())
      .collection('answer')
      .doc(action.payload.questionId.toString())
      .set({
        ...action.payload.answer,
        qTopic: action.payload.qTopic
      });
  } else {
    const res = await alasql('SELECT * FROM answer WHERE qNr = ? AND qCat = ? AND uid = ?', [
      action.payload.questionId,
      categoryForQuery,
      userId
    ]);

    if (res.length) {
      await alasql('UPDATE answer SET answer = ? WHERE id = ?', [action.payload.answer, res[0].id]);
    } else {
      await alasql('INSERT INTO answer VALUES ?', [
        {
          qNr: action.payload.questionId,
          qCat: categoryForQuery,
          qTopic: action.payload.qTopic,
          uid: userId,
          answer: action.payload.answer
        }
      ]);
    }
  }
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import { getTranslationList, imageAbsolutePath } from '../../../../utils/utils';
import TickListItem from '../../../../ui-components/TickListItem/TickListItem';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import { selectLanguage } from '../../../../reducer/user.selectors';
import MoreInfoOfferLink from '../../../../components/MoreInfoOfferLink/MoreInfoOfferLink';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import styles from './FunctionsSection.module.css';

const imageDe: string = imageAbsolutePath('homePage/function-section-de.png');
const imageEn: string = imageAbsolutePath('homePage/function-section-en.png');
const imageFr: string = imageAbsolutePath('homePage/function-section-fr.png');
const imageIt: string = imageAbsolutePath('homePage/function-section-it.png');

const IMAGE_LIST = {
  de: { img: imageDe },
  fr: { img: imageFr },
  it: { img: imageIt },
  en: { img: imageEn }
};

const FunctionsSection: React.FC = () => {
  const { t } = useTranslation();

  const currentLanguage = useSelector(selectLanguage);

  const getFunctionList = useMemo(() => {
    const translationObj = getTranslationList(currentLanguage, `homePageNew.functionSection.list`);
    const translationList: string[] = Object.values(translationObj);
    return Object.values(translationList).map(text => text);
  }, [currentLanguage]);

  const renderItemList = useMemo(
    () =>
      getFunctionList.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TickListItem key={index} text={item} />
      )),
    [getFunctionList]
  );

  return (
    <section>
      <TitleH3 center text={t('homePageNew.functionSection.title')} />

      <div className={styles.container}>
        <ul className={styles.listWrapper}>{renderItemList}</ul>
        <LocalesImage alt={t('homePageNew.functionSection.imageAlt')} localesPath={IMAGE_LIST} />
        {/* <motion.img variants={titleAnimation} src={image} alt={t('homePageNew.functionSection.imageAlt')} /> */}
      </div>

      <div className={styles.buttonWrapper}>
        <LinkWrapper className="btn btn-primary" to="/offers" toanchor="plans">
          {t('homePageNew.btn.buyNow')}
        </LinkWrapper>
      </div>
      <MoreInfoOfferLink to="/about" />
    </section>
  );
};

export default FunctionsSection;

import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import useTrafficLight from './hook/useTrafficLight';
import { TrafficLight } from '../TrafficLight/TrafficLight';
import { ITrafficLightWrapperProps } from './TrafficLightWrapper.types';
import styles from './TrafficLightWrapper.module.css';

const TrafficLightWrapper: React.FC<ITrafficLightWrapperProps> = ({ ...rest }) => {
  const {
    states: { redLightState, greenLightState, yellowLightState },
    startCountDown
  } = useTrafficLight({
    redLightInitState: false,
    yellowLightInitState: false,
    greenLightInitState: false
  });

  useEffect(() => {
    (async () => {
      // rest.onHide();
      await startCountDown();
    })();
  }, [startCountDown]);

  useEffect(() => {
    if (yellowLightState) {
      const timer = setTimeout(() => {
        rest.onHide();
      }, 2000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [yellowLightState, rest]);

  return (
    <motion.div
      initial={{ y: -250, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className={styles.container}
      whileTap={{ scale: 0.8, rotate: 360 }}
      transition={{ duration: 0.5 }}
      drag
      dragConstraints={{ left: 5, right: 5, top: 5, bottom: 5 }}
      dragTransition={{ min: 0, max: 10, bounceStiffness: 100, bounceDamping: 8 }}
    >
      <TrafficLight>
        <TrafficLight.CircleLight color="#e20000" isOn={redLightState} />
        <TrafficLight.CircleLight color="#f4df01" isOn={yellowLightState} />
        <TrafficLight.CircleLight color="#15cf74" isOn={greenLightState} />
      </TrafficLight>
    </motion.div>
  );
};

export default React.memo(TrafficLightWrapper);

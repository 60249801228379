import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ISelectGameModeButtonProps } from './SelectGameModeButton.types';
import styles from './SelectGameModeButton.module.css';

const svgVariants = {
  selected: { x: [-5, 0, 5, 0], rotate: [-10, 0, 10, 0] },
  notSelected: { x: 0, rotate: 0 }
};

const buttonVariants = {
  selected: { scale: 1.2 },
  notSelected: { scale: 0.8 }
};

const SelectGameModeButton: React.FC<ISelectGameModeButtonProps> = ({
  isSelected,
  icon,
  text,
  className,
  countOfLives,
  ...rest
}: ISelectGameModeButtonProps) => {
  const { t } = useTranslation();

  const variantName = useMemo(
    () => (isSelected ? 'selected' : 'notSelected'),

    [isSelected]
  );

  const classes = `${className} ${styles.button} ${isSelected ? styles.selected : ''}`;
  return (
    <motion.div animate={variantName} variants={buttonVariants} className={styles.selectGameModeButtonContainer}>
      <span>{text}</span>
      <button type="button" className={classes} {...rest}>
        <motion.div
          animate={variantName}
          variants={svgVariants}
          transition={{ duration: 0.5, repeat: isSelected ? Infinity : 0 }}
        >
          {icon}
        </motion.div>
      </button>
      <div className={styles.textContainer}>
        <span>{`${countOfLives > 1 ? t('gameContainer.lives') : t('gameContainer.life')} ${countOfLives}`}</span>
      </div>
    </motion.div>
  );
};

export default React.memo(SelectGameModeButton);

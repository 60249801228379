import { createAction } from 'redux-actions';

export const GET_SCHOOL_LIST_REQUEST = createAction('GET_SCHOOL_LIST_REQUEST');
export const GET_SCHOOL_LIST_FAILURE = createAction('GET_SCHOOL_LIST_FAILURE');
export const GET_SCHOOL_LIST_SUCCESS = createAction('GET_SCHOOL_LIST_SUCCESS');

export const GET_SCHOOL_BY_ID_REQUEST = createAction('GET_SCHOOL_BY_ID_REQUEST');
export const GET_SCHOOL_BY_ID_FAILURE = createAction('GET_SCHOOL_BY_ID_FAILURE');
export const GET_SCHOOL_BY_ID_SUCCESS = createAction('GET_SCHOOL_BY_ID_SUCCESS');

export const GET_SCHOOL_COORDINATES_LIST_REQUEST = createAction('GET_SCHOOL_COORDINATES_LIST_REQUEST');
export const GET_SCHOOL_COORDINATES_LIST_FAILURE = createAction('GET_SCHOOL_COORDINATES_LIST_FAILURE');
export const GET_SCHOOL_COORDINATES_LIST_SUCCESS = createAction('GET_SCHOOL_COORDINATES_LIST_SUCCESS');

export const SET_SCHOOL_REQUEST = createAction('SET_SCHOOL_REQUEST');
// success and failure are handled by GET_USER_PROFILE_* actions

import React, { useCallback, useEffect, useState, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ISchoolCoordinates, SchoolState } from '../reducer/school.types';
import ContainerMap, { mapStyles } from '../components/Map/Map';
import SchoolInfoModalContainer from './SchoolInfoModalContainer/SchoolInfoModalContainer';
import { selectSchoolCoordinatesList, selectSchool } from '../reducer/scool.selectors';
import { PENDING, UNCALLED } from '../constants/store.constants';
import { GET_SCHOOL_COORDINATES_LIST_REQUEST } from '../actions/school.action';
import SchoolMarkers from '../components/SchoolMarkets/SchoolMarkers';
import PlaceholderBox from '../components/PlaceholderBox/PlaceholderBox';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBundary';
import { Loader } from '../ui-components/Loader/Loader';
import styles from './SchoolMarkersContainer.module.css';
import useDeviceSizes from '../hooks/useDeviceSizes';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../constants';

const MAP_OPTIONS = {
  streetViewControl: false,
  mapTypeControl: false,
  styles: mapStyles
};

const SchoolMarkersContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { width } = useDeviceSizes();
  const mapRef = useRef(null);
  const { schoolCoordinatesRequest, searchField, schoolListRequest, schoolList }: SchoolState = useSelector(
    selectSchool
  );
  const schoolCoordinatesList: ISchoolCoordinates[] = useSelector(selectSchoolCoordinatesList);
  const [selectedTeacherId, setSelectedTeacherId] = useState<number | null>(null);
  const [zoom, setZoom] = useState(DEFAULT_MAP_ZOOM);

  useEffect(() => {
    if (schoolCoordinatesRequest.status === UNCALLED) {
      dispatch(GET_SCHOOL_COORDINATES_LIST_REQUEST());
    }
  }, [schoolCoordinatesRequest, dispatch]);

  useEffect(() => {
    if (mapRef?.current) {
      if (searchField && schoolListRequest.status === 'success' && schoolList && schoolList.length > 0) {
        const { teacherLatitude, teacherLongitude } = schoolList[0];
        const newCenterObject = { lat: +teacherLatitude, lng: +teacherLongitude };
        // @ts-ignore*/
        mapRef!.current!.panTo(newCenterObject);
        setZoom(15);
      } else {
        // @ts-ignore*/
        mapRef!.current!.panTo(DEFAULT_MAP_CENTER);
        setZoom(DEFAULT_MAP_ZOOM);
      }
    }
  }, [searchField, schoolListRequest, schoolList, mapRef]);

  const handleCloseModal = useCallback(() => setSelectedTeacherId(null), [setSelectedTeacherId]);
  const handleMarketClick = useCallback((teacherId: number) => setSelectedTeacherId(teacherId), [setSelectedTeacherId]);

  return (
    <div className={styles.mapContent}>
      <div className={styles.container}>
        <ErrorBoundary fallback={<PlaceholderBox show className={styles.mapContainer} />}>
          <ContainerMap
            useRef={mapRef}
            zoom={zoom}
            defaultZoom={width <= 991 ? 7 : 8}
            options={MAP_OPTIONS}
            containerElement={<div className={styles.mapContainer} />}
          >
            <SchoolMarkers schoolCoordinatesList={schoolCoordinatesList} onClick={handleMarketClick} />
          </ContainerMap>
        </ErrorBoundary>
        <Loader show={schoolCoordinatesRequest.status === PENDING} inBlock />
      </div>
      <SchoolInfoModalContainer teacherId={selectedTeacherId} onHide={handleCloseModal} />
    </div>
  );
};

export default memo(SchoolMarkersContainer);

import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobileSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { selectLanguage } from '../../reducer/user.selectors';
import poster from '../../img/poster.svg';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { P3 } from '../../ui-components/P3/P3';
import styles from './EbikeDisplay.module.css';

const getMovieByLocale = locale => {
  const prefix = '/content/ebike/';

  switch (locale) {
    case 'de':
    case 'fr':
    case 'it':
      return `${prefix}video_ebike_${locale}.mp4`;

    default:
      return `${prefix}video_ebike_de.mp4`;
  }
};

const EbikeDisplay = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <PageTitle>{t('ebikeDisplay.title')}</PageTitle>
          <P3>{t('ebikeDisplay.subtitle1')}</P3>
          <P3>{t('ebikeDisplay.subtitle2')}</P3>
        </Col>
        <Col lg={{ span: 7, offset: 1 }}>
          <video
            className={styles.responsiveVideo}
            src={getMovieByLocale(currentLanguage)}
            muted
            poster={isMobileSafari ? poster : undefined}
            controls
          >
            <source src={getMovieByLocale(currentLanguage)} type="video" />
            <track kind="captions" />
          </video>
        </Col>
      </Row>
    </Container>
  );
};

export default EbikeDisplay;

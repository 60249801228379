import React, { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { motion } from 'framer-motion';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import { LoaderWrapper } from '../../../../ui-components/LoaderWrapper/LoaderWrapper';
import { IOfferItemProps } from './OfferItem.type';
import styles from './OfferItem.module.css';

const OfferItem: FC<IOfferItemProps> = forwardRef(
  (
    { text, price, icons, linkUrl, categoryText, children, isLoading = false }: IOfferItemProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} className={styles.container}>
        <div>
          <div className={styles.content}>
            <LoaderWrapper show={isLoading}>
              <div className={styles.topWrapper}>
                <div className={styles.iconWrapper}>{icons}</div>
                <p>{`${text} ${t('offersPageNew.offersSection.days')}`}</p>
                <p>{price}</p>
              </div>
            </LoaderWrapper>
            <p className={styles.categoryText}>{categoryText}</p>
          </div>

          {children}
        </div>
        <div className={styles.buttonWrapper}>
          <LinkWrapper className="btn btn-primary" to={linkUrl}>
            {t('offersPageNew.offersSection.buyNow')}
          </LinkWrapper>
          <div className={styles.wave} />
        </div>
      </div>
    );
  }
);

// export const MSaleItem = motion(SaleItem);

export default OfferItem;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import errorImage from '../../img/sad-kiano.png';
import { IErrorPageProps } from './ErrorPage.types';
import styles from './ErrorPage.module.css';

export const ErrorPage: React.FC<IErrorPageProps> = ({ errorMessage, errorInfo }: IErrorPageProps) => {
  const { t } = useTranslation();

  const onInfoClick = useCallback(() => {
    // eslint-disable-next-line no-alert
    alert(errorInfo);
  }, [errorInfo]);

  const onReloadClick = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Row>
        <Col className={styles.container}>
          <PageTitle>{t('errorBoundaryPage.unexpectedError')}</PageTitle>
          {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}

          <img src={errorImage} alt="error" />
          <div className={styles.buttonWrapper}>
            <Button variant="secondary" onClick={onReloadClick}>
              {t('errorBoundaryPage.buttons.reloadPage')}
            </Button>
            <Button variant="outline-secondary btnLabel" as={LinkWrapper} to="/">
              {t('errorBoundaryPage.buttons.openHomePage')}
            </Button>
            {errorInfo && (
              <Button variant="danger" onClick={onInfoClick}>
                {t('errorBoundaryPage.buttons.errorPath')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;

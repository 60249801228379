import React, { useMemo } from 'react';
import { IBodyProps } from './Body.types';

const Body: React.FC<IBodyProps> = ({ tableData, tableRow }) => {
  const renderTableMap = useMemo(() => {
    if (tableData) {
      return (tableData as any[]).map(tableRow);
    }
    return null;
  }, [tableData, tableRow]);

  return <tbody>{renderTableMap}</tbody>;
};

export default React.memo(Body);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../../../utils/utils';
import styles from './VipCodeSection.module.css';

const vipCard = imageAbsolutePath('vip-card.png');

const VipCodeSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.container}>
        <div>
          <LinkWrapper to="/vipcode">
            <h5>{t('homePageNew.vipCardSections.title')}</h5>
          </LinkWrapper>
          <LinkWrapper to="/vipcode">
            <h6>{t('homePageNew.vipCardSections.linkText')}</h6>
          </LinkWrapper>
        </div>
        <LinkWrapper to="/vipcode">
          <img src={vipCard} alt={t('homePageNew.vipCardSections.imgAlt')} />
        </LinkWrapper>
      </div>
    </section>
  );
};

export default VipCodeSection;

import React, { useCallback, useState } from 'react';
import { isMobileSafari } from 'react-device-detect';
import poster from '../../img/poster.svg';
import styles from './ZoomMultimedia.module.css';
import { IZoomMultimediaProps } from './ZoomMultimedia.types';
import MultimediaModal from '../../components/MultimediaModal/MultimediaModal';
import ZoomBtn from '../ZoomBtn/ZoomBtn';
import { getExamImageSrc } from '../../utils/prefetchMediaSources';
import ZoomLink from '../ZoomLink/ZoomLink';

const ZoomMultimedia: React.FC<IZoomMultimediaProps> = ({
  src,
  description,
  variant,
  video,
  autoplay = false
}: IZoomMultimediaProps) => {
  const [showImgWin, setShowImgWin] = useState(false);
  const imageSrc = getExamImageSrc(src);
  const handleHide = useCallback(() => {
    setShowImgWin(false);
  }, [setShowImgWin]);

  const handleShow = useCallback(() => {
    setShowImgWin(true);
  }, [setShowImgWin]);

  return video ? (
    <div className={styles.videoContainer}>
      <video
        className={styles.questionVideoContent}
        src={src}
        autoPlay={autoplay}
        controls
        muted
        poster={isMobileSafari ? poster : undefined}
      >
        <source src={src} type="video" />
        <track kind="captions" />
      </video>
      <ZoomBtn className={styles.zoomBtn} onClick={handleShow} />
      <MultimediaModal video src={src} alt={description} show={showImgWin} onHide={handleHide} />
    </div>
  ) : (
    <div className={styles[`imgContainer-${variant}`]}>
      <ZoomLink onClick={handleShow} />
      <img className={styles.questionImgContent} src={imageSrc} alt="" />
      <ZoomBtn className={styles.sidebarZoomBtn} onClick={handleShow} />
      <MultimediaModal src={imageSrc} alt={description} show={showImgWin} onHide={handleHide} />
    </div>
  );
};

export default ZoomMultimedia;

/* eslint-disable react/jsx-wrap-multilines,react/no-array-index-key */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import OfferItem from '../../components/OfferItem/OfferItem';
import { getTranslationList, imageAbsolutePath } from '../../../../utils/utils';
import TickListItem from '../../../../ui-components/TickListItem/TickListItem';
import { selectLanguage } from '../../../../reducer/user.selectors';
import { useGetOfferList } from '../../../../hooks/dataFetch/useGetOfferList';
import { IRootState } from '../../../../reducer';
import LocalesImage from '../../../../components/LocalesImage/LocalesImage';
import styles from './OffersSection.module.css';

const categoriesAB = imageAbsolutePath('B-A1-logo.png');
const categoriesMFG = imageAbsolutePath('MFG-logo.png');

const germany = imageAbsolutePath('lang/Germany.svg');
const france = imageAbsolutePath('lang/France.svg');
const italy = imageAbsolutePath('lang/Italy.svg');
const spain = imageAbsolutePath('lang/Spain.svg');
const uk = imageAbsolutePath('lang/UK.svg');
const turkey = imageAbsolutePath('lang/Turkey.svg');
const portugal = imageAbsolutePath('lang/Portugal.svg');
const croatia = imageAbsolutePath('lang/Croatia.svg');
const albania = imageAbsolutePath('lang/Albania.svg');

const LANGUAGE_ARRAY = [germany, france, italy, spain, uk, turkey, portugal, croatia, albania];

const examQuestionBannerDe: string = imageAbsolutePath('banners/years-logo_de.png');
const examQuestionBannerEn: string = imageAbsolutePath('banners/years-logo_en.png');
const examQuestionBannerFr: string = imageAbsolutePath('banners/years-logo_fr.png');
const examQuestionBannerIt: string = imageAbsolutePath('banners/years-logo_it.png');

const EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST = {
  de: { img: examQuestionBannerDe },
  fr: { img: examQuestionBannerFr },
  it: { img: examQuestionBannerIt },
  en: { img: examQuestionBannerEn }
};

const OffersSection: React.FC = () => {
  const { t } = useTranslation();

  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);

  const currentLanguage = useSelector(selectLanguage);

  const { offersList, isFetching } = useGetOfferList();

  const firstPlan = offersList.find(offer => offer.id === 11);
  const secondPlan = offersList.find(offer => offer.id === 12);

  /** create list from translation * */
  const getOfferList = useCallback(
    (pathName: string) => {
      const translationObj = getTranslationList(currentLanguage, `offersPageNew.offersSection.${pathName}`);
      const translationList: string[] = Object.values(translationObj);
      return Object.values(translationList).map(text => text);
    },
    [currentLanguage]
  );

  const renderList = useCallback(
    pathName => getOfferList(pathName).map((text: string, index) => <TickListItem key={index} withDote text={text} />),
    [getOfferList]
  );

  return (
    <section className={styles.container}>
      <LocalesImage
        className={styles.banner}
        alt={t('homePageNew.titleSection.images.logoAlt')}
        localesPath={EXAM_QUESTION_BANNERS_ALL_LANGUAGE_LIST}
      />
      <TitleH3 center text={t('offersPageNew.offersSection.title')} />
      <div id="plans" className={styles.offersItemWrapper}>
        <OfferItem
          categoryText={t('offersPageNew.offersSection.autoCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${firstPlan?.id}`}
          isLoading={isFetching}
          icons={
            <img
              className={styles.abIcons}
              src={categoriesAB}
              alt={t('homePageNew.titleSection.images.categoriesAlt')}
            />
          }
          text={firstPlan?.days.toString()}
          price={firstPlan?.cost}
        >
          <ul className={styles.listWrapper}>{renderList('card1')}</ul>
          <ul className={styles.langList}>
            {LANGUAGE_ARRAY.map((item, index) => (
              <LanguageItem key={index} image={item} />
            ))}
          </ul>
        </OfferItem>

        <OfferItem
          categoryText={t('offersPageNew.offersSection.tractorCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${secondPlan?.id}`}
          isLoading={isFetching}
          icons={<img src={categoriesMFG} alt={t('homePageNew.titleSection.images.categoriesAlt')} />}
          text={secondPlan?.days.toString()}
          price={secondPlan?.cost}
        >
          <ul className={styles.listWrapper}>{renderList('card2')}</ul>
          <ul className={styles.langList}>
            {LANGUAGE_ARRAY.slice(0, 3).map((item, index) => (
              <LanguageItem key={index} image={item} />
            ))}
          </ul>
        </OfferItem>
      </div>
    </section>
  );
};

const LanguageItem = ({ image }: { image: string }) => (
  <li>
    <img src={image} width={50} height={50} alt="lang" />
  </li>
);

export default OffersSection;

import React, { useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import Checkbox from '../../ui-components/Checkbox/Checkbox';
import styles from './QuestionView.module.css';
import ZoomMultimedia from '../../ui-components/ZoomMultimedia/ZoomMultimedia';
import { IQuestionProps } from './QuestionView.types';
import { ILangsAttributes } from '../../reducer/question.types';
import { getQuestionVideoSrc, isQuestionHasVideoSrc } from '../../utils/prefetchMediaSources';
import { IRootState } from '../../reducer';
import { usePrevious } from '../../hooks/usePrevious';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';

const QuestionView: React.FC<IQuestionProps> = ({
  lng,
  question,
  timeFinish,
  onCheckedAnswerChange,
  questionCount,
  questionId,
  defaultRoute,
  onQuestionChange,
  isExam,
  isCorrect,
  onQuestionCheck,
  selectedAnswers,
  handleExit,
  isFinishedExam
}: IQuestionProps) => {
  const disabledPrevBtn = questionId === 1;
  const disabledNextBtn = questionId === questionCount;
  const { t } = useTranslation();

  const { isLogged }: LoginState = useSelector(selectLogin);
  const isAnswered: boolean = typeof isCorrect === 'boolean';
  const showUiResult = isExam ? timeFinish : isAnswered;
  const disableQuestionChangeBtn: boolean = isAnswered || !selectedAnswers || !selectedAnswers.length;
  const questionDictionary: ILangsAttributes = question.langs[lng] || question.langs.de;

  /** Don't show video in exam mode, don't show video for demo * */
  const hasVideo: boolean = useMemo(() => !isExam && isLogged && question && isQuestionHasVideoSrc(question), [
    question,
    isExam,
    isLogged
  ]);
  const offlineMode: boolean = useSelector(({ auth }: IRootState) => auth.offlineMode);
  const videoSrc: string = useMemo(() => (hasVideo ? getQuestionVideoSrc(question, showUiResult) : ''), [
    hasVideo,
    question,
    showUiResult
  ]);
  const lang = question.langs[lng] || question.langs.de;

  const rememberPreviousAnswerStatus = usePrevious(showUiResult);
  const rememberPreviousQuestionId = usePrevious(questionId);

  /* AutoPlay video after user make answer */
  const videoAutoPlayAfterUserAnswer =
    showUiResult && hasVideo && !rememberPreviousAnswerStatus && rememberPreviousQuestionId === questionId;

  const handleQuestionCheck = useCallback(() => {
    if (onQuestionCheck) {
      onQuestionCheck();
    }
  }, [onQuestionCheck]);

  const handleNextQuestion = useCallback(() => {
    onQuestionChange(`${defaultRoute}/${questionId + 1}`);
  }, [onQuestionChange, questionId, defaultRoute]);

  const handlePrevQuestion = useCallback(() => {
    onQuestionChange(`${defaultRoute}/${questionId - 1}`);
  }, [onQuestionChange, questionId, defaultRoute]);

  const toPrevQuestion = useCallback(() => {
    if (!disabledPrevBtn) {
      onQuestionChange(`${defaultRoute}/${questionId - 1}`);
    }
  }, [disabledPrevBtn, onQuestionChange, defaultRoute, questionId]);

  const toNextQuestion = useCallback(() => {
    if (!disabledNextBtn) {
      onQuestionChange(`${defaultRoute}/${questionId + 1}`);
    }
  }, [disabledNextBtn, onQuestionChange, defaultRoute, questionId]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        // left
        toPrevQuestion();
      }
      if (e.keyCode === 39) {
        // right
        toNextQuestion();
      }

      if (e.keyCode === 38) {
        // up 1st question
        onQuestionChange(`${defaultRoute}/1`);
      }
      if (e.keyCode === 40) {
        // down last question
        onQuestionChange(`${defaultRoute}/${questionCount}`);
      }
    },
    [onQuestionChange, toPrevQuestion, toNextQuestion, defaultRoute, questionCount]
  );

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [onQuestionChange, questionId, defaultRoute, escapeListener]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: toNextQuestion,
    onSwipedRight: toPrevQuestion
  });

  const colorBtn = useMemo(() => {
    return cn(styles.btnCenter, {
      [styles.btnCorrectAnswer]: showUiResult && isCorrect,
      [styles.btnWrongAnswer]: showUiResult && !isCorrect
    });
  }, [showUiResult, isCorrect]);

  const textBtn = useMemo(() => {
    switch (true) {
      case !showUiResult:
        return t('questionCard.correct');
      case Boolean(isCorrect):
        return t('questionCard.right');
      default:
        return t('questionCard.wrong');
    }
  }, [showUiResult, isCorrect, t]);

  return (
    <div
      className={cn(styles.questionContainer, {
        [styles.trueAnswer]: showUiResult && isCorrect,
        [styles.falseAnswer]: showUiResult && !isCorrect
      })}
      // eslint rule is disabled because react-swipeable recommends
      // spreading the handlers object
      // (https://github.com/FormidableLabs/react-swipeable#hook-details)
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...swipeHandlers}
    >
      {offlineMode ? (
        <ZoomMultimedia variant="main" src={question.img} description={lang.q} />
      ) : (
        <ZoomMultimedia
          video={hasVideo}
          variant="main"
          src={hasVideo ? videoSrc : question.img}
          autoplay={videoAutoPlayAfterUserAnswer}
          description={lang.q}
        />
      )}
      <div className={styles.questionContent}>
        <div className={styles.questionList}>
          <div className={styles.questionListContent}>
            <div className={styles.questionTitle}>
              <p className="text-to-read">{questionDictionary.q}</p>
            </div>
            {questionDictionary.a.map(({ text, id }) => (
              <Checkbox
                showCorrectStatus={showUiResult}
                // id - 1 because answerListSelected order started from 0
                isChecked={selectedAnswers && selectedAnswers.includes(id - 1)}
                isCorrect={question.correct.includes(id)}
                onCheckedChange={onCheckedAnswerChange}
                key={text}
                label={text}
                id={id}
              />
            ))}
          </div>
          {!hasVideo || offlineMode ? null : (
            <ZoomMultimedia variant="sidebar" src={question.img} description={lang.q} />
          )}
        </div>
        <div className={styles.questionPaginator}>
          {disabledPrevBtn ? (
            <Button className={styles.btnPrev} variant="secondary" disabled>
              <i className="icon-arrow" />
            </Button>
          ) : (
            <Button className={styles.btnPrev} variant="secondary" onClick={handlePrevQuestion}>
              <i className="icon-arrow" />
            </Button>
          )}
          {(!isExam || (isExam && showUiResult)) && (
            <Button
              className={colorBtn}
              variant="secondary"
              disabled={disableQuestionChangeBtn}
              onClick={handleQuestionCheck}
            >
              {textBtn}
            </Button>
          )}
          {handleExit && disabledNextBtn && !isFinishedExam ? (
            <Button className={styles.btnExit} variant="secondary" onClick={handleExit}>
              <i className="icon-check" />
            </Button>
          ) : (
            <Button
              className={styles.btnNext}
              variant="secondary"
              disabled={disabledNextBtn}
              onClick={handleNextQuestion}
            >
              <i className="icon-arrow" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionView;

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { useSwipeable } from 'react-swipeable';
import { RouteComponentProps } from 'react-router';
import {
  getTheoryContentImgAnchorIndex,
  getTheoryImage,
  scrollToAnchor,
  splitTextByLines,
  splitTextContentWithImgToChunks,
  isSSR
} from '../../utils/utils';
import ReadSpeaker from '../ReadSpeaker/ReadSpeaker';
import { THEORY_POSTFIX } from '../../constants/rsPostfix';
import { P3 } from '../../ui-components/P3/P3';
import { IPages } from '../../reducer/theory.types';
import Image from '../../ui-components/Image/Image';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import useDeviceSizes from '../../hooks/useDeviceSizes';
import { BounceOnSelect } from '../../ui-components/BounceOnSelect/BounceOnSelect';
import { SET_BOOK_MARKED_PAGE_REQUEST } from '../../actions/user.action';
import { selectedMarkedPage } from '../../reducer/user.selectors';
import { Bookmark } from '../../ui-components/Bookmark/Bookmark';
import { ITheoryBookProps } from './TheoryBook.types';
import styles from './TheoryBook.module.css';

export const TheoryBook: React.FC<ITheoryBookProps> = ({
  page,
  inModal,
  pathName = 'topics',
  pageChapters,
  groupPages,
  currentLanguage
}: ITheoryBookProps) => {
  const dispatch = useDispatch();

  const isGlossaryMode = pathName === 'glossary';
  const isTopicsMode = pathName === 'topics';
  const { t } = useTranslation();
  const { width } = useDeviceSizes();
  const isMobileMode = width <= 991;
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const pages: string[] = Object.keys(groupPages);
  const { history, location }: RouteComponentProps = useReactRouter();
  const prevLocationKeyRef = useRef<string | undefined>(location.key);

  const bookMarkedPage = useSelector(selectedMarkedPage);
  const isBookmarked = useMemo(() => bookMarkedPage === pages[index], [bookMarkedPage, pages, index]);

  const currentPage: IPages[] = groupPages[pages[index]];
  const title = pageChapters[pages[index]];

  const ref = useRef<HTMLDivElement>(!isSSR() ? document.createElement('div') : null);

  const scrollReset = () => {
    scrollToAnchor(THEORY_PAGE_ANCHOR);
    // @ts-ignore
    ref.current.scrollTop = 0;
  };

  const handleChangePathname = useCallback(
    (pageItem: string) => {
      history.push(`/${currentLanguage}/theory/${pathName}/${pageItem}`);
      scrollReset();
    },
    [history, currentLanguage, pathName]
  );

  const handleNextPage = useCallback(() => {
    if (pages[index + 1]) {
      if (inModal) {
        setIndex(index + 1);
      } else {
        handleChangePathname(pages[index + 1]);
      }
      isMobileMode && scrollToAnchor(THEORY_PAGE_ANCHOR);
    }
  }, [handleChangePathname, index, pages, inModal, isMobileMode]);

  const handlePrevPage = useCallback(() => {
    if (pages[index - 1]) {
      if (inModal) {
        setIndex(index - 1);
      } else {
        handleChangePathname(pages[index - 1]);
      }
      isMobileMode && scrollToAnchor(THEORY_PAGE_ANCHOR);
    }
  }, [handleChangePathname, index, pages, inModal, isMobileMode]);

  const toPrevPage = useCallback(() => {
    if (groupPages[pages[index - 1]]) {
      handlePrevPage();
    }
  }, [handlePrevPage, groupPages, index, pages]);

  const toNextPage = useCallback(() => {
    if (groupPages[pages[index + 1]]) {
      handleNextPage();
    }
  }, [handleNextPage, groupPages, index, pages]);

  const handleBookmark = useCallback(() => {
    dispatch(SET_BOOK_MARKED_PAGE_REQUEST(pages[index] !== bookMarkedPage ? pages[index] : null));
  }, [dispatch, index, pages, bookMarkedPage]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        // left
        toPrevPage();
      }
      if (e.keyCode === 39) {
        // right
        toNextPage();
      }
    },
    [toPrevPage, toNextPage]
  );

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [handleChangePathname, escapeListener]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: toNextPage,
    onSwipedRight: toPrevPage
  });

  useEffect(() => {
    setIndex(Object.keys(groupPages).indexOf(String(page)));
  }, [page, groupPages]);

  useEffect(() => {
    if (isGlossaryMode && isMobileMode && prevLocationKeyRef.current !== location.key) {
      setShowModal(true);
    }
  }, [prevLocationKeyRef, isGlossaryMode, isMobileMode, location.key]);

  useEffect(() => {
    prevLocationKeyRef.current = location.key;
    scrollReset();
  }, [location]);

  const handleOnHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const composeTheoryTitle = title ? <p className={`glossary-title text-to-read${THEORY_POSTFIX}`}>{title}</p> : null;

  const composedContent = currentPage
    ? currentPage.map((pageFromGroup: IPages, currentPageIndex) => {
        const [subTitle, ...texts]: string[] = splitTextByLines(pageFromGroup.text);
        const filterImgList = pageFromGroup.img.filter(Boolean);
        const filteredParagraphs = texts.filter(Boolean);

        return (
          // eslint-disable-next-line
          <div className={styles.glossaryImgList} key={`${page}${currentLanguage}${currentPageIndex}`}>
            <p className={cx('glossary-subtitle', `text-to-read${THEORY_POSTFIX}`)}>{subTitle}</p>
            {filterImgList.length > 0 &&
              filterImgList
                .filter((_, item) => !filteredParagraphs.some(text => text.includes(`[IMAGE${item + 1}]`)))
                .map(imgKey => (
                  <Image
                    key={imgKey}
                    className={styles.imgContent}
                    placeholderClassName={styles.imgPlaceholderContent}
                    src={getTheoryImage(imgKey)}
                  />
                ))}
            {filteredParagraphs.map((textItem, textIndex) => {
              if (!textItem.includes('[IMAGE')) {
                return (
                  // eslint-disable-next-line
                  <P3 key={`${textItem}${textIndex}`} className={cx('glossary-text', `text-to-read${THEORY_POSTFIX}`)}>
                    {textItem}
                  </P3>
                );
              }

              return splitTextContentWithImgToChunks(textItem).map(subItem => {
                const imgKey = filterImgList[Number(getTheoryContentImgAnchorIndex(subItem)) - 1];
                return imgKey ? (
                  <Image
                    key={imgKey}
                    className={styles.imgContent}
                    placeholderClassName={styles.imgPlaceholderContent}
                    src={getTheoryImage(imgKey)}
                  />
                ) : (
                  <span key={subItem} className={`text-to-read${THEORY_POSTFIX}`}>
                    {subItem}
                  </span>
                );
              });
            })}
          </div>
        );
      })
    : null;

  const controls = (
    <div className={styles.glossaryFooter}>
      <Button
        className={styles.btnPrev}
        variant="secondary"
        onClick={handlePrevPage}
        disabled={!groupPages[pages[index - 1]]}
      >
        <i className="icon-arrow" />
      </Button>
      <div className={styles.topicNumberWrapper}>
        <p className={styles.topicNumber}>{`${index + 1} / ${pages.length}`}</p>
        {isTopicsMode && (
          <LinkWrapper to="/theory" className={styles.topicLink}>
            {t('theoryPage.pageView.linkBack')}
          </LinkWrapper>
        )}
        <BounceOnSelect isSelect={isBookmarked}>
          <Bookmark isBookmarked={isBookmarked} onClick={handleBookmark} />
        </BounceOnSelect>
      </div>
      <Button
        className={styles.btnNext}
        variant="secondary"
        onClick={handleNextPage}
        disabled={!groupPages[pages[index + 1]]}
      >
        <i className="icon-arrow" />
      </Button>
    </div>
  );

  return (
    <>
      {isGlossaryMode && isMobileMode && (
        <Modal
          show={showModal}
          size="sm"
          onHide={handleOnHide}
          className={styles.modal}
          backdropClassName={styles.backdrop}
          scrollable
        >
          <Modal.Header closeButton>{composeTheoryTitle}</Modal.Header>
          <Modal.Body>
            {showModal && (
              <div {...swipeHandlers}>
                {composedContent}
                {controls}
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
      <div ref={ref} className={cx(styles.glossaryScrollBox, { [styles.withModal]: isGlossaryMode })}>
        <div className={styles.glossaryHeader}>
          {' '}
          {composeTheoryTitle}
          {!isGlossaryMode && (
            <ReadSpeaker
              className={styles.questionBtnListItem}
              lng={currentLanguage}
              rsPostfix={THEORY_POSTFIX}
              stopParam={index}
            />
          )}
        </div>
        {showModal ? (
          <>
            {composedContent}
            {controls}
          </>
        ) : (
          <div {...swipeHandlers}>
            {composedContent}
            {controls}
          </div>
        )}
      </div>
    </>
  );
};
